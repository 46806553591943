import React, { useState, useEffect } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { BASE_URL } from "../../utils/config.jsx";
import { GET, PUT, POST } from "../../utils/ApiRequestProvider.tsx";
import { TokenService } from "../../utils/TokenService.ts";
import Loader from "../../components/Loader.jsx";
import { set, useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'react-modal';
import CitySelect from "../../components/cityselectmodal.jsx";
import { json } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMapReact from 'google-map-react';

const CustomMarker = ({ text }) =>
    <div className="text-primary text-48 text-center items-center justify-center -translate-x-1/2 -translate-y-1/2" >
        <LocationOnIcon />
    </div>





export default function Content() {
  const { register, handleSubmit, formState: errors, setValue } = useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [profileImage, setProfileImage] = useState({ base64: '', name: '', type: '' });
  const [businessLogo, setBusinessLogo] = useState({ base64: '', name: '', type: '' });
  const [businessDoc, setBusinessDoc] = useState({ base64: '', name: '', type: '' });


  const [businessProfile, setBusinessProfile] = useState({ base64: '', name: '', type: '' });
  const [city, setCity] = useState({ id: 0, name: '' });
  const [cityUser, setCityUser] = useState({ id: 0, name: '' });
  const [city_type, setCityType] = useState('');
  const [cityModal, setCityModal] = useState(false);
  const [days, setDays] = useState({
    monday: { id: 1, isClosed: false, openingTime: '', closingTime: '' },
    tuesday: { id: 2, isClosed: false, openingTime: '', closingTime: '' },
    wednesday: { id: 3, isClosed: false, openingTime: '', closingTime: '' },
    thursday: { id: 4, isClosed: false, openingTime: '', closingTime: '' },
    friday: { id: 5, isClosed: false, openingTime: '', closingTime: '' },
    saturday: { id: 6, isClosed: false, openingTime: '', closingTime: '' },
    sunday: { id: 7, isClosed: false, openingTime: '', closingTime: '' }
  });
  const [businessHoursDTOs, setBusinessHoursDTOs] = useState([]);
  const [userServiceDTOs, setUserServiceDTOs] = useState([]);
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [mapModal, setMapModal] = useState(false);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [serviceType, setServiceType] = useState({
    repairShop: { isSelected: false, id: 1, name : 'Repair Shop'},
    oilChange: { isSelected: false, id: 2, name : 'Oil Change'},
    towing: { isSelected: false, id: 3, name : 'Towing'}
  });
 


  function handleUserCity(city) {
    // console.log('Selected city ID:', cityId, 'Selected city Name:', cityName, 'Selected city Long:', long, 'Selected city Lat:', lat);
    if(city !== null){
      setValue('city', city.name);
      setValue('city_id', city.id);
      setCityUser({id: city.id, name: city.name})
      setCityModal(false);
    }   
  }

  function handleBusiCity(city) {
    console.log('Selected city ID:', city);
    if(city !== null){
      setValue('busi_city', city.name);
      setValue('busi_city_id', city.id);
      setCity({id: city.id, name: city.name})
      setCenter({
        lat: parseFloat(city.latitude),
        lng: parseFloat(city.longitude)
      });
      setCityModal(false);
    }
  }

  const handleCityModalOpen = (c) => {
    setCityModal(!cityModal);
    setCityType(c);
  }

  const handleSetCoords = (long, lat) => {
    setMapModal(false)
    setCoords({ ...coords, longitude: parseFloat(long), latitude: parseFloat(lat) }); // Combine longitude and latitude into a single setFilter call
    console.log(long, lat)
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
        setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude
        });
    }, (error) => {
        console.error("Error getting geolocation:", error);
    });
}, []);

  const handleMapChange = ({ center }) => {
    setCenter({
      lat: center.lat,
      lng: center.lng
    });
  };

  useEffect(() => {
    // Logic to update businessHoursDTOs array after days state update
    const updatedDTOs = Object.values(days).map((day) => ({
      dayOfWeek: day.id , // Adjusted dayOfWeek based on the IDs
      openingTime: dateTime(day.openingTime),
      closingTime: dateTime(day.closingTime),
      isClosed: day.isClosed
    }));

    setBusinessHoursDTOs(updatedDTOs);
  }, [days]);

  const handleToggle = (day) => {
    setDays((prevDays) => ({
      ...prevDays,
      [day]: {
        ...prevDays[day],
        isClosed: !prevDays[day].isClosed
      }
    }));
  };

 
  const handleTimeChange = (day, field, value) => {
    setDays((prevDays) => {
      const updatedDays = { ...prevDays };
      updatedDays[day][field] = value;

      // If changing time of an open day, propagate to all closed days
      if (!updatedDays[day].isClosed) {
        Object.keys(updatedDays).forEach((d) => {
          if (d !== day && updatedDays[d].isClosed) {
            updatedDays[d][field] = value;
          }
        });
      }

      return updatedDays;
    });
  };

  useEffect(() => {
    // Logic to update userServiceDTOs array after serviceType state update
    const updatedDTOs = Object.values(serviceType)
      .filter(service => service.isSelected)
      .map(service => ({ id: service.id }));

    setUserServiceDTOs(updatedDTOs);
  }, [serviceType]);

  const handleToggleServiceType = (type) => {
    setServiceType(prevState => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        isSelected: !prevState[type].isSelected
      }
    }));
  };

  const custom = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '65%',
    },
  };

  const onSubmit = async (data) => {
    const res = await POST(BASE_URL + `api/User/providerRegistration`, 
    {
        uploadProfileDto: {
          profileBase64Data: profileImage.base64.split(',')[1],
          profileName: profileImage.name,
          profileType: profileImage.type
        },
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        gender: data.gender,
        user_CityId: data.city_id,
        user_ZipCode: data.zipCode,
        address: data.address,
        lati: center.lat,
        long: center.lng,
        maritalStatus: data.maritalStatus,
        email: data.email,
        phone: data.phone,
        userServiceDTOs: userServiceDTOs,
        businessDTOs: {
          uploadLogoDto: {
            logoBase64Data: businessLogo.base64.split(',')[1],
            logoName: businessLogo.name,
            logoType: businessLogo.type
          },
          uploadBusinessProfileDto: {
            bProfileBase64Data: businessProfile.base64.split(',')[1],
            bProfileName: businessProfile.name,
            bProfileType: businessProfile.type
          },
          id: 0,
          title: data.title,
          facilitylicense: data.facilitylicense,
          business_CityId: data.busi_city_id,
          business_ZipCode: data.busi_zipCode,
          address: data.busi_address,
          lati: coords.latitude,
          long: coords.longitude,
          email: data.busi_email,
          phone: data.busi_phone,
          uploadDocumentDto: {
            base64Data: businessDoc.base64,
            fileName: businessDoc.name,
            fileType: businessDoc.type
          },
          businessHoursDTOs: businessHoursDTOs,
        },
        redirectURL: `https://admin.cnamobile.app/setpassword`
      });
      if (res.status === 'success') {
        alert(res.message);
        window.location.reload();
      }
      else {
        alert(res.message);
      }
    // console.log('data', JSON.stringify({
    //   uploadProfileDto: {
    //     // profileBase64Data: profileImage.base64,
    //     profileName: profileImage.name,
    //     profileType: profileImage.type
    //   },
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   dob: data.dob,
    //   gender: data.gender,
    //   user_CityId: data.city_id,
    //   user_ZipCode: data.zipCode,
    //   address: data.address,
    //   lati: center.lat,
    //   long: center.lng,
    //   maritalStatus: data.maritalStatus,
    //   email: data.email,
    //   phone: data.phone,
    //   userServiceDTOs: userServiceDTOs,
    //   businessDTOs: {
    //     uploadLogoDto: {
    //       // logoBase64Data: businessLogo.base64,
    //       logoName: businessLogo.name,
    //       logoType: businessLogo.type
    //     },
    //     uploadBusinessProfileDto: {
    //       // bProfileBase64Data: businessProfile.base64,
    //       bProfileName: businessProfile.name,
    //       bProfileType: businessProfile.type
    //     },
    //     id: 0,
    //     title: data.title,
    //     facilitylicense: data.facilitylicense,
    //     business_CityId: data.busi_city_id,
    //     business_ZipCode: data.busi_zipCode,
    //     address: data.busi_address,
    //     lati: coords.latitude,
    //     long: coords.longitude,
    //     email: data.busi_email,
    //     phone: data.busi_phone,
    //     uploadDocumentDto: {
    //       base64Data: businessDoc.base64,
    //       fileName: businessDoc.name,
    //       fileType: businessDoc.type
    //     },
    //     businessHoursDTOs: businessHoursDTOs,
    //     redirectURL: `https://admin.cnamobile.app/setpassword`
    //   }
    // }));
  }


  const handleImageChange = (e, type) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      if (type === 'profile') {
        setProfileImage({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });
      }
      else if (type === 'busi_profile') {
        setBusinessProfile({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });
      }
      if (type === 'busi_logo') {
        setBusinessLogo({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });

      };
    }
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const base64 = reader.result;
          setBusinessDoc({
            base64: base64.split(',')[1],
            name: file.name,
            type: file.type,
          });
        } catch (error) {
          console.error('Error reading file:', error);
        }
      };
      reader.readAsDataURL(file);
    }
  };


  const dateTime = (timeString) => {
    if (!timeString) {
      return ''; // Return an empty string if timeString or currentDate is undefined
    }

    // Parse the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');

    // Create a new Date object using the current date
    const date = new Date()

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Create options for formatting the date
    const options = {
      weekday: 'ddd',
      month: 'MMM',
      day: 'dd',
      year: 'yyyy',
      hour: 'HH',
      minute: 'mm',
      second: 'ss',
    };

    // Format the date using Intl.DateTimeFormat
    const formatter = new Date(date);
    const formattedTime = formatter.toString().split(' (')[0];

    return formatter.toString().split(' (')[0];;
  };

  const renderMap = (defaultCenter) => {
    // console.log(defaultCenter);
    if (defaultCenter.lat === 0 && defaultCenter.lng === 0) {
        return <div>Loading...</div>;
    }
    else {
        return (
            <div>
                <div style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBoTFxXAmbxt9d_SFQ5SABXmgtNO7R033I" }}
                        center={defaultCenter}
                        defaultZoom={11}
                        yesIWantToUseGoogleMapApiInternals
                        onChange={handleMapChange}
                    >
                        <CustomMarker
                            lat={defaultCenter.lat}
                            lng={defaultCenter.lng}
                            text="My Marker"
                        />
                    </GoogleMapReact>


                </div>
                <div className='flex items-center justify-center'>
                    <button onClick={() => handleSetCoords(center.lng, center.lat)} className="w-fit bg-primary hover:bg-blue-700 text-white  py-2 px-4 rounded-lg my-4">
                        Done
                    </button>
                </div>
            </div>
        );
    }
  }


  return (
    <div>
      {isLoading ?
        <Loader />
        :
        <div>
          {/* active inactive user toggle */}
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-xl text-primary font-bold pb-5">
              Business Register
            </h3>

          </div>

          {/* Profile image section */}
          <div className="flex flex-row items-start justify-between w-fit">
            <div className="flex flex-col pb-5" >
              <img
                src={profileImage.base64 !== '' ? profileImage.base64 : "images/image-placeholder.jpg"}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              <div className="py-3">
                <input
                  className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                  id="profile_image"
                  type="file"
                  onChange={(event) => handleImageChange(event, 'profile')}

                />
              </div>
            </div>

          </div>

          {/* Business User Details */}
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-xl text-gray-700 font-semibold pb-5">
              Service Provider Info
            </h3>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}>

            <div className="overflow-hidden">

              <div className="flex md:flex-row flex-col gap-10 pb-3">
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="firstName" className="pb-1">First Name*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    id="firstName" {...register('firstName', { required: true })}

                  />
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="lastNamw" className="pb-1">Last Name*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    id="lastName" {...register('lastName', { required: true })}

                  />
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="gender" className="pb-1">Gender*</label>
                  <select id="gender" {...register('gender', { required: true })} className={`py-2 px-2 rounded-md border w-full`}>
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="maritalStatus" className="pb-1">Marital Status*</label>
                  <select className="py-2 px-2 rounded-md border w-full"
                    id="maritalStatus" {...register('maritalStatus', { required: true })}
                  >
                    <option >Select </option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Student">Student</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="dob" className="pb-1">DOB*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="date"
                    id="dob" {...register('dob', { required: true })}

                  />

                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="email" className="pb-1">Email*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="email"
                    id="email" {...register('email', { required: true })}
                  />
                </div>


              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="phone" className="pb-1">Phone*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="phone"
                    id="phone" {...register('phone', { required: true })}

                  />
                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label className="pb-1">ZIP Code*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    id='zipCode' {...register('zipCode', { required: true })}

                  />
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="city" className="pb-1">City*</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    id="city" {...register('city', { required: true })}
                    onClick={() => handleCityModalOpen('user_city')}
                  />
                </div>

              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label className="pb-1">Address*</label>
                  <textarea
                    className="py-2 px-2 rounded-md border w-full h-20"
                    type="address"
                    id='address' {...register('address', { required: true })}
                  />
                </div>
              </div>
            </div>



            <div className="flex flex-row items-center justify-around py-5">
              <div className="flex flex-col items-center justify-between gap-5">
                <img
                  src={businessLogo.base64 !== '' ? businessLogo.base64 : "images/image-placeholder.jpg"}
                  className="w-[150px] h-[150px] object-cover rounded-full"
                />
                <input
                  className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                  id="file_input"
                  type="file"
                  onChange={(event) => handleImageChange(event, 'busi_logo')}
                />
              </div>
              <div className="flex flex-col items-center justify-between gap-5">
                <img
                  src={businessProfile.base64 !== '' ? businessProfile.base64 : "images/image-placeholder2.jpg"}
                  className="w-[600px] h-[280px] object-cover rounded-md"
                  atl='business profile image'
                />
                <input
                  className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                  id="file_input"
                  type="file"
                  onChange={(event) => handleImageChange(event, 'busi_profile')}
                />
              </div>
            </div>
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-xl text-gray-700 font-semibold pb-5">
                Buisness Info
              </h3>

            </div>
            {/* Business Details */}
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="overflow-hidden">

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Business Title*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          id='title' {...register('title', { required: true })}

                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Phone*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="tel"

                          id='busi_phone' {...register('busi_phone', { required: true })}
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Business Email*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="email"

                          id='busi_email' {...register('busi_email', { required: true })}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">City*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          id='busi_city' {...register('busi_city', { required: true })}
                          onClick={() => handleCityModalOpen('busi_city')}
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Zip Code</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          id='busi_zipCode' {...register('busi_zipCode', { required: true })}
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Address*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          id='busi_address' {...register('busi_address', { required: true })}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">License Number*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          id='facilitylicense' {...register('facilitylicense', { required: true })}
                        />
                      </div>
                      {/* <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Document</label>
                          <button onClick={handlePdfDownload} className="flex flex-row items-center justify-between py-2 px-2 rounded-md border w-full">
                            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">{userData?.business?.fileName}</span>
                            <DownloadIcon />
                          </button>
                      </div> */}
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Upload Document*</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                        />
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </div>

          </form>
          <div className="flex flex-row items-center justify-between">
            <label className="text-xl text-gray-700 font-semibold pb-5">
              Select Location 
            </label>
            <button onClick={()=>setMapModal(true)} className="w-fit bg-primary hover:bg-blue-700 text-white  py-2 px-4 rounded-lg">
              <EditIcon />  Select Location
            </button>
          </div>
          <div className="flex flex-col text-sm text-gray-500 w-full pb-4">
            <label className="pb-1">Service Type*</label>
            <div className="flex flex-row gap-5 items-center justify-between">
              {Object.entries(serviceType).map(([type, service]) => (
                <div key={type} className="w-[1/3] rounded-lg p-2 border flex flex-grow items-center justify-between">
                  <label className="flex flex-row justify-between items-center cursor-pointer w-full">
                    <span className="ms-3 text-sm font-medium text-gray-900">{service.name}</span>
                    <input
                      type="checkbox"
                      checked={service.isSelected}
                      onChange={() => handleToggleServiceType(type)}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* working hours */}
          <div className="bg-gray-100 p-4 rounded-md">
            <h3 className="text-primary text-lg font-bold">Working days</h3>
            <div className="flex flex-col gap-5">
              {Object.entries(days).map(([day, info]) => (
                <div key={day} className="flex flex-col gap-2">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900">{day.charAt(0).toUpperCase() + day.slice(1)}</span>
                        <input
                          type="checkbox"
                          checked={!info.isClosed}
                          onChange={() => handleToggle(day)}
                          className="sr-only peer"
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border"
                        type="time"
                        value={info.openingTime}
                        onChange={(e) => handleTimeChange(day, 'openingTime', e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        value={info.closingTime}
                        onChange={(e) => handleTimeChange(day, 'closingTime', e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end items-center gap-x-4 p-4 sm:px-7 ">
            <button type="submit" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={handleSubmit(onSubmit)}>
              Submit
            </button>
          </div>
          
          {/* City Select Modal */}
          <Modal
            isOpen={cityModal}
            onRequestClose={() => setCityModal(false)}
            style={custom}
            appElement={document.getElementById('your-app-element-id')}
          >
            <div className="flex flex-col items-center gap-5 p-5">
              {city_type === 'busi_city' ? <CitySelect onCitySelect={handleBusiCity} /> :
                <CitySelect onCitySelect={handleUserCity} />}
            </div>
          </Modal>
                
          {/* Map Modal */} 
          <Modal
            isOpen={mapModal}
            onRequestClose={() => setMapModal(false)}
            style={custom}
          >
            <div >
              {renderMap(center)}
            </div>
          </Modal>




        </div>}
    </div>
  );
}
