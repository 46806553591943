import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./workerinfodetails/content";
import { useParams, useLocation } from "react-router-dom";

export default function WorkerInfoDetails() {
  const { state } = useLocation();
  return (
    <Baselayout>
      <Content state={state} />
      </Baselayout>
  );
}
