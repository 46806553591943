import React, { useState, useEffect } from "react";
import { DELETE, GET, PUT } from "../../utils/ApiRequestProvider.tsx";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { BASE_URL } from "../../utils/config.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader.jsx";
import Modal from "react-modal";

const ResultsPerPage = 10;

export default function Content() {
  
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterUsers, setFilterUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [description, setDescription] = useState('');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };

  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = startIndex + ResultsPerPage;
  useEffect(() => {
    console.log('Role:',TokenService.getUserRole());
    const UserList = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/User/GetAllCustomers", {
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
      });
  
      if (res.status === 'success') {
        setLoading(false);
        
        setUsers(res.data); // Update users state with filtered data
        setFilterUsers(res.data);
        console.log(res.data);
      }
      else {
        alert(res.message);
        setLoading(false);
      }
    };
  
    UserList();
  }, []);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleSearch = (event) => {
    console.log(event.target.value);
    const s_text = event.target.value.toLowerCase();
    setSearchText(s_text);

    const filtered = users.filter((user) => {
        return user.firstName.toLowerCase().includes(s_text) || 
        user.lastName.toLowerCase().includes(s_text) || 
        user.status.toLowerCase().includes(s_text) || user.phone.toLowerCase().includes(s_text) || 
        formatDate(user.dob).toLowerCase().includes(s_text) || user.address.toLowerCase().includes(s_text) || 
        user.monthlyIncome.toLowerCase().includes(s_text) || user.maritalStatus.toLowerCase().includes(s_text) || 
        formatDate(user.createdAt).toLowerCase().includes(s_text);

        // // return fullName.includes(s_text);
        // return  status.includes(s_text);
    });
    setFilterUsers(filtered);
};

const handleStatusChange = async () => {
  console.log('userId', userId);
  const res = await DELETE(BASE_URL + "api/User/deleteUser", {
    userId: userId,
    note: description
  });
  if (res.status === 'success') {
    setModalIsOpen(false);
    alert('User deleted successfully');
    window.location.reload();
  }
  else {
    alert(res.message);
  }
}

Modal.setAppElement(document.getElementById('modal')); 

  
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) ?  (
        <div className=" sm:px-6  mx-auto">
          <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">Users</h2>
                    </div>

                    <div></div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Phone
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              DOB
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Address
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Monthly Income
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Martial Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        {
                     ( TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) &&(
                    <th scope="col" className="px-6 py-3 text-end">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Action
                        </span>
                      </div>
                    </th>

                      )
                    }

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Change
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {filterUsers.slice(startIndex, endIndex).map((user) => (
                        <tr key={user.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to='/customerinfodetails' state={user.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {user.firstName} {user.lastName}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {user.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {
                                user.status === 'Active' ?
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                    <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                    {user.status}
                                  </span>
                                  :
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                    <CancelIcon className="text-red-500" fontSize="12" />
                                    {user.status}
                                  </span>

                              }
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.dob)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.address}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.monthlyIncome}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.maritalStatus}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.createdAt)}
                              </span>
                            </div>
                          </td>
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (


                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                  <Link to='/customerinfoedit' state={user.id}
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                  >
                                    <EditIcon className="text-primary hover:text-black" />
                                  </Link>
                                
                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    onClick={() => {setModalIsOpen(true); setUserId(user.id)}}
                                  >
                                    <DeleteIcon className="text-primary hover:text-black" />
                                  </a>
                                </div>
                              </td>
                            )
                          }
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to='/customerinfodetails' state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium ">
                                  View
                                </button>
                              </Link>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to='/passwordchange' state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium ">
                                  Change Password
                                </button>
                              </Link>
                            </div>
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterUsers.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(users.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(users.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        null
      )}
      <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            appElement={document.getElementById('modal')}
          >
           
                <div className="flex flex-col items-center gap-5 px-4 ">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you delete the user?</h2>
                  {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
  
                  <textarea
                    className="w-full p-2 min-h-20 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
    

                <div className="flex justify-center items-center gap-x-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              </div>
              

             
          </Modal>
    </div>
  );
}
