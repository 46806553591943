import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../components/Loader.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import Dropdown, {UserDropdown} from "../../components/drodown.jsx";

const ResultsPerPage = 10; // Define the maximum number of results per page

export default function Content() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [business, setBusiness] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [type, setType] = useState({ id: 0, type: "" });
  const [loading, setLoading] = useState(false);


  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Function to calculate startIndex and endIndex
  const startIndex = (currentPage - 1) * ResultsPerPage;
  const endIndex = currentPage * ResultsPerPage;

  // Function to handle pagination
  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const getAllSubscriptions = async () => {
      setLoading(true);
      let response = await GET(BASE_URL + "api/Subscription/GetAllSubscriptionHistoryList");
      if (response.status === 'success') {
    
        let businessSubscriptions = response.data.filter(sub => sub?.business !== null);
        if(TokenService.getUserRole() === 4)
        {
          businessSubscriptions = businessSubscriptions.filter(sub => sub?.business?.id === TokenService.getUser().user.businessId);
        }

        setBusiness(businessSubscriptions);

        const customerSubscriptions = response.data.filter(sub => sub?.business === null);
        setCustomer(customerSubscriptions);
        console.log('customer', businessSubscriptions);
        setLoading(false);
      }
      else {
        alert(response.message);
        setLoading(false);
      }
    }

    getAllSubscriptions();
  }, []);


  const handleBusinessSelect = (id) => {
    // setSelectedBusinessId(id);
    handleGetBusinessSubs(id)
  };

  const handleUserSelect = (id) => {
    // setSelectedBusinessId(id);
    handleGetCustomerSubs(id)
  };

  const handleGetCustomerSubs = async (id) => {
    setCustomer([]);
    // console.log('id', id);
    const res = await GET(BASE_URL + `api/Subscription/getSubscriptionHistoryByUserId?userId=${id}`);
    if (res.status === 'success') {
      setCustomer(res.data);
    }
    else {
      alert('Error fetching data',res.message);
    }
  }


  const handleGetBusinessSubs = async (id) => {
    setBusiness([]);
    // console.log('id', id);
    const res = await GET(BASE_URL + `api/Subscription/getSubscriptionHistoryByBusinessId?businessId=${id}`);
    if (res.status === 'success') {
      setBusiness(res.data);
    }
    else {
      alert('Error fetching data',res.message);
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const subcriptionStatus = (status) => {
    if (status === 1) {
      return "Active";
    } else if (status === 2) {
      return "Inactive";
    } else if (status === 3) {
      return "Expired";
    } else {
      return "Cancelled";
    }
  }


  return (
    <div>
      {loading ?
        <Loader />
        :
        <div>
          <div className="flex flex-row items-center justify-between sm:px-6 mb-6">
            {
              (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6 || TokenService.getUserRole() === 7 ) && (
              
            <div className="flex flex-row items-center  gap-3 bg-gray-300 px-2 py-2 rounded-md">
              <button
                className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                  }`}
                onClick={() => handleTabClick("tab1")}
              >
                Business
              </button>
              <button
                className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                  }`}
                onClick={() => handleTabClick("tab2")}
              > 
                Customer
              </button>
            </div>
                )
            }
          {
            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
            
            <div className="flex ">
              {activeTab === "tab1" ?
              <Dropdown onBusinessSelect={handleBusinessSelect} />
              : <UserDropdown onSelect={handleUserSelect}/>}
            </div>
              )
          }

          </div>
          {/* Business Products tab */}
          <div
            className=" sm:px-6 mx-auto"
            id="tab1"
            style={{ display: activeTab === "tab1" ? "block" : "none" }}
          >
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">
                          Business Subscriptions
                        </h2>
                      </div>

                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="ps-6 lg:ps-3 px-6 py-3 text-center"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Business Title
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Subscription Type
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Description
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Start Date
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                End Date
                              </span>
                            </div>
                          </th>


                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Status
                              </span>
                            </div>
                          </th>
                          
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {business.slice(startIndex, endIndex).map((pro, index) => {

                          return (
                            <tr key={index}>
                              <td className="size-px whitespace-nowrap">
                                <div className="ps-6 lg:ps-3  px-6 py-3">
                                  <div className="flex items-center gap-x-3">

                                    <div className="grow">
                                      <Link to="/subscriptionhistory" state={({ id: pro.id, type: 'business' })}>
                                        <span className="block text-sm font-semibold text-gray-800 hover:underline">
                                          {pro.business?.title}
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {pro.product?.name}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3 flex flex-row gap-3">
                                  <span className="text-sm text-gray-500">
                                    {pro.product?.description}
                                  </span>
                                </div>
                              </td>



                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {formatDate(pro.startDate)}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {formatDate(pro.endDate)}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {pro.status !== 1 ? (
                                      <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                        <CancelIcon className="text-red-500" fontSize="12" />
                                        {subcriptionStatus(pro.status)}
                                      </span>
                                    ) : (
                                      <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                        <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                        {subcriptionStatus(pro.status)}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </td>
                             
                            </tr>
                          )
                        }
                        )}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{business.length}</span>{" "}
                          results
                        </p>
                      </div>

                     
                      <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(business.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(business.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Customer Products tab */}
          <div
            className=" sm:px-6 mx-auto"
            id="tab2"
            style={{ display: activeTab === "tab2" ? "block" : "none" }}
          >
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">
                          Customer Subscriptions
                        </h2>
                      </div>

                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="ps-6 lg:ps-3 px-6 py-3 text-center"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Customer Name
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Salary Range
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Subcription Type
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Description
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Start Date
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                End Date
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Status
                              </span>
                            </div>
                          </th>
                         



                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {customer?.slice(startIndex, endIndex).map((pro, index) => (
                          <tr key={index}>
                            <td className="size-px whitespace-nowrap">
                              <div className="ps-6 lg:ps-3  px-6 py-3">
                                <div className="flex items-center gap-x-3">

                                  <div className="grow">
                                    <Link to="/subscriptionhistory" state={({ id: pro.id, type: 'customer' })}>
                                      <span className="block text-sm font-semibold text-gray-800 hover:underline">
                                        {pro.user?.firstName} {pro.user?.lastName}
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <span className="text-sm text-gray-500">
                                    {pro.product?.range}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.product?.name}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3 flex flex-row gap-3">
                                <span className="text-sm text-gray-500">
                                  {pro.product?.description}
                                </span>
                              </div>
                            </td>



                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {formatDate(pro.startDate)}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {formatDate(pro.endDate)}
                                </span>
                              </div>
                            </td>



                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.status !== 1 ? (
                                    <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                      <CancelIcon className="text-red-500" fontSize="12" />
                                      {subcriptionStatus(pro.status)}
                                    </span>
                                  ) : (
                                    <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                      <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                      {subcriptionStatus(pro.status)}
                                    </span>
                                  )}

                                </span>
                              </div>
                            </td>
                            

                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{customer.length}</span>{" "}
                          results
                        </p>
                      </div>
                      <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(customer.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(customer.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      }


    </div>
  );
}
