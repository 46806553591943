import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './subscriptionhistory/content'
import { useLocation } from "react-router-dom";

export default function SubscriptionHistory() {
  const { state } = useLocation();
  return (
    <Baselayout>
        <Content  state={state}/>
    </Baselayout>
  )
}
