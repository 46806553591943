import { useEffect, useState } from "react";
import "./App.css";
import AppRoutes from "./components/Routes/AppRoutes";
import { TokenService } from "./utils/TokenService.ts";


function App() {


  // let lastClicked = new Date().getTime();

  // const checkIsInactive = () => {
  //   lastClicked = new Date().getTime();
  // }

  // useEffect(() => {
    
  //   const body = document.body;
  
  //   if (body) {
  //     body.addEventListener('click', checkIsInactive);
  //   }

  //   const interval = setInterval(() => {
  //     if ((new Date().getTime() - lastClicked) > 300000 && TokenService.getAccessToken() !== "") {
  //       TokenService.clearToken();
  //       TokenService.clearUser();
  //       TokenService.clearUserRole();
  //       sessionStorage.removeItem("token");
  //       window.location.href = "/";
  //       TokenService.setAccessToken('');
  //     }
  //   }, 60000);



  //   return () => {
  //     if (body) {
  //       body.removeEventListener('click', checkIsInactive);
  //     }
  //     clearInterval(interval);
  //   };

   
  // }, []);
  

  return (
    <>
      <AppRoutes />

    </>
  );
}

export default App;