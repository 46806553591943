import React, { useState } from "react";
import { set, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { POST, PUT } from "../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../utils/config.jsx";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "../components/Loader.jsx";


const ForgotPassword = () => {
    
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState(''); 
  let firebaseUID = '';

  const handleTogglePassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle confirm password visibility
  };

  const handlePasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const password = e.target.form.password.value;
    setPasswordsMatch(password === confirmPassword);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const res = await POST(BASE_URL + 'api/User/password/reset',{
        password: data.password,
        confirmPassword: data.confirmPassword,
        token: data.otp
        });
    if (res.status === 'success') {
        console.log('Password set successfully:', res.data);
        alert('Password set successfully');
        
        window.location.href = '/';
        }
    else {
        alert(res.message);
        setIsLoading(false);
    }
    setIsLoading(false);
    
  };

  const handleSendEmail = async () => {
    console.log(email);
    const res = await POST(BASE_URL + 'api/User/password/forget?email='+email);
    if (res.status === 'success') {
      console.log('Password set successfully:', res.data);
      alert('Email sent successfully');
      setStep(step + 1);
    }
    else {
      alert(res.message);
      setIsLoading(false);
    }
  }

    const renderSteps = () => {
        switch (step) {
            case 1: {
                return (
                    <div className="p-4 sm:p-7   ">
                        <div className="flex items-center justify-center">
                            <img className="w-32" src="/images/logo.png" alt="Logo" />
                        </div>
                        <div className="mt-5">
                            <div className="grid gap-y-4 ">
                                <div>
                                    <label htmlFor="email" className="block text-sm mb-2">Email</label>
                                    <div className="relative">
                                        <input type='email' id="email" placeholder="Enter E-mail" onChange={(e) => setEmail(e.target.value)} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 `} />
                                    </div>
                                    {/* {errors.password && <p className="text-xs text-red-600 mt-2">Password is required</p>} */}
                                </div>
                                <div className="w-full text-center ">
                                    <button type="submit" className="w-fit py-3 px-4 bg-primary hover:bg-blue-500 text-white rounded-lg" onClick={handleSendEmail}>Submit</button>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            }
            case 2: {
                return (
                    <div className="p-4 sm:p-7   ">
                    <div className="flex items-center justify-center">
                      <img className="w-32" src="/images/logo.png" alt="Logo" />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-5">
                        <div className="grid gap-y-4">
                        <div>
                            <label htmlFor="otp" className="block text-sm mb-2">Verification code</label>
                            <div className="relative">
                              <input type={"text"} id="otp" {...register('otp', { required: true })}  className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.otp ? 'border-red-500' : 'border-gray-300'}`} />
                              
                              
                            </div>
                            {errors.otp && <p className="text-xs text-red-600 mt-2">Verification code is required</p>}
                          </div>

                          <div>
                            <label htmlFor="password" className="block text-sm mb-2">Password</label>
                            <div className="relative">
                              <input type={showPassword ? "text" : "password"} id="password" {...register('password', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`} />
                              
                              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                {showPassword ? (
                                  <VisibilityOff onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                                ) : (
                                  <Visibility onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                                )}
                              </span>
                            </div>
                            {errors.password && <p className="text-xs text-red-600 mt-2">Password is required</p>}
                          </div>
                          <div>
                            <label htmlFor="confirmPassword" className="block text-sm mb-2">Confirm Password</label>
                            <div className="relative">
                              <input type={showConfirmPassword ? "text" : "password"} id="confirmPassword" {...register('confirmPassword', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`} />
                              <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                                {showConfirmPassword ? (
                                  <VisibilityOff onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                                ) : (
                                  <Visibility onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                                )}
                              </span>
                            </div>
                            {errors.confirmPassword && <p className="text-xs text-red-600 mt-2">Confirm Password is required</p>}
                            {!passwordsMatch && <p className="text-xs text-red-600 mt-2">Passwords do not match</p>}
                          </div>
                          <div className="flex flex-row items-center gap-4">
                            <button className="w-full py-3 px-4 text-primary bg-slate-100 rounded-lg" onClick={()=> setStep(1)}>Back</button>
                            <button type="submit" className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                )
            }
        }
    }


  return (
    <div>
      {!isLoading ? (
        <main className="flex items-center justify-center h-[100vh] w-full mx-auto p-6">
            { 
                <div className="w-full lg:w-[30%] bg-white border border-gray-200 rounded-xl shadow-sm ">
                    {renderSteps()}
                </div>
            }
 
        </main>
      ) : <Loader />

      }
    </div>

  );
};

export default ForgotPassword;
