import React, { useState, useEffect, useRef } from "react";
import { GET } from "../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../utils/config.jsx";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { set } from "react-hook-form";


export function CustomDropdown({ onBusinessSelect, url="", data,disable=false,title="" }) {
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedItems, setSelectedItems] = useState('');
    const [businessTitle, setBusinessTitle] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleBusinessList = async () => {
            if (data) {
                setUsers(data);
                setFilteredUsers(data);
            }
            // console.log("data", data);
        };

        handleBusinessList();
    }, [data]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (event) => {
        const searchText = event.target.value.toLowerCase();
        setSearchTerm(searchText);
    
        const filtered = users.filter((user) => {
            const fullName = `${user} `.toLowerCase();
            return fullName.includes(searchText);
        });
        setFilteredUsers(filtered);
    };
    
    const handleSelect = (country) => {
        let title = country;
        setBusinessTitle(title);
        // setSelectedItems(title);
        onBusinessSelect(title);
    };

    return (
        <div ref={dropdownRef} className="">
            <button
                id="dropdownSearchButton"
                onClick={toggleDropdown}
                data-dropdown-toggle="dropdownSearch"
                data-dropdown-placement="bottom"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                type="button"
                // disabled={disable}
            >
                {businessTitle === '' ? 'Select ' + title : businessTitle} 
                {!isOpen ? <ExpandMoreIcon className="text-black" /> : <ExpandLessIcon className="text-black" />}
            </button>

            {isOpen && (
                <div id="dropdownSearch" className="z-10 absolute  bg-white rounded-lg shadow w-60">
                    <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                id="input-group-search"
                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearch} // Add onChange handler for search input
                            />
                        </div>
                    </div>
                    <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
                        <li key={0} onClick={() => handleSelect('')}>
                            <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                                <label htmlFor={`checkbox-item-All`} className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                                    All
                                </label>
                            </div>
                        </li>
                        {filteredUsers.map((user) => (
                            <li key={user} onClick={() => handleSelect(user)}>
                                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                                    
                                    <label htmlFor={`checkbox-item-${user}`} className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                                        {user}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>

                </div>
            )}
        </div>
    );
}
