import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { POST, PUT } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "../../components/Loader.jsx";
import { TokenService } from "../../utils/TokenService.ts";

export default function Content({state}) {
    
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  const [isLoading, setIsLoading] = useState(false); 
  const [u_id, setU_id] = useState(0);

  useEffect(() => {
    if (state) {
      setU_id(state.id);
    }
    else{
      setU_id(TokenService.getUser().user.id);
    }
  }, [state]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle confirm password visibility
  };

  const handlePasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const password = e.target.form.password.value;
    setPasswordsMatch(password === confirmPassword);
  };


  const onSubmit = async (data) => {
    console.log(data);
    const res = await PUT(`${BASE_URL}api/User/changePassword`,{
        userId: u_id,
        password: data.password,
        confirmPassword: data.confirmPassword
      });
      if(res.status === "success"){
        alert("Password changed successfully");
        window.history.back();
      }
      else{
        alert("Error changing password");
      }
    }



  return (
    <div className="">
         <div className="w-full  ">
         <h3 className="text-xl text-primary font-bold pb-4">
          Change Password
        </h3>
            <div className="pt-5  ">
              <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="flex flex-row items-end gap-3 w-full">
                    <div>
                      <label htmlFor="password" className="block text-sm">Password</label>
                      <div className="relative">
                        <input type={showPassword ? "text" : "password"} id="password" {...register('password', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`} />
                        {/* Eye icon to toggle password visibility */}
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          {showPassword ? (
                            <VisibilityOff onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                          ) : (
                            <Visibility onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                          )}
                        </span>
                      </div>
                      {errors.password && <p className="text-xs text-red-600 mt-2">Password is required</p>}
                    </div>
                    <div >
                      <label htmlFor="confirmPassword" className="block text-sm">Confirm Password</label>
                      <div className="relative">
                        <input type={showConfirmPassword ? "text" : "password"} id="confirmPassword" {...register('confirmPassword', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`} />
                        {/* Eye icon to toggle confirm password visibility */}
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          {showConfirmPassword ? (
                            <VisibilityOff onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                          ) : (
                            <Visibility onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                          )}
                        </span>
                      </div>
                      {errors.confirmPassword && <p className="text-xs text-red-600 mt-2">Confirm Password is required</p>}
                      {!passwordsMatch && <p className="text-xs text-red-600 mt-2">Passwords do not match</p>}
                    </div>
                    <div className="mt-5">
                      <button type="submit" className="w-full py-3 px-4 bg-primary hover:bg-blue-500 text-white rounded-lg">Set Password</button>
                    </div>
                  </div>
              </form>
            </div>
          </div>
    </div>
  )
}
