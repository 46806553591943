import React from 'react'
import Baselayout from "../components/layouts/Baselayout";
import Content from './sendnotification/content'

export default function Notify() {
  return (
    <Baselayout>
    <Content />
    </Baselayout>
  )
}
