import React, { useState, useEffect } from "react";
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import BusinessDropdown, { UserDropdown, BusinessUserDropdown } from '../../components/dropdown';
import Modal from 'react-modal';
import { GET, POST } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config";
import { TokenService } from "../../utils/TokenService.ts";
import { set } from "react-hook-form";
import { max } from "moment-timezone";

const CustomMarker = ({ text }) =>
    <div className="text-primary text-48 text-center items-center justify-center -translate-x-1/2 -translate-y-1/2" >
        <LocationOnIcon />
    </div>;

export default function Content() {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [filterModal, setFilterModal] = useState(false);
    const [mapModal, setMapModal] = useState(false);
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [userdisable, setUserDisable] = useState(false)
    const [businessdisable, setBusinessDisable] = useState(false)
    const [filterdisable, setFilterDisable] = useState(false)
    const [subject, setSubject] = useState('')
    const [filterUid, setFilterUid] = useState([]);
    const [businessUserIds, setBusinessUserIds] =useState([]);
    const [userIds, setUserIds] = useState([]);
    const [filter, setFilter] = useState({
        roleId: 0,
        age: 0,
        maxAge: 0,
        gender: '',
        maritalStatus: '',
        status: '',
        longitude: 0,
        latitude: 0,
        radius: 0,
    })
    // var filterUid = [];  
    // let businessUserIds = [];
    // let userIds = []; 

    // Set the app element for React Modal
    Modal.setAppElement(document.getElementById('root'));

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            setCenter({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            });
        }, (error) => {
            console.error("Error getting geolocation:", error);
        });
        getRoles();
        
        
    }, []);

    const getRoles = async () => {
        const res = await GET(BASE_URL + 'api/Role/getRoles');
        if (res.status === 'success') {
            const filteredRoles = res.data.filter(role => role.name !== 'Admin');
            setRoles(filteredRoles);
        }
    }

    const onBusinessSelect = (selectedItems) => {
        setBusinessUserIds([])
        const ids = selectedItems.map(item => item.id);
        setBusinessUserIds(ids)
        console.log("Selected businessUserIds ID: ", businessUserIds.length);
         if (businessUserIds.length > 0){
            console.log("Business User ID: ", businessUserIds.length);
            setUserDisable(true)
            setFilterDisable(true)
        }

    }

    const onUserSelect = (selectedItems) => {
        // userIds = [];
        setUserIds([])
        console.log("Selected user ID: ", selectedItems);
        const ids = selectedItems.map(item => item.id);
        setUserIds(ids) 
        console.log(userIds.length > 0);
       if (userIds.length > 0){
            console.log("User ID: ", userIds.length);
            setBusinessDisable(true)
            setFilterDisable(true)
        }
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            //   marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '35%',
            //   height:'38vh',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: 'none',
            zIndex: 999

        },
    };

    const custom = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            // height: '40%',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: 'none',
        },
    };

    const handleMapChange = ({ center }) => {
        setCenter({
            lat: center.lat,
            lng: center.lng
        });
    };

    const renderMap = (defaultCenter) => {
        // console.log(defaultCenter);
        if (defaultCenter.lat === 0 && defaultCenter.lng === 0) {
            return <div>Loading...</div>;
        }
        else {
            return (
                <div>
                    <div style={{ height: '50vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBoTFxXAmbxt9d_SFQ5SABXmgtNO7R033I" }}
                            center={defaultCenter}
                            defaultZoom={11}
                            yesIWantToUseGoogleMapApiInternals
                            onChange={handleMapChange}
                        >
                            <CustomMarker
                                lat={defaultCenter.lat}
                                lng={defaultCenter.lng}
                                text="My Marker"
                            />
                        </GoogleMapReact>


                    </div>
                    <div className='flex items-center justify-center'>
                        <button onClick={() => handleSetCoords(center.lng, center.lat)} className="w-fit bg-primary hover:bg-blue-700 text-white  py-2 px-4 rounded-lg my-4">
                            Done
                        </button>
                    </div>
                </div>
            );
        }
    }

    const handleSetCoords = (long, lat) => {
        setMapModal(false)
        setFilter({ ...filter, longitude: parseFloat(long), latitude: parseFloat(lat) }); // Combine longitude and latitude into a single setFilter call
        console.log(long, lat)
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
    };

    const handlePrintHTML = async () => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const markup = draftToHtml(rawContentState);
    

        try {
            if (userIds.length > 0) {
                const response = await POST(BASE_URL + 'api/Mail/sendEmail', {
                    headers: {
                        'Authorization': 'Bearer ' + TokenService.getAccessToken()
                    },
                    subject: subject, // Assuming subject and filterUid are defined elsewhere in your code
                    body: markup,
                    recipientIds: userIds
                });
                if (response.status === 'success') {
                    alert('Email sent successfully');
                    window.location.reload();
                }
            }
            else if (businessUserIds.length > 0) {
                const response = await POST(BASE_URL + 'api/Mail/sendEmail', {
                    headers: {
                        'Authorization': 'Bearer ' + TokenService.getAccessToken()
                    },
                    subject: subject, // Assuming subject and filterUid are defined elsewhere in your code
                    body: markup,
                    recipientIds: businessUserIds
                });
                if (response.status === 'success') {
                    alert('Email sent successfully');
                    window.location.reload();
                }
            }
            else if (filterUid.length > 0) {
                const response = await POST(BASE_URL + 'api/Mail/sendEmail', {
                    headers: {
                        'Authorization': 'Bearer ' + TokenService.getAccessToken()
                    },
                    subject: subject, // Assuming subject and filterUid are defined elsewhere in your code
                    body: markup,
                    recipientIds: filterUid
                });
                if (response.status === 'success') {
                    alert('Email sent successfully');
                    window.location.reload();
                }
            }
            
        } catch (error) {
            console.error("Error sending email:", error);
            // Handle error
        }

        // console.log("Subject: ", subject);
        // console.log("Body: ", markup);
        // console.log("Recipient IDs: ", filterUid);
    };  

    const handleGetUsers = async () => {    
        if (filterUid.length > 0) {
            setFilterUid([]);
        }
        setIsLoading(true);
        let API_string = `${BASE_URL}api/User/getFilteredUser`;
    
        // Check if any filter criteria are selected
        if (
            filter.roleId !== 0 ||
            filter.age !== 0 ||
            filter.maxAge !== 0 ||
            filter.gender !== '' ||
            filter.maritalStatus !== '' ||
            filter.status !== '' ||
            filter.latitude !== '' || filter.longitude !== ''|| filter.radius > 0
        ) {
            // If any filter criteria are selected, construct the full API string with query parameters
            const queryParams = [];
    
            if (filter.roleId !== 0) {
                queryParams.push(`roleId=${filter.roleId}`);
            }
            if (filter.age !== 0) {
                queryParams.push(`minAge=${filter.age}`);
            }
            if (filter.maxAge !== 0) {
                queryParams.push(`maxAge=${filter.maxAge}`);
            }
            if (filter.gender !== '') {
                queryParams.push(`gender=${filter.gender}`);
            }
            if (filter.maritalStatus !== '') {
                queryParams.push(`maritalStatus=${filter.maritalStatus}`);
            }
            if (filter.status !== '') {
                queryParams.push(`status=${filter.status}`);
            }
    
            // Check if latitude, longitude, and radius are provided
            if (filter.latitude !== '' && filter.longitude !== '' && filter.radius > 0) {
                queryParams.push(`latitude=${filter.latitude}`);
                queryParams.push(`longitude=${filter.longitude}`);
                queryParams.push(`radiusInMiles=${filter.radius}`);
            }
    
            // Concatenate query parameters
            API_string += '?' + queryParams.join('&');
        }
    
        console.log(API_string);
    
        // Perform API request
        const response = await GET(API_string);
        console.log(response); // Do something with the response data
        setFilterModal(false);
        if (response.status === 'success') {
            setIsLoading(false);
            if (response.data === null) {
                alert('No Users Found');
            }
            else if(response.data.length === 0){
                alert('No Users Found');
                filterUid = [];
            }
             else {
                const newUserIds = response.data.map(user => user.id);
                // setUserIds(prevUserIds => [...prevUserIds, ...newUserIds]);
                setFilterUid([...filterUid, ...newUserIds]);
                // filterUid = filterUid.concat(newUserIds);
                if(filterUid.length > 0){
                    console.log("Filter User ID: ", filterUid.length);

               setUserDisable(true)
                 setBusinessDisable(true)
                }
               
            }
        } else {
            setIsLoading(false);
            alert("Error fetching filtered users:", response.message);
        }
    };

    const handleClearFilter = () => {
        userIds.splice(0, userIds.length);
        businessUserIds.splice(0, businessUserIds.length);
        filterUid.splice(0, filterUid.length);
        setFilterUid([])
        setUserIds([])
        setBusinessUserIds([])
        setUserDisable(false)
        setBusinessDisable(false)
        setFilterDisable(false)
        window.location.reload();
    }
    const getuserIdCount = () => {
        console.log("Filter : ", filterUid.length);
        if (userIds.length > 0) {
            return <p className='text-sm text-gray-800'>{userIds.length} Users Selected</p>
        }
        else if (businessUserIds.length > 0) {
            return <p className='text-sm text-gray-800'>{businessUserIds.length} Business Users Selected</p>
        }
        else if (filterUid.length > 0) {
            return <p className='text-sm text-gray-800'>{filterUid.length} Users Selected</p>
        }
        else {
            return <p className='text-sm text-gray-800'>0 Users Selected</p>
        }
    }
    


    return (
        <div>
            <div className="flex flex-col gap-3">
                <h2 className="text-xl font-semibold text-gray-800 ">
                    Send Email
                </h2>
                <div className='flex flex-row gap-3 my-4 items-end justify-between'>
                    <div className='flex flex-row gap-3 items-end'>
                        <div className='flex flex-col gap-1 items-start justify-center'>
                            <label className='text-sm  text-gray-800'>Select Business</label>
                            <BusinessUserDropdown disable={businessdisable} onBusinessSelect={onBusinessSelect} url={'api/User/getFilteredUser?roleId=4'} />
                        </div>
                        <div className='flex flex-col gap-1 items-start justify-center'>
                            <label className='text-sm  text-gray-800'>Select Customer</label>
                            <UserDropdown disable={userdisable} onUserSelect={onUserSelect} url={'api/User/GetAllCustomers'} />
                        </div>
                        <button className="bg-gray-200 hover:bg-gray-300 text-primary  py-3 px-4 rounded-lg w-40" onClick={handleClearFilter}>Clear Filter</button>

                    </div>
                    <div className='flex flex-col gap-1 items-start justify-center'>
                        {/* <label className='text-sm  text-gray-800'>Filter</label> */}
                        <button disabled={filterdisable} className="bg-primary hover:bg-blue-700 text-white  py-3 px-4 rounded-lg w-40 disabled:opacity-50" onClick={() => setFilterModal(true)}>Filter</button>
                    </div>
                </div>
               
                <div className="flex flex-col gap-2">
                    <label className='text-sm text-gray-800'>User Count</label>
                    
                    {getuserIdCount()}
                </div>
                <div className="flex flex-col gap-2">
                    <label className='text-sm text-gray-800'>Subject</label>
                    <input
                        type="text"
                        className="w-full border border-gray-300 p-2 rounded-lg"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>

                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={onEditorStateChange}
                    editorStyle={{ height: '300px', border: '1px solid #F1F1F1', padding: '5px', zIndex: 0, position: 'relative' }}
                />
                <div className='flex items-center justify-end'>
                    <button className="bg-primary hover:bg-blue-700 text-white  py-3 px-4 rounded-lg w-40" onClick={handlePrintHTML}>Send</button>
                </div>
            </div>

            {/* filter Modal */}
            <Modal
                isOpen={filterModal}
                onRequestClose={() => setFilterModal(false)}
                appElement={document.getElementById('your-app-element-id')}
                style={customStyles}
            // zIndex={999}
            >
                <div className='flex flex-col justify-center gap-2 p-6'>

                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Select Role</label>
                        <select
                            className="w-full border border-gray-300 p-2 rounded-lg"
                            onChange={(e) => setFilter({ ...filter, roleId: parseInt(e.target.value) })}
                            value={filter.roleId} // Set the default value to filter.roleId
                        >
                            <option value={0}>Select</option>
                            {roles.map(role => (
                                <option key={role.id} value={role.id}>{role.name}</option>
                            ))}
                        </select>
                    </div>

                        <div className='flex flex-row items-start justify-between gap-2'>

                            <div className='flex flex-col items-start justify-center gap-2'>
                                <label className='text-sm text-gray-800'>Min Age</label>

                                <input
                                    type="number"
                                    className="w-full border border-gray-300 p-2 rounded-lg"
                                    min={12}
                                    value={filter.age}
                                    onChange={(e) => setFilter({ ...filter, age: parseInt(e.target.value) })}
                                />
                            </div>

                            <div className='flex flex-col items-start justify-center gap-2'>
                                <label className='text-sm text-gray-800'>Max Age</label>

                                <input
                                    type="number"
                                    className="w-full border border-gray-300 p-2 rounded-lg"
                                    min={filter.age+1}
                                    value={filter.maxAge}
                                    onChange={(e) => setFilter({ ...filter, maxAge: parseInt(e.target.value) })}
                                />
                            </div>
                        </div>

                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Gender</label>
                        <select
                            className="w-full border border-gray-300 p-2 rounded-lg"
                            value={filter.gender}
                            onChange={(e) => setFilter({ ...filter, gender: e.target.value })}
                        >
                            <option>Select</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>

                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Marital Status</label>
                        <select
                            className="w-full border border-gray-300 p-2 rounded-lg"
                            value={filter.maritalStatus}
                            onChange={(e) => setFilter({ ...filter, maritalStatus: e.target.value })}
                        >
                            <option>Select</option>
                            <option >All</option>
                            <option value='Single'>Single</option>
                            <option value='Married'>Married</option>
                            <option value='Divorced'>Divorced</option>
                            <option value='Student'>Student</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div>

                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Status</label>
                        <select
                            className="w-full border border-gray-300 p-2 rounded-lg"
                            value={filter.status}
                            onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                        >
                            <option>Select</option>
                            <option value='Active'>Active</option>
                            <option value='InActive'>InActive</option>
                        </select>
                    </div>
                    
                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Location</label>
                        <button
                            className="w-full bg-primary hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
                            onClick={(e) => setMapModal(true)}
                        >
                            Select Location
                        </button>
                    </div>

                    <div className='flex flex-col items-start justify-center gap-2'>
                        <label className='text-sm text-gray-800'>Radius in Miles</label>
                        <input
                            type="number"
                            className="w-full border border-gray-300 p-2 rounded-lg"
                            value={filter.radius}
                            min={5}
                            onChange={(e) => setFilter({ ...filter, radius: parseInt(e.target.value) })}
                        />
                    </div>

                    <div className='flex items-center justify-center'>
                        <button
                            onClick={handleGetUsers}
                            className="w-fit bg-primary hover:bg-blue-700 text-white py-2 px-4 rounded-lg"
                        >
                            Get Users
                        </button>
                    </div>
                </div>
            </Modal>

            {/* Map Modal */}
            <Modal
                isOpen={mapModal}
                onRequestClose={() => setMapModal(false)}
                style={custom}
            >
                <div >
                    {renderMap(center)}
                </div>
            </Modal>
        </div>
    );
}


