import React, { useEffect, useState } from 'react'
import { GET, POST, PUT } from '../../utils/ApiRequestProvider.tsx';
import { BASE_URL } from '../../utils/config.jsx';

export default function Content() {
  const [doc, setDoc] = useState({ base64: '', name: '', type: '' });
  const [aggrementUrl , setAggrementUrl] = useState('');
  const [btn, setBtn] = useState(true);
  const [id, setId] = useState(0);

  useEffect(() => {
    handleGetDoc();
  }, [])

  const handleGetDoc = async () => {
    const res = await GET(BASE_URL + 'api/Terms/GetTerms?termType=customer')
    if (res.status === 'success') {
      setAggrementUrl(BASE_URL+res.data.filePath);
      setId(res.data.id);
      console.log('aggrementUrl', BASE_URL+res.data.filePath);

    }
  }


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const base64 = reader.result;
          setDoc({
            base64: base64.split(',')[1],
            name: file.name,
            type: file.type,
          });
          if (file.type === 'application/pdf') {
            setBtn(false);
          } else {
            setBtn(true);
          }
        } catch (error) {
          console.error('Error reading file:', error);
        }
      };
      reader.readAsDataURL(file);
    }
    console.log(doc);
  };

  const handleUpload = async() => {
    const res = await POST(BASE_URL + 'api/Terms/saveTermsAndConditions',
    {
      id: id,
      type: 'customer',
      termsBase64Data: doc.base64,
      termsName: doc.name,
      termsType: doc.type,
    })
    if (res.status === 'success') {
      alert('Document uploaded successfully');
      window.location.reload();
    } else {
      alert('Error uploading document');
    }
  }
  

  return (
    <div>
      <div className='flex flex-row items-start justify-between gap-2 my-4'>
        <h1 className="text-2xl font-bold">Customer Agreement</h1>
        
      </div>
      <div className="flex flex-col items-center justify-center h-[80vh] w-full">

        <object data={aggrementUrl} type="application/pdf" width="100%" height="100%" >
        </object>
      </div>
      <div className='flex flex-row items-end justify-end gap-2 my-4'>
        <div className="flex flex-col ">
          <div className="flex flex-row items-end gap-2">
            <div className="flex flex-col text-sm text-gray-500">
              <label className="pb-1">Update Aggrement</label>
              <input
                className="py-2 px-2 rounded-md border w-full"
                type="file"
                onChange={(e) => handleFileChange(e)}
              />

            </div>
            <button className="bg-primary text-white px-3 py-2 h-10 rounded-lg disabled:bg-blue-300" disabled={btn} onClick={handleUpload}>
              Update
            </button>
          </div>
          {doc.type !== 'application/pdf' && doc.type !== '' && (
            <p className="text-red-500 text-xs">Please select a PDF file</p>
          )}
        </div>


      </div>
    </div>

  )
}
