import React, { useState, useEffect } from "react";
import { POST, GET } from "../../utils/ApiRequestProvider.tsx";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { BASE_URL } from "../../utils/config.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import { UnfeaturedBusinessDropdown } from "../../components/dropdown.jsx";
import Loader from "../../components/Loader.jsx";

const ResultsPerPage = 10;

export default function Content() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;
  const [search, setSearch] = useState('');



  const handleBusinessSelect = (id) => {
    setSelectedBusinessId(id);
    console.log('businessID', id);
  };

  const UserList = async () => {
    setLoading(true);
    const res = await GET(BASE_URL + "api/Admin/GetFeaturedBusinessesList");
    if (res.status === 'success') {
      setUsers(res.data); // Update users state with filtered data
      setFilteredUsers(res.data);
      console.log('data',res.data);
      setLoading(false);
    }
    else {
      alert(res.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    UserList();
  }, []);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleBoostBusiness = async () => {
    setLoading(true);
    console.log('Boosting Business');
    const res = await POST(BASE_URL + `api/Admin/addBusinessFeatured?featuredProductId=10&businessId=${selectedBusinessId}`, {
      Authorization: `Bearer ${TokenService.getAccessToken()}`,
    });
    if (res.status === 'success') {
      alert('Business Boosted Successfully');
      UserList();
      setLoading(false);
    }
    else {
      UserList();
      alert(res.message);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredData = users.filter((user) => {
      return user.title.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredUsers(filteredData);
  };

  return (
    <div>
      {loading ? <Loader /> :
        <div>
          <div className="px-6 py-4 flex items-center justify-between">
        
          <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e)=>handleSearch(e)}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>

            <div className="flex flex-row gap-3">
              <button
                className={`inline-flex items-center gap-x-2 px-4 py-2 text-sm font-medium text-white ${selectedBusinessId !== null ? 'bg-primary' : 'bg-slate-300'}   border border-transparent rounded-md shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-dark `}
                disabled={selectedBusinessId === null}
                onClick={handleBoostBusiness}

              >
                Boost Business
              </button>
              <UnfeaturedBusinessDropdown onBusinessSelect={handleBusinessSelect} url={'api/Admin/GetBusinessesList'} />

            </div>
          </div>
          <div className=" sm:px-6  mx-auto">
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">Featured Business</h2>
                      </div>

                      <div></div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Name
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Status
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Phone
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Address
                              </span>
                            </div>
                          </th>

                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7)
                            &&
                            (
                              <th scope="col" className="px-6 py-3 text-end">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                    Action
                                  </span>
                                </div>
                              </th>

                            )
                          }

                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {filteredUsers.slice(startIndex, endIndex).map((user, index) => (
                          <tr key={index}>
                            <td className="size-px whitespace-nowrap">
                              <div className="ps-6 lg:ps-3  px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <img
                                    className="inline-block size-[38px] rounded-full"
                                    src="images/image-placeholder.jpg"
                                    alt="Image Description"
                                  />
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 ">
                                      {user.title}
                                    </span>
                                    <span className="block text-sm text-gray-500">
                                      {user.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                  <svg
                                    className="size-2.5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                  </svg>

                                  {user.isFeatured ? "Featured" : "Not Featured"}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <span className="text-sm text-gray-500">
                                    {user.phone}
                                  </span>
                                </div>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {user.address}
                                </span>
                              </div>
                            </td>


                            {
                              (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (

                                <td className="size-px whitespace-nowrap">
                                  <div className="px-6 py-1.5 flex gap-3">
                                    <a
                                      className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                      href="#"
                                    >
                                      <EditIcon className="text-primary hover:text-black" />
                                    </a>

                                    <a
                                      className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                      href="#"
                                    >
                                      <BlockIcon className="text-primary hover:text-black" />
                                    </a>
                                  </div>
                                </td>
                              )
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{users.length}</span>{" "}
                          results
                        </p>
                      </div>

                      <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(users.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(users.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
