import React, { useState, useEffect, useRef } from "react";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { POST, GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import ChatIcon from '@mui/icons-material/Chat';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { set } from "react-hook-form";
import Chat from "@mui/icons-material/Chat";
import { getAuth } from "firebase/auth";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
  addDoc,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Modal from "react-modal";
import { TokenService } from "../../utils/TokenService.ts";


export default function Content() {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [friendList, setFriendList] = useState([]);
  const [selectedFriendId, setSelectedFriendId] = useState(0);
  const [userId, setUserId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const chatContainerRef = useRef(null);
  const auth = getAuth();
  const user = auth.currentUser?.uid;
  const [chatList, setChatList] = useState([]);
  const [description, setDescription] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isCollapsed, setIsCollapsed] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID
  const custom = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  // const handleGetUserList = async () => {
  //   try {
  //     const res = await GET(BASE_URL + "api/User/GetAllUsersList");
  //     if (res.status === 'success') {
  //       const FilteredUsers = res.data.filter((item) => item.role?.name !== 'Admin' && item.firebaseUserId !== 'undefined' && item.firebaseUserId !== null);
  
  //       // Filter users based on the search query
  //       let filteredList = FilteredUsers;
  //       if (searchQuery.trim() !== "") {
  //         filteredList = FilteredUsers.filter((user) =>
  //           user.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  //           user.lastName.toLowerCase().includes(searchQuery.toLowerCase())
  //         );
  //       }
  
  //       // Separate filtered users based on role
  //       const usersByRole = {};
  //       filteredList.forEach(user => {
  //         if (!usersByRole[user.role.name]) {
  //           usersByRole[user.role.name] = [];
  //         }
  //         usersByRole[user.role.name].push(user);
  //       });
  
  //       // Update friendList state
  //       setFriendList(usersByRole);
  //       setChatList(res.data);
  //     } else {
  //       console.log('Error fetching friend list:', res);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching friend list:', error);
  //   }
  // }
  const handleGetUserList = async () => {
    try {
      setIsLoading(true); // Set loading state to true when search starts
      const res = await GET(BASE_URL + "api/User/GetAllUsersList");
      if (res.status === 'success') {
        let FilteredUsers = [];
        if(TokenService.getUserRole() === 4){
          FilteredUsers = res.data.filter((item) => item.roleId !== 4 && item.roleId !== 3 && (item.businessId === null || item.businessId === TokenService.getUser().user.businessId )&&item.roleId !== 6 && item.firebaseUserId !== 'undefined' && item.firebaseUserId !== null);
        }
        else{
        FilteredUsers = res.data.filter((item) => item.role?.name !== 'Admin' && item.firebaseUserId !== 'undefined' && item.firebaseUserId !== null);
        }
        // Filter users based on the search query
        let filteredList = FilteredUsers;
        if (searchQuery.trim() !== "") {
          filteredList = FilteredUsers.filter((user) => {
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            return fullName.includes(searchQuery.toLowerCase());
          });
        }
  
        // Separate filtered users based on role
        const usersByRole = {};
        filteredList.forEach(user => {
          if (!usersByRole[user.role.name]) {
            usersByRole[user.role.name] = [];
          }
          usersByRole[user.role.name].push(user);
        });
  
        // Update friendList state
        setFriendList(usersByRole);
        setChatList(res.data);
      } else {
        console.log('Error fetching friend list:', res);
      }
    } catch (error) {
      console.error('Error fetching friend list:', error);
    } finally {
      setIsLoading(false); // Set loading state to false when search completes
    }
  };
  
  
  useEffect(() => {
    handleGetUserList();
  }, [searchQuery]);

  const handleFriendSelection = async (friendId, u_id, f_name, l_name) => {
    setUserId(u_id);
    setSelectedFriendId(friendId);
    setName(f_name + " " + l_name);
    const q = query(
      collection(db, "conversations"),
      // where("uid", "in", [user, friendId]),
      orderBy("createdAt", "desc"),
      limit(50)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = [];
      querySnapshot.forEach((doc) => {
        fetchedMessages.push({ ...doc.data(), id: doc.id });
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      console.log(friendId);
      const filteredMessages = sortedMessages.filter((message) => {
        return message.receiver === friendId && message.sender === user || message.receiver === user && message.sender === friendId
      })
      setMessages(filteredMessages);
      scrollToBottom(); // Scroll to bottom after loading messages
    });
    return () => unsubscribe();
  };

  const scrollToBottom = () => {
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    let { displayName, photoURL } = auth.currentUser;
    let avatar ;
    console.log(displayName);
    if (displayName === null) {
      console.log(user);
      console.log(chatList);
      const userName = chatList.filter((item) => item.firebaseUserId === user)[0].firstName;
      console.log(userName);
      if(userName === 'undefined'){
        displayName = 'NA';
      }
      else{
        displayName = chatList.filter((item) => item.firebaseUserId === user)[0].firstName;
      }
      avatar = chatList.filter((item) => item.firebaseUserId === user)[0].profilePath;
    }
    const new_message =await addDoc(collection(db, "conversations"),
    
    {
      text:message,
      sender: user,
      receiver: selectedFriendId,
      createdAt: new Date().getTime(),
      user: {
        _id: user ,
        name: displayName,
      },
    });
    console.log("Document written with ID: ", new_message.id);
    setMessage("");
    scrollToBottom(); // Scroll to bottom after sending message
  };

  const handleSubmitTicket = async (event) => {
    event.preventDefault();
    const res = await POST(BASE_URL + "api/Ticket/createTicket", {
      description: description,
      createdById: TokenService.getUser().user.id,
      ticketForUserId: userId,
      status: "Open",
    });
    if (res.status === "success") {
      console.log("Ticket created successfully");
      alert("Ticket created successfully");
      setModalIsOpen(false);
      window.location.reload();

    }
    else {
      alert(res.message);
      // alert("Error creating ticket");
      setModalIsOpen(false);
    }
  }
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  
  // Trigger filtering only if searchQuery is not empty
  useEffect(() => {
    if (searchQuery === "") {
      setFriendList([]); // Clear the friendList when searchQuery is empty
      return;
    }
  
    handleGetUserList();
  }, [searchQuery]);
  
  const initializeCollapseState = () => {
    const collapseState = {};
    Object.keys(friendList).forEach((role, index) => {
      collapseState[role] = index === 0 ? false : true;
    });
    setIsCollapsed(collapseState);
  };
  
  // Initialize collapse state when friendList changes
  useEffect(() => {
    initializeCollapseState();
  }, [friendList]);
  
  // Function to toggle collapse state
  const toggleCollapse = (role) => {
    setIsCollapsed((prevState) => ({
      ...prevState,
      [role]: !prevState[role],
    }));
  };
  
  return (
    <div className="flex h-screen antialiased text-gray-800">
      <div className="flex flex-row h-full w-full overflow-x-hidden">
        <div className="flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0">
          <div className="flex flex-col mt-8">
            <div className="flex flex-row items-center justify-between text-xs">
              <span className="font-bold">Chats</span>
              <span className="flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full text-primary">{chatList.length - 1}</span>
            </div>
            <div className="flex flex-row items-center justify-between text-xs">
            <input
  type="text"
  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
  value={searchQuery}
  onChange={handleSearchInputChange}
  placeholder="Search users..."
/>
</div>
            <div className="flex flex-col space-y-1 mt-4 -mx-2 h-full overflow-y-auto">
            {isLoading && (
              <div className="flex items-center justify-center h-full">
                <p>Loading...</p>
              </div>
            )}
            {Object.entries(friendList).map(([role, users]) => (
        <div key={role}>
          <div className="flex items-center justify-between">
            <h2 className="font-bold cursor-pointer" onClick={() => toggleCollapse(role)}>
              {role}
            </h2>
            <button onClick={() => toggleCollapse(role)}>
            <ArrowDropDownCircleIcon style={{ fill: '#275DAB' }}/>
            </button>
          </div>
          {/* Check if the role is collapsed before rendering the users */}
          {!isCollapsed[role] && (
            <div className="flex flex-col space-y-1 mt-4">
              {users.map((friend, index) => (
                <button
                  key={index}
                  className="flex flex-row items-center hover:bg-gray-100 rounded-xl p-2"
                  onClick={() => handleFriendSelection(friend.firebaseUserId, friend.id, friend.firstName, friend.lastName )}
                >
                  <div className="flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full">
                    {friend.firstName[0]}
                  </div>
                  <div className="ml-2 text-sm font-semibold"><p> {friend.firstName} {friend.lastName} </p></div>
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-auto h-auto p-6">
          <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4">
            <div ref={chatContainerRef} className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                <div className="flex flex-row items-center justify-between bg-primary rounded-xl p-4">
                  <span className=" font-medium text-lg text-white">{name.toUpperCase()}</span>
                <button className="flex flex-row items-center justify-center bg-white hover:bg-slate-100 rounded-xl text-primary px-4 py-1 flex-shrink-0"
                  onClick={(e)=> setModalIsOpen(!modalIsOpen)}>
                  <span>Create Ticket</span>
                  </button>
                </div>
                <div className=" grid-cols-12 gap-y-2">
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`col-start-${message.user.sender === user ? '6' : '1'} col-end-${message.sender === user ? '13' : '8'} p-3 rounded-lg`}
                    >
                      <div className={message.sender=== user ? "flex items-center justify-start flex-row-reverse" : "flex flex-row items-center"}>
                        <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                          {message.user.name?.slice(0, 1)}
                        </div>
                        <div className={`relative ${message.sender === user ? 'mr-3' : 'ml-3'} text-sm ${message.sender === user ? 'bg-indigo-100' : 'bg-white'} py-2 px-4 shadow rounded-xl`}>
                          <div>{message.text}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
                  {name !== "" && (
            <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
              <form onSubmit={(event) => sendMessage(event)} className="send-message w-full flex flex-row">
                <div className="flex-grow ml-4">
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="ml-4">
                  <button className="flex items-center justify-center bg-primary hover:bg-blue-600 rounded-xl text-white px-4 py-1 flex-shrink-0">
                    <span>Send</span>
                    <span className="ml-2">
                      <svg className="w-4 h-4 transform rotate-45 -mt-px" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          )}

          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {setModalIsOpen(false); setDescription("")}}
        style={custom}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 ">
            <h1 className="text-2xl font-bold">Create Ticket</h1>
          <form className="flex flex-col gap-2">
            <label className="text-sm text-gray-600">Description</label>
            <textarea
              className=" h-32 rounded-lg  border border-gray-300 w-96 p-2 focus:outline-none focus:border-primary"
              placeholder="Enter your message here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <button onClick={handleSubmitTicket} className="bg-primary text-white rounded-lg p-2">Submit</button>
          </form>
        </div>
      </Modal>
    </div>
  );
}