import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../utils/config";
import { GET, POST, PUT } from "../../utils/ApiRequestProvider.tsx";
import { set, useForm } from 'react-hook-form';
import Loader from "../../components/Loader.jsx";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { TokenService } from "../../utils/TokenService.ts";
import Modal from "react-modal";
import CitySelect from "../../components/cityselectmodal.jsx";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import modalStyle from "../../components/modalStyle";

export default function Content({ state }) {
  const { register: registerVehicle, handleSubmit: handleSubmitVehicle, formState: { errors: errorsVehicle }, setValue: setValueVehicle } = useForm();
  const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, setValue: setValue } = useForm();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [vin, setVin] = useState('');
  const [vehicleData, setVehicleData] = useState({
    manufacturer: '',
    make: '',
    model: '',
    year: '',
    engine: '',
    transmission: ''
  });
  const [profileImgEdit, setProfileImgEdit] = useState(false);
  const [profileImage, setProfileImage] = useState({ base64: '', name: '', type: '' });
  const [profileEdit, setProfileEdit] = useState(false);
  const [vehicleEdit, setVehicleEdit] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cityModal, setCityModal] = useState(false);
  const [city, setCity] = useState({ id: 0, name: '' });
  const [v_Id, setV_Id] = useState(0);
  const [v_error, setV_error] = useState('');
  const [v_loading, setV_loading] = useState(false);

  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setProfileImage({
        base64: base64Data,
        name: file.name,
        type: file.type,
      });
    }
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (state) {
      handleGetData();
    }
  }, [state]);

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${state}`);
    if (res.status === 'success') {
      setUserData(res.data);
      console.log(res.data);
      setIsLoading(false);
      if (res.data.status === 'Active') {
        setUserStatus(true);
      }
      else {
        setUserStatus(false);
      }
      setValue('firstName', res.data?.firstName);
      setValue('lastName', res.data?.lastName);
      setValue('gender', res.data?.gender);
      setValue('maritalStatus', res.data?.maritalStatus);
      setValue('dob', UTCtoDate(res.data?.dob));
      setValue('monthlyIncome', res.data?.monthlyIncome);
      setValue('email', res.data?.email);
      setValue('phone', res.data?.phone);
      setValue('zipCode', res.data?.zipCode);
      setValue('address', res.data?.address);
      setValue('city', res.data?.city?.name);
      setValue('city_id', res.data?.city?.id);

      res.data?.vehicle?.forEach(element => {
        setV_Id(element.id);
        setValueVehicle('manufacturer', element.manufacturer);
        setValueVehicle('make', element.make);
        setValueVehicle('model', element.model);
        setValueVehicle('year', element.year);
        setValueVehicle('engine', element.engine);
        setValueVehicle('transmission', element.transmission);       
      });


     
    }
  }

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}/${month}/${day}`;

    return shortDate;
  };

  const handleProfileImgUpdate = async () => {
    const res = await PUT(BASE_URL + `api/User/updateUserProfilePic`, {
      userId: userData.id,
      profilePic: profileImage.base64,
      profilePicName: profileImage.name,
      profilePicType: profileImage.type
    });
    if (res.status === 'success') {
      alert('Profile Image Updated Successfully');
      window.location.reload();
    }
  }

  const UTCtoDate = (d) => {
    if (d) {
      const date = d?.split('T')[0].slice(0, 10).split('-');
      const formattedDate = `${date[0]}-${date[1]}-${date[2]}`;
      return formattedDate;
    }
  }

  const DatetoUTC = (date) => {
    const dateObj = new Date(date);
  
    return dateObj.toString().split(' (')[0];
  }

  const onSubmit = async (data) => {
    const res = await POST(BASE_URL + `api/User/requestUserinfoUpdate`, {
      userId: state,
      firstName: data.firstName,
      lastName: data.lastName,
      dob: data.dob,
      gender: data.genger,
      phone: data.phone,
      cityId: data.city_id,
      zipCode: data.zipCode,
      address: data.address,
      lati: 0,
      long: 0,
      maritalStatus: data.maritalStatus,
      monthlyIncome: data.monthlyIncome,
      requestedById: TokenService.getUser()?.user?.id,
      description: data.description,
    });
    if (res.status === 'success') {
      alert('User Info Update Requested Successfully');
      window.location.reload();
    }
    // console.log(JSON.stringify({
    //   userId: state,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   dob: DatetoUTC(data.dob),
    //   gender: data.genger,
    //   phone: data.phone,
    //   cityId: data.city_id,
    //   zipCode: data.zipCode,
    //   address: data.address,
    //   lati: 0,
    //   long: 0,
    //   maritalStatus: data.maritalStatus,
    //   monthlyIncome: data.monthlyIncome,
    //   requestedById: TokenService.getUser()?.user?.id,
    //   description: data.description,
    // }))
  };

  const handleStatusChange = async () => {
    if (userStatus) {
      activateUser('Active')
    }
    else if (!userStatus) {
      activateUser('InActive')
    }
  }

  const activateUser = async (s) => {
    const res = await PUT(BASE_URL + `api/User/updateUserStatus`, {
      userId: userData.id,
      status: s
    });
    console.log(res);
    if (res.status === 'success') {
      alert('User Status Updated Successfully');
      window.location.reload();
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };

  const custom = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '65%',
    },
  };

  function handleCitySelection(city) {
    console.log('Selected city ID:', city);
    if(city !== null){
      setValue('city', city.name);
      setValue('city_id', city.id);
      setCity({id: city.id, name: city.name})
      setCityModal(false);
    }  
  }




  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl text-primary font-bold pb-5">
        Worker Details
        </h3>

        <label className="flex cursor-pointer gap-2 items-center justify-end">
          <p className="text-sm">In Active</p>
          <input
            type="checkbox"
            defaultChecked={userData.status} className="sr-only peer"
            checked={userStatus}
            onChange={() => setModalIsOpen(true)}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
          <p className="text-sm">Active</p>
        </label>
      </div>
      {isLoading ?
        <Loader />
        :
        <>
          {/* Profile image section */}
          <div className="flex flex-row items-start justify-between w-fit">
            <div className="flex flex-col pb-5" >
              <img
                src={profileImage.base64 !== '' ? profileImage.base64 : (userData.profilePath ? BASE_URL + userData.profilePath : "images/image-placeholder.jpg")}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              {profileImgEdit &&
                <div className="py-3">
                  <input
                    className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                    id="file_input"
                    type="file"
                    onChange={(event) => handleImageChange(event)}
                    disabled={!profileImgEdit}
                  />

                  <div className="flex gap-3 py-3">
                    <button className="text-white bg-primary rounded-lg px-4 py-2"
                      onClick={handleProfileImgUpdate}
                    >
                      Save
                    </button>
                    <button
                      className="text-primary bg-gray-200 rounded-lg px-4 py-2"
                      onClick={() => setProfileImgEdit(!profileImgEdit)}
                    >
                      Cancel
                    </button>
                  </div>

                </div>
              }
            </div>
            <div className="flex flex-col flex-grow items-between">
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileImgEdit(!profileImgEdit)}>
                <EditIcon />
              </button>

            </div>
          </div>

          <div>

            {/* Personnal Info */}
            <>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="flex flex-row items-center justify-between">
                    <h3 className="text-xl text-gray-700 font-semibold pb-5">
                      Personnal Info
                    </h3>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileEdit(!profileEdit)}>
                      <EditIcon />
                    </button>
                  </div>
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="overflow-hidden">

                        <div className="flex md:flex-row flex-col gap-10 pb-3">
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="firstName" className="pb-1">First Name</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={userData?.firstName}
                              id="firstName" {...register('firstName', { required: true })}
                              disabled={!profileEdit}
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="lastNamw" className="pb-1">Last Name</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={userData?.lastName}
                              id="lastName" {...register('lastName', { required: true })}
                              disabled={!profileEdit}
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="gender" className="pb-1">Gender</label>
                            <select disabled={!profileEdit} id="gender" {...register('gender', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.gender ? 'border-red-500' : 'border-gray-300'}`}>
                              <option value="">Select a gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="maritalStatus" className="pb-1">Marital Status</label>
                            <select className="py-2 px-2 rounded-md border w-full"
                              id="maritalStatus" {...register('maritalStatus', { required: true })}
                              defaultValue={userData?.maritalStatus}
                              disabled={!profileEdit}
                            >
                              <option >Select </option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                              <option value="Student">Student</option>
                            </select>
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="dob" className="pb-1">DOB</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="date"
                              defaultValue={UTCtoDate(userData?.dob)}
                              id="dob" {...register('dob', { required: true })}
                              disabled={!profileEdit}
                            />

                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Created At</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={formatDate(userData?.createdAt)}
                              disabled={true}
                            />
                          </div>

                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="email" className="pb-1">Email</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              disabled
                              defaultValue={userData?.email}
                              id="email" {...register('email', { required: true })}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="phone" className="pb-1">Phone</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="phone"
                              disabled={!profileEdit}
                              defaultValue={userData?.phone}
                              id="phone" {...register('phone', { required: true })}

                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="city" className="pb-1">City</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              disabled={!profileEdit}
                              defaultValue={userData?.city ? userData.city.name : ''}
                              id="city" {...register('city', { required: true })}
                              onClick={() => setCityModal(true)}
                            />
                          </div>

                         
                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                          


                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">ZIP Code</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="number"
                              disabled={!profileEdit}
                              defaultValue={userData?.zipCode}
                              id='zipCode' {...register('zipCode', { required: true })}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Address</label>
                            <textarea
                              className="py-2 px-2 rounded-md border w-full h-20"
                              type="address"
                              disabled={!profileEdit}
                              defaultValue={userData?.address}
                              id='address' {...register('address', { required: true })}

                            />
                          </div>
                        </div>
                        {profileEdit &&
                          <div className="flex md:flex-row flex-col gap-10 pb-3">

                            <div className="flex flex-col text-sm text-gray-500 w-full">
                              <label htmlFor="description" className="pb-1">Reason</label>
                              <input
                                className="py-2 px-2 rounded-md border w-full"
                                type="text"
                                disabled={!profileEdit}
                                id="description" {...register('description')}
                              />
                            </div>

                          </div>
                        }

                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {profileEdit &&
                <div className="flex justify-end gap-3">
                  <button
                    type="submit"
                    className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                    onClick={() => setProfileEdit(!profileEdit)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary text-white py-2 px-4 rounded-lg"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                </div>
              }
            </>


          </div>
        </>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 p-5">
          <h2 className="text-xl font-semibold text-gray-700">Are you sure you want to {userStatus ? 'deactivate' : 'activate'} the user?</h2>
          {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
        </div>

        <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
            Cancel
          </button>
          <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => handleStatusChange()}>
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={cityModal}
        onRequestClose={() => setCityModal(false)}
        style={custom}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 p-5">
          <CitySelect onCitySelect={handleCitySelection} />
        </div>
      </Modal>


    </div>
  );
}
