import React, { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import { GET } from '../../utils/ApiRequestProvider.tsx';
import { BASE_URL } from '../../utils/config.jsx';
import { Link } from 'react-router-dom';

const ResultsPerPage = 10;

export default function Content() {
    const [loading, setLoading] = useState(false);
    const [mail, setMail] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const handleGetEmails = async () => {
            setLoading(true);
            const res = await GET(BASE_URL + "api/Notification/getNotifications");
            if (res.status === 'success') {
                // const emails = res.data;
                // const senderPromises = emails.map(async (email) => {
                //     const senderName = await handleGetData(email.userId);
                //     return { ...email, userId: senderName };
                // });
                // const updatedEmails = await Promise.all(senderPromises);
                setMail(res.data);
                setLoading(false);
            } else {
                alert(res.message);
                setLoading(false);
            }
        };

        handleGetEmails();
    }, []);

    // const handleGetData = async (id) => {
    //     const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${id}`);
    //     if (res.status === 'success') {
    //         return res.data.firstName + ' ' + res.data.lastName;
    //     }
    // };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    };

    function getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    const startIndex = (currentPage - 1) * ResultsPerPage;
    const endIndex = currentPage * ResultsPerPage;

    return (
        <div>
            {loading ? <Loader /> :
                <div className=" sm:px-6 mx-auto">
                    <div className="flex flex-col">
                        <div className="-m-1.5 overflow-x-auto">
                            <div className="p-1.5 min-w-full inline-block align-middle">
                                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                                        <div>
                                            <h2 className="text-xl font-semibold text-gray-800 ">
                                                All Notifications Logs
                                            </h2>
                                        </div>

                                        <div></div>
                                    </div>

                                    <table className="min-w-full divide-y divide-gray-200 ">

                                        <thead className="bg-gray-50 ">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="ps-6 lg:ps-3 px-6 py-3 text-start"
                                                >
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                            Sent By
                                                        </span>
                                                    </div>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-start">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                            Title
                                                        </span>
                                                    </div>
                                                </th>



                                                <th scope="col" className="px-6 py-3 text-end">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                            Message
                                                        </span>
                                                    </div>
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-end">
                                                    <div className="flex items-center gap-x-2">
                                                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                                            Send Date
                                                        </span>
                                                    </div>
                                                </th>

                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200 ">
                                            {mail.slice(startIndex, endIndex).map((mail) => (
                                                <tr key={mail.id}>
                                                    <td className="size-px whitespace-nowrap">
                                                        <div className="ps-6 lg:ps-3  px-6 py-3">
                                                            <div className="flex items-center gap-x-3">
                                                                <div className="grow">
                                                                    <span className="block text-sm font-semibold text-gray-800 ">
                                                                        {mail.sentBy.firstName + ' ' + mail.sentBy.lastName}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="size-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span className="block text-sm font-semibold text-gray-800 ">
                                                                {mail.title}
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td className="size-px overflow-x-auto ">
                                                        <div className="px-6 py-3 ">
                                                            <span className="text-sm text-gray-500 w-40">
                                                                {mail.message}
                                                            </span>
                                                        </div>
                                                    </td>

                                                    <td className="size-px whitespace-nowrap">
                                                        <div className="px-6 py-3">
                                                            <span className="text-sm text-gray-500">
                                                                {formatDate(mail.createdAt)}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                                        <div>
                                            <p className="text-sm text-gray-600 ">
                                                <span className="font-semibold text-gray-800 ">{mail.length}</span>{" "}
                                                results
                                            </p>
                                        </div>

                                        <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(mail.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(mail.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
