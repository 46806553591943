import React, { useState, useEffect, useRef } from "react";
import UploadIcon from "@mui/icons-material/Download";
import {GET,POST} from "../utils/ApiRequestProvider.tsx";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { BASE_URL } from "../utils/config.jsx";
import { set } from "react-hook-form";


export default function Content({onCitySelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [businessTitle, setBusinessTitle] = useState('');
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [states, setStates] = useState([]);
  const [cities_new, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [searchState, setSearchState] = useState('');
  const [searchCity, setSearchCity] = useState('');
  const [stateTitle, setStateTitle] = useState('');
  const [cityTitle, setCityTitle] = useState('');
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [isStateOpen, setStateOpen] = useState(false);
  const [isCityOpen, setCityOpen] = useState(false);
  const dropdownRef = useRef(null);


  const handleBusinessSelect = (id) => {
    setSelectedBusinessId(id);
    handleGetState(id)
  };

  const handleGetState = async (id) => {
    console.log('id', id)
    const res = await POST(BASE_URL + `api/Region/GetStateList?CountryId=${id}`);
    if (res.status === 'success') {
      setStates(res.data);
      setFilteredStates(res.data); // Initialize filteredUsers with all users
    }
    else {
      console.log('offer', res);
    }
  }

  const handleGetCity = async (id) => {
    // console.log('id', id)
    const res = await POST(BASE_URL + `api/Region/GetCityList?StateId=${id}`);
    if (res.status === 'success') {
      setCities(res.data);
      setFilteredCities(res.data); // Initialize filteredUsers with all users
    }
    else {
      // console.log('offer', res);
    }
  }




  useEffect(() => {
    const handleBusinessList = async () => {
      const res = await GET(BASE_URL + "api/Region/GetCountryList");
      if (res.status === 'success') {
        setUsers(res.data);
        setFilteredUsers(res.data); // Initialize filteredUsers with all users
      }
    };

    handleBusinessList();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    console.log('isOpen', isOpen)

  };
  const toggleStateDropdown = () => {
    setStateOpen(!isStateOpen);

  };
  const toggleCityDropdown = () => {
    setCityOpen(!isCityOpen);

  };

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
    setSearchTerm(searchText);

    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(searchText) // Filter based on search term in title
    );
    setFilteredUsers(filtered);
  };

  const handleStateSearch = (event) => {
    const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
    setSearchState(searchText);

    const filtered = states.filter((state) =>
      state.name.toLowerCase().includes(searchText) // Filter based on search term in title
    );
    setFilteredStates(filtered);
  };

  const handleCitySearch = (event) => {
    const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
    setSearchCity(searchText);
    const filtered_city = cities_new.filter((city) =>
      city.name.toLowerCase().includes(searchText)
    );
    // console.log(filtered);
    setFilteredCities(filtered_city);
  };

  const handleSelect = (id, name) => {
    setIsOpen(false);
    // Call the parent component's callback function with the selected ID
    handleGetState(id);
    setBusinessTitle(name);
  };

  const handleStateSelect = (id, name) => {
    setStateOpen(false);
    // Call the parent component's callback function with the selected ID
    handleGetCity(id);
    setStateTitle(name);
  };

  const handleCitySelect = (id, name) => {
    setCityOpen(false);
    setCityTitle(name);
    onCitySelect(id, name);
  };

  return (
    <div className="flex flex-row gap-3">

    
    <div 
  //   className="flex flex-col items-end" 
  ref={dropdownRef} 
  
    >
      <button
        id="dropdownSearchButton"
        onClick={toggleDropdown}
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center  "
        type="button"
      >
        {businessTitle === '' ? 'Select Country' : businessTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
        {!isOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
      </button>

      {isOpen && (
        <div id="dropdownSearch" className="z-999 bg-white rounded-lg shadow w-60 overflow-y-visible">
          <div className="p-3">
            <label for="input-group-search" className="sr-only">Search</label>
            <div className="relative">
              <div className=" inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
              </div>
              <input
                type="text"
                id="input-group-search"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch} // Add onChange handler for search input
              />
            </div>
          </div>
          <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
            {filteredUsers.map((user) => (
              <li key={user.id} onClick={value => handleSelect(user.id, user.name)}>
                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                  <label for="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                    {user.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>

        </div>
      )}
    </div>

    <div 
  //   className="flex flex-col items-end" 
  ref={dropdownRef}
    >
      <button
        id="dropdownSearchButton"
        onClick={toggleStateDropdown}
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        {stateTitle === '' ? 'Select State' : stateTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
        {!isStateOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
      </button>

      {isStateOpen && (
        <div id="dropdownSearch" className="z-10  bg-white rounded-lg shadow w-60">
          <div className="p-3">
            <label for="input-group-search" className="sr-only">Search</label>
            <div className="relative">
              <div className=" inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
              </div>
              <input
                type="text"
                id="input-group-search"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search"
                value={searchState}
                onChange={handleStateSearch} // Add onChange handler for search input
              />
            </div>
          </div>
          <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
            {filteredStates.map((user) => (
              <li key={user.id} onClick={value => handleStateSelect(user.id, user.name)}>
                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                  <label for="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                    {user.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>

        </div>
      )}
    </div>

    <div 
  //   className="flex flex-col items-end" 
  ref={dropdownRef}
    >
      <button
        id="dropdownSearchButton"
        onClick={toggleCityDropdown}
        data-dropdown-toggle="dropdownSearch"
        data-dropdown-placement="bottom"
        className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
        type="button"
      >
        {cityTitle === '' ? 'Select City' : cityTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
        {!isCityOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
      </button>

      {isCityOpen && (
        <div id="dropdownSearch" className="z-10  bg-white rounded-lg shadow w-60">
          <div className="p-3">
            <label for="input-group-search" className="sr-only">Search</label>
            <div className="relative">
              <div className=" inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
              </div>
              <input
                type="text"
                id="input-group-search"
                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                placeholder="Search"
                value={searchCity}
                onChange={handleCitySearch} // Add onChange handler for search input
              />
            </div>
          </div>
          <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
            {filteredCities.map((user) => (
              <li key={user.id} onClick={value => handleCitySelect(user)}>
                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                  <label for="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                    {user.name}
                  </label>
                </div>
              </li>
            ))}
          </ul>

        </div>
      )}
    </div>
    </div>
  );
}
