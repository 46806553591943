import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./productsdetailsview/content";
import { useParams, useLocation } from "react-router-dom";

export default function ProductsDetailsView() {
  const { state } = useLocation();
  return (
    <>
      <Baselayout>
        <Content state={state}/>
      </Baselayout>
    </>
  );
}
