import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import Dropdown from "../../components/drodown.jsx";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader.jsx";
import { TokenService } from "../../utils/TokenService.ts";

const ResultsPerPage = 10;

export default function Content() {

  const [myOffers, setMyOffers] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredOffers, setFilteredOffers] = useState([]);
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;

  const handleBusinessSelect = (id) => {
    setSelectedBusinessId(id);
    handleGetOffers(id)
  };

  let today = new Date();

  const handleGetOffers = async (id) => {
    setLoading(true);
    setMyOffers([])
    var offerList = [];
   
    const res = await GET(BASE_URL + `api/Admin/getAllOffersByBusinessList?BusinessId=${id}`);
    if (res.status === 'success') {
      setMyOffers(res.data);
      setFilteredOffers(res.data);
      console.log('my Offers:', res.data);
      console.log('selectedBusinessId:', id);
      setLoading(false);
    }
    else {
      alert(res.message);
      console.log('offer', res);
      setLoading(false);
      handleGetAllOffers();
    }
  }

  useEffect(() => {
    // console.log("Appointemets", TokenService.getAccessToken());
    if (selectedBusinessId === null) {
      handleGetAllOffers();
    }
  }, []);


  const handleGetAllOffers = async () => {
    // console.log('offers', TokenService.getUser().user.business.id);
    setLoading(true);
    setMyOffers([])
    var offerList = [];
    const res = await GET(BASE_URL + "api/Admin/GetAllOfferList");
    if (res.status === 'success') {
      let offers = res.data;
      if(TokenService.getUserRole() === 4){
        offers = res.data.filter(offer => offer.businessId === TokenService.getUser().user?.business?.id);
      }
      setMyOffers(offers);
      setFilteredOffers(offers);
      console.log(res.data);
      setLoading(false);
    }
    else {
      alert(res.message);
      // console.log('offer', res);
      setLoading(false);
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    const searchValue = event.target.value;

    if (searchValue === "") {
      setFilteredOffers(myOffers);
    }
    else {
      const filteredData = myOffers.filter((offer) => {
        return offer.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          offer.business.title.toLowerCase().includes(searchValue.toLowerCase()) ||
          offer.serviceType.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          offer.status.toLowerCase().includes(searchValue.toLowerCase()) ||
          offer.price.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
          offer.discount.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
          formatDate(offer.createdAt).toLowerCase().includes(searchValue.toLowerCase()) ||
          formatDate(offer.validDate).toLowerCase().includes(searchValue.toLowerCase());
      });
      setFilteredOffers(filteredData);
    }
  }



  return (
    <div>
      {
        (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
        
      <div className="flex flex-row items-center justify-end sm:px-6 mb-6 gap-4">

        <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e)=>handleSearch(e)}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
        <div className="flex ">
          <Dropdown onBusinessSelect={handleBusinessSelect} />

        </div>
      </div>
          )
      }
      {
        loading ?
          <Loader />
          :
          <div className=" sm:px-6 mx-auto">
            <div className="flex flex-col">

              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">
                          Offers
                        </h2>
                      </div>


                      <div>
                      </div>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="ps-6 lg:ps-3 px-6 py-3 text-start"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Title
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Status
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Service
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Business Name
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Created
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Valid till
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Price
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-start">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Discount
                              </span>
                            </div>
                          </th>

                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                              <th scope="col" className="px-6 py-3 text-end">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                    Action
                                  </span>
                                </div>
                              </th>

                            )
                          }

                          <th scope="col" className="px-6 py-3 text-end">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                View
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {filteredOffers && filteredOffers.slice(startIndex, endIndex).map((offer, index) => (
                          <tr key={index}>
                            <td className="size-px whitespace-nowrap">
                              <div className="ps-6 lg:ps-3  px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <img
                                    className="inline-block size-[38px] rounded-full"
                                    src="images/image-placeholder.jpg"
                                    alt="Image Description"
                                  />
                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800  ">
                                      {offer.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                {offer.status !== 'Active' ? (
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                    <CancelIcon className="text-red-500" fontSize="12" />
                                    {offer.status}
                                  </span>
                                ) : (
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                    <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                    {offer.status}
                                  </span>
                                )}
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3 flex flex-row gap-3">
                                <span className="text-xs text-white bg-primary p-2 rounded-full">
                                  {offer.serviceType?.name}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {offer.business?.title}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {formatDate(offer.createdAt)}
                                </span>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {formatDate(offer.validDate)}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <span className="text-sm text-gray-500">
                                    ${offer.price}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {offer.discount}%
                                </span>
                              </div>
                            </td>
                            {
                              (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                                <td className="size-px whitespace-nowrap">
                                  <div className="px-6 py-1.5 flex gap-3 items-center">
                                    <Link to="/offerdetails" state={offer.id}>
                                      <span
                                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                      >
                                        <EditIcon className="text-primary hover:text-black" />
                                      </span>
                                    </Link>

                                    {/* <a
                                      className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    >
                                      <BlockIcon className="text-primary hover:text-black" />
                                    </a> */}
                                  </div>
                                </td>

                              )
                            }

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <Link to="/offerdetails" state={offer.id}>
                                  <button className="bg-primary text-white px-4 py-1 rounded-lg hover:bg-primary-dark">
                                    View
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{filteredOffers?.length}</span>{" "}
                          results
                        </p>
                      </div>

                      <div className="flex flex-row items-center gap-3">
                        <button
                          className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                        <span className="text-sm font-medium text-gray-800 ">
                          {currentPage} of {Math.ceil(myOffers?.length / ResultsPerPage)}
                        </span>
                        <button
                          className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={currentPage === Math.ceil(myOffers?.length / ResultsPerPage)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      }
    </div>
  );
}




