import React, { useState, useEffect } from "react";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MailIcon from '@mui/icons-material/Mail';
import {TokenService} from '../../utils/TokenService.ts';
import BellIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';


export default function Content() {


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div>
      <div className=" sm:px-6 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">  

              <div className="flex flex-row items-center gap-3 py-2">
                {
                  (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7 || TokenService.getUserRole() === 4 || TokenService.getUserRole() === 6) && (
                      <Link to="/tickets" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                        <div className="flex flex-row ">

                          <LocalActivityIcon className="w-8 h-8 text-gray-400" />
                          <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Tickets</h3>
                        </div>
                      </Link>
                  
                    )
                }
                <Link to="/livechat" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                  <div className="flex flex-row ">
                    <ChatIcon className="w-8 h-8 text-gray-400" />
                    <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Live Chat</h3>
                  </div>
                </Link>
                

              </div>
            {
              (TokenService.getUserRole() === 1) && (
                <div className="flex flex-row items-center gap-3 py-2">
                <Link to="/register" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                  <div className="flex flex-row ">
                    <AccountCircleIcon className="w-8 h-8 text-gray-400" />
                    <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Register</h3>
                  </div>
                </Link>
                <Link to="/salesandsupport" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                  <div className="flex flex-row ">
                    <AccountCircleIcon className="w-8 h-8 text-gray-400" />
                    <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Sales & Support</h3>
                  </div>
                </Link>

              </div>
              )

            }
              

              <div className="flex flex-row items-center gap-3 py-2">
                {
                  (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7 ) && (
                    <Link to="/notificationlogs" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                      <div className="flex flex-row ">
                        <BellIcon className="w-8 h-8 text-gray-400" />
                        <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Notifications Logs</h3>
                      </div>
                    </Link>
                  )
                }
                {/* {
                  (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7 ) && (
                    <Link to="/requests" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                      <div className="flex flex-row ">
                        <ManageAccountsIcon className="w-8 h-8 text-gray-400" />
                        <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Requests</h3>
                      </div>
                    </Link>
                  )
                } */}
                  {
                    (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6) &&(
                      <Link to="/emaillog" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                        <div className="flex flex-row ">
                          <MailIcon className="w-8 h-8 text-gray-400" />
                          <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Email Logs</h3>
                        </div>
                      </Link>
                    )
                  }
               
               
              </div>

              <div className="flex flex-row items-center gap-3 py-2">
                {
                  (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7 ) && (
                    <Link to="/requests" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                      <div className="flex flex-row ">
                        <ManageAccountsIcon className="w-8 h-8 text-gray-400" />
                        <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Requests</h3>
                      </div>
                    </Link>
                  )
                }
                {
                  (TokenService.getUserRole() === 1  ) && (
                    <Link to="/deletedusers" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                      <div className="flex flex-row ">
                        <DeleteIcon className="w-8 h-8 text-gray-400" />
                        <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Deleted Users</h3>
                      </div>
                    </Link>
                  )
                }
                  
               
               
              </div>
            </div>
          </div>
      </div>
    </div>
    </div >
  );
}


