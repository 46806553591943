import React, { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { BASE_URL } from "../../utils/config.jsx";
import { GET, PUT, POST } from "../../utils/ApiRequestProvider.tsx";
import { TokenService } from "../../utils/TokenService.ts";
import Loader from "../../components/Loader.jsx";
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'react-modal';
import CitySelect from "../../components/cityselectmodal.jsx";
import { json } from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GoogleMapReact from 'google-map-react';

const CustomMarker = ({ text }) =>{
  <div className="text-primary text-48 text-center items-center justify-center -translate-x-1/2 -translate-y-1/2" >
      <LocationOnIcon />
  </div>
}



export default function Content({ state }) {
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [workers, setWorkers] = React.useState([]);
  const [myAppointments, setMyAppointments] = React.useState([]);
  const [myOffers, setMyOffers] = React.useState([]);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [profileImage, setProfileImage] = useState({ base64: '', name: '', type: '' });
  const [businessLogo, setBusinessLogo] = useState({ base64: '', name: '', type: '' });
  const [businessDoc, setBusinessDoc] = useState({ base64: '', name: '', type: '' });
  const [businessProfile, setBusinessProfile] = useState({ base64: '', name: '', type: '' });
  const [monday, setMonday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [tuesday, setTuesday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [wednesday, setWednesday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [thursday, setThursday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [friday, setFriday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [saturday, setSaturday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [sunday, setSunday] = useState({id:0, isClosed: true, openingTime: '', closingTime: '' });
  const [profileImgEdit, setProfileImgEdit] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [profileEdit, setProfileEdit] = useState(false);
  const [city, setCity] = useState({id:0, name:''});
  const [cityUser, setCityUser] = useState({id:0, name:''});
  const [city_type, setCityType] = useState('');
  const [cityModal, setCityModal] = useState(false);
  const [businessEdit, setBusinessEdit] = useState(false);
  const { register: registerUserForm, handleSubmit: handleSubmitUser, formState: { errors: errorsUser }, setValue: setValueUser } = useForm();
  const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, setValue: setValue } = useForm();
  const [mondayEdit, setMondayEdit] = useState(false);
  const [tuesdayEdit, setTuesdayEdit] = useState(false);
  const [wednesdayEdit, setWednesdayEdit] = useState(false);
  const [thursdayEdit, setThursdayEdit] = useState(false);
  const [fridayEdit, setFridayEdit] = useState(false);
  const [saturdayEdit, setSaturdayEdit] = useState(false);
  const [sundayEdit, setSundayEdit] = useState(false);
  const [timeModal , setTimeModal] = useState(false);
  const [syncTime, setSyncTime] = useState({openingTime: '', closingTime: ''});
  const [center, setCenter] = useState({ lat: 0, lng: 0 }); 
  
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [mapModal, setMapModal] = useState(false);

 

  const renderMap = (defaultCenter) => {
    // console.log("defaultCenter",defaultCenter);
    if (defaultCenter.lat === 0 && defaultCenter.lng === 0) {
        return <div>Loading...</div>;
    }
    else {
        return (
            <div>
                <div style={{ height: '50vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBoTFxXAmbxt9d_SFQ5SABXmgtNO7R033I" }}
                        center={{lat: defaultCenter.lat, lng: defaultCenter.lng}} 
                        defaultZoom={11}
                        yesIWantToUseGoogleMapApiInternals
                        onChange={handleMapChange}
                        // style={{ height: '50vh', width: '100%',}}
              >
                <div className="text-primary text-48 text-center items-center justify-center -translate-x-1/2 -translate-y-1/2" >
                  <LocationOnIcon />
                </div>
              </GoogleMapReact>
  
  
                </div>
                <div className='flex items-center justify-center'>
                    <button onClick={() => handleSetCoords(center.lng, center.lat)} className="w-fit bg-primary hover:bg-blue-700 text-white  py-2 px-4 rounded-lg my-4">
                        Done
                    </button>
                </div>
            </div>
        );
    }
  }

  const handleMapChange = ({ center }) => {
    setCenter({
      lat: center.lat,
      lng: center.lng
    });
  };

  const handleSetCoords = (long, lat) => {
    setMapModal(false)
    setCoords({ ...coords, longitude: parseFloat(long), latitude: parseFloat(lat) }); // Combine longitude and latitude into a single setFilter call
    console.log(long, lat)
  }



  function handleUserCity(city) {
    // console.log('Selected city ID:', cityId, 'Selected city Name:', cityName, 'Selected city Long:', long, 'Selected city Lat:', lat);
    if(city !== null){
      setValueUser('city', city.name);
      setValueUser('city_id', city.id);
      setCityUser({id: city.id, name: city.name})
      setCityModal(false);
    }   
  }

  function handleBusiCity(city) {
    console.log('Selected city ID:', city.name);
    if(city !== null){
      setValue('busi_city', city.name);
      setValue('busi_city_id', city.id);
      setCity({id: city.id, name: city.name})
      setCenter({
        lat: parseFloat(city.latitude),
        lng: parseFloat(city.longitude)
      });
      setCityModal(false);
    }
    setMapModal(true);
  }


  
  

  const handleCityModalOpen = (c) => {
    setCityModal(!cityModal);
    setCityType(c);
  }

  const setActiveDay = (day) => {
    setMondayEdit(day === 'monday');
    setTuesdayEdit(day === 'tuesday');
    setWednesdayEdit(day === 'wednesday');
    setThursdayEdit(day === 'thursday');
    setFridayEdit(day === 'friday');
    setSaturdayEdit(day === 'saturday');
    setSundayEdit(day === 'sunday');
  };


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '30%',
    },
  };

  const custom = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '65%',
    },
  };

  const mapModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '65%',
    },
  };
  

  let today = new Date();

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatTime = (timeString) => {
    if (timeString !== null) {

      let time = timeString.split('T')[1]; // Extract time part from the string
      time = time.split(':').slice(0, 2).join(':'); // Extract hours and minutes and join them
      // console.log('time:', time);
      return time;
    }
  };

  React.useEffect(() => {
    if (state)
      handleGetData();
  }, [state]);

  const handlePdfDownload = () => {
    window.open(`${pdfUrl}`, '_blank');
  };

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${state}`);
    if (res.status === 'success') {

      setUserData(res.data);
      setPdfUrl(BASE_URL + res.data?.business?.filePath);
      console.log('userData', res.data);
      if (res.data.businessId) {
        getBusinessHours(res.data.businessId)
        handleGetWorkers(res.data.businessId);
        handleGetAppointments(res.data.businessId);
        handleGetOffers(res.data.businessId);
      }
      if (res.data.status === 'Active') {
        setUserStatus(true);
      }
      else {
        setUserStatus(false);
      }
      // handleGetWorkers(res.data.businessId);
      setCenter({
        lat: parseFloat(res.data?.business?.lati),
        lng: parseFloat(res.data?.business?.long)
      });
      setValueUser('firstName', res.data?.firstName);
      setValueUser('lastName', res.data?.lastName);
      setValueUser('gender', res.data?.gender);
      setValueUser('maritalStatus', res.data?.maritalStatus);
      setValueUser('dob', dateFormat(res.data?.dob));
      setValueUser('monthlyIncome', res.data?.monthlyIncome);
      setValueUser('email', res.data?.email);
      setValueUser('phone', res.data?.phone);
      setValueUser('zipCode', res.data?.zipCode);
      setValueUser('address', res.data?.address);
      setValueUser('city', res.data?.city?.name);
      setValueUser('city_id', res.data?.city?.id);      
      setValue('title', res.data?.business?.title);
      setValue('busi_phone', res.data?.business?.phone);
      setValue('busi_email', res.data?.business?.email);
      setValue('facilitylicense', res.data?.business?.facilitylicense);
      setValue('busi_city', res.data?.business?.city?.name);
      setValue('busi_zipCode', res.data?.business?.zipCode);
      setValue('busi_address', res.data?.business?.address);  
      setValue('busi_city_id', res.data?.business?.city?.id);

      setIsLoading(false);

    }
  }

  const getBusinessHours = async (business_id) => {
    const res = await GET(BASE_URL + `api/BusinessHour/getBusinessHourByBusinessId?businessId=${business_id}`);
    if (res.status === 'success') {
      console.log('business hours:', res.data);
      setMonday({
        id: res.data[0]?.id,
        isClosed: res.data[0]?.isClosed,
        openingTime: formatTime(res.data[0]?.openingTime),
        closingTime: formatTime(res.data[0]?.closingTime),
      });
      setTuesday({
        id: res.data[1]?.id,
        isClosed: res.data[1]?.isClosed,
        openingTime: formatTime(res.data[1]?.openingTime),
        closingTime: formatTime(res.data[1]?.closingTime),
      });
      setWednesday({
        id: res.data[2]?.id,
        isClosed: res.data[2]?.isClosed,
        openingTime: formatTime(res.data[2]?.openingTime),
        closingTime: formatTime(res.data[2]?.closingTime),
      });
      setThursday({
        id: res.data[3]?.id,
        isClosed: res.data[3]?.isClosed,
        openingTime: formatTime(res.data[3]?.openingTime),
        closingTime: formatTime(res.data[3]?.closingTime),
      });
      setFriday({
        id: res.data[4]?.id,
        isClosed: res.data[4]?.isClosed,
        openingTime: formatTime(res.data[4]?.openingTime),
        closingTime: formatTime(res.data[4]?.closingTime),
      });
      setSaturday({
        id: res.data[5]?.id,
        isClosed: res.data[5]?.isClosed,
        openingTime: formatTime(res.data[5]?.openingTime),
        closingTime: formatTime(res.data[5]?.closingTime),
      });
      setSunday({
        id: res.data[6]?.id,
        isClosed: res.data[6]?.isClosed,
        openingTime: formatTime(res.data[6]?.openingTime),
        closingTime: formatTime(res.data[6]?.closingTime),
      });
    }
    else {
      alert(res.message);
    }
  }

  const handleGetWorkers = async (business_id) => {
    setIsLoading(true);
    // console.log('business_id', business_id);
    const res = await GET(BASE_URL + `api/User/GetAllBusinessWorkerList?businessId=${business_id}`);
    if (res.status === 'success') {
      setWorkers(res.data);
      // console.log('workers', res.data);
      setIsLoading(false);
    }
  }

  const handleGetAppointments = async (id) => {

    const res = await GET(BASE_URL + `api/Appointment/GetAllAppointmentListByBusiness?businessId=${id}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
    });

    if (res.status === 'success') {

      setMyAppointments(res.data); // Update users state with filtered data
      // console.log(res.data);
    }
  }

  const handleGetOffers = async (id) => {

    const res = await GET(BASE_URL + `api/Admin/getAllOffersByBusinessList?BusinessId=${id}`);
    if (res.status === 'success') {
      setMyOffers(res.data);
      // console.log('my Offers:', res.data);
    }
  }

  const onSubmit = async (data) => {


    const res = await PUT(BASE_URL + `api/Business/updateBusinessProfile`, {
      forBusinessId: userData?.businessId,
      address: data.busi_address,
      cityId: data.busi_city_id,
      zipCode: data.busi_zipCode,
      long: center.lng,
      lati: center.lat,
      requestedById: TokenService.getUser()?.user?.id,
      description: data.description
    });
    if (res.status === 'success') {
      alert('Updated Successfully');
      window.location.reload();
    }
    // console.log(JSON.stringify({
    //   forBusinessId: userData?.businessId,
    //   address: data.busi_address,
    //   cityId: data.busi_city_id,
    //   zipCode: data.busi_zipCode,
    //   requestedById: TokenService.getUser()?.user?.id,
    //   description: data.description
    // }));
  }

  const handleServiceProviderSubmit = async (data) => {
    // console.log('data');
    const res = await POST(BASE_URL + `api/User/requestUserinfoUpdate`, {
      userId: userData.id,
      firstName: data.firstName,
      lastName: data.lastName,
      dob: data.dob,
      gender: data.gender,
      phone: data.phone,
      cityId: data.city_id,
      zipCode: data.zipCode,
      address: data.address,
      maritalStatus: data.maritalStatus,
      // monthlyIncome: data.monthlyIncome,
      requestedById: TokenService.getUser()?.user?.id,
      description: data.description,
    });
    if (res.status === 'success') {
      alert('User Info Update Requested Successfully');
      window.location.reload();
    }
    // console.log(JSON.stringify({
    //   userId: userData.id,
    //     firstName: data.firstName,
    //     lastName: data.lastName,
    //     dob: data.dob,
    //     gender: data.genger,
    //     // phone: data.phone,
    //     cityId: data.city_id,
    //     zipCode: data.zipCode,
    //     address: data.address,
    //     lati: 0,
    //     long: 0,
    //     maritalStatus: data.maritalStatus,
    //     // monthlyIncome: data.monthlyIncome,
    //     requestedById: TokenService.getUser()?.user?.id,
    //     description: data.description,
    //   }));
  }

  const handleImageChange = (e, type) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      if (type === 'profile') {
        setProfileImage({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });
      }
      else if (type === 'busi_profile') {
        setBusinessProfile({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });
      }
      if (type === 'busi_logo') {
        setBusinessLogo({
          base64: base64Data,
          name: file.name,
          type: file.type,
        });

      };
    }
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result.split(",")[1];
        setBusinessDoc({
          base64: base64,
          name: file.name,
          type: file.type,
        });
      };
      reader.readAsDataURL(file);
    }
    console.log('file', businessDoc.base64);
  };

  const dateFormat = (d) => {
    if (d) {
      const date = d?.split('T')[0].slice(0, 10).split('-');
      const formattedDate = `${date[0]}-${date[1]}-${date[2]}`;
      // console.log('formattedDate', formattedDate);
      return formattedDate;
    }
  }

  const handleProfileImgUpdate = async () => {
    const res = await PUT(BASE_URL + `api/User/updateUserProfilePic`, {
      userId: userData.id,
      profilePic: profileImage.base64,
      profilePicName: profileImage.name,
      profilePicType: profileImage.type
    });
    if (res.status === 'success') {
      alert('Profile Image Updated Successfully');
      window.location.reload();
    }
  }

  const handleStatusChange = async () => {
   
    if (!userStatus) {
      activateUser('Active')
    }
    else {
      activateUser('InActive')
    }
  }

  const activateUser = async (s) => {

    const res = await PUT(BASE_URL + `api/User/updateUserStatus`, {
      userId: userData.id,
      status: s
    });
    console.log(res);
    if (res.status === 'success') {
      alert('User Status Updated Successfully');
      window.location.reload();
    }
  }

  const handleDayUpdate = async (day) => {
    const res = await PUT(BASE_URL + `api/BusinessHour/updateBusinessHour`, {
      bHourId: day.id,
      isClosed: day.isClosed,
      openingTime: dateTime(day.openingTime),
      closingTime: dateTime(day.closingTime)
    }, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`
      }
    });
    console.log(res);
    if (res.status === 'success') {
      alert('Updated Successfully');
      window.location.reload();
    }
  };

  const dateTime = (timeString) => {
    if (!timeString) {
      return ''; // Return an empty string if timeString or currentDate is undefined
    }
  
    // Parse the time string into hours and minutes
    const [hours, minutes] = timeString.split(':');
  
    // Create a new Date object using the current date
    const date = new Date()
  
    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);
  
    // Create options for formatting the date
    const options = {
      weekday: 'ddd',
      month: 'MMM',
      day: 'dd',
      year: 'yyyy',
      hour: 'HH',
      minute: 'mm',
      second: 'ss',
    };
  
    // Format the date using Intl.DateTimeFormat
    const formatter = new Date(date);
    const formattedTime = formatter.toString().split(' (')[0];
  
    return formatter.toString().split(' (')[0];;
  };

  const handleTimeSync = async () => {
    setTimeModal(false);
    const days = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
    
    for (const day of days) {
      await handleAllDayUpdate(day.id, day.isClosed, syncTime.openingTime, syncTime.closingTime);
    }
  
    alert('Updated Successfully');
    window.location.reload();
  
    const updatedDays = days.map(day => ({
      ...day,
      openingTime: syncTime.openingTime,
      closingTime: syncTime.closingTime
    }));
  
    setMonday(updatedDays[0]);
    setTuesday(updatedDays[1]);
    setWednesday(updatedDays[2]);
    setThursday(updatedDays[3]);
    setFriday(updatedDays[4]);
    setSaturday(updatedDays[5]);
    setSunday(updatedDays[6]);
  };
  

  const handleAllDayUpdate = async (id, isClosed, open_Time, close_Time) => {
    // console.log('id:', id, 'isClosed:', isClosed, 'open_Time:', open_Time, 'close_Time:', close_Time);
      const res = await PUT(BASE_URL + `api/BusinessHour/updateBusinessHour`, {
        bHourId: id,
        isClosed: isClosed,
        openingTime: dateTime(open_Time),
        closingTime: dateTime(close_Time)
      }, {
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`
        }
      });
      console.log(res);
      if (res.status === 'success') {
        console.log(res);
      }
    // return;
  };




  return (
    <div>
      {isLoading ?
        <Loader />
        :
        <div>
          {/* active inactive user toggle */}
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-xl text-primary font-bold pb-5">
              Business Details
            </h3>

            <label className="flex cursor-pointer gap-2 items-center justify-end">
              <p className="text-sm">In Active</p>
              <input
                type="checkbox"
                defaultChecked={userData.status} className="sr-only peer"
                checked={userStatus}
                onChange={() => setModalIsOpen(true)}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
              <p className="text-sm">Active</p>
            </label>
          </div>

          {/* Profile image section */}
          {/* <div className="flex flex-row items-start justify-between w-fit">
            <div className="flex flex-col pb-5" >
              <img
                src={profileImage.base64 !== '' ? profileImage.base64 : (userData.profilePath ? BASE_URL + userData.profilePath : "images/image-placeholder.jpg")}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              {profileImgEdit &&
                <div className="py-3">
                  <input
                    className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                    id="profile_image"
                    type="file"
                    onChange={(event) => handleImageChange(event, 'profile')}
                    disabled={!profileImgEdit}
                  />

                  <div className="flex gap-3 py-3">
                    <button className="text-white bg-primary rounded-lg px-4 py-2"
                      onClick={handleProfileImgUpdate}
                    >
                      Save
                    </button>
                    <button
                      className="text-primary bg-gray-200 rounded-lg px-4 py-2"
                      onClick={() => setProfileImgEdit(!profileImgEdit)}
                    >
                      Cancel
                    </button>
                  </div>

                </div>
              }
            </div>
            <div className="flex flex-col flex-grow items-between">
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileImgEdit(!profileImgEdit)}>
                <EditIcon />
              </button>

            </div>
          </div> */}

            {/* Business User Details */}
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-xl text-gray-700 font-semibold pb-5">
                Service Provider Info
              </h3>
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileEdit(!profileEdit)}>
                <EditIcon />
              </button>
            </div>
          <form
            onSubmit={handleSubmitUser(handleServiceProviderSubmit)}>
              
            <div className="overflow-hidden">

              <div className="flex md:flex-row flex-col gap-10 pb-3">
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="firstName" className="pb-1">First Name</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    defaultValue={userData?.firstName}
                    id="firstName" {...registerUserForm('firstName', { required: true })}
                    disabled={!profileEdit}
                  />
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="lastNamw" className="pb-1">Last Name</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    defaultValue={userData?.lastName}
                    id="lastName" {...registerUserForm('lastName', { required: true })}
                    disabled={!profileEdit}
                  />
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="gender" className="pb-1">Gender</label>
                  <select disabled={!profileEdit} id="gender" {...registerUserForm('gender', { required: true })} className={`py-2 px-2 rounded-md border w-full`}>
                    <option value="">Select a gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="maritalStatus" className="pb-1">Marital Status</label>
                  <select className="py-2 px-2 rounded-md border w-full"
                    id="maritalStatus" {...registerUserForm('maritalStatus', { required: true })}
                    defaultValue={userData?.maritalStatus}
                    disabled={!profileEdit}
                  >
                    <option >Select </option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Student">Student</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="dob" className="pb-1">DOB</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="date"
                    defaultValue={dateFormat(userData?.dob)}
                    id="dob" {...registerUserForm('dob', { required: true })}
                    disabled={!profileEdit}
                  />

                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="email" className="pb-1">Email</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="email"
                    defaultValue={userData?.email}
                    id="email" {...registerUserForm('email', { required: true })}
                    disabled
                  />
                </div>


              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="phone" className="pb-1">Phone</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="phone"
                    defaultValue={userData?.phone}
                    id="phone" {...registerUserForm('phone', { required: true })}
                    disabled={!profileEdit}
                  />
                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label className="pb-1">Created At</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="email"
                    defaultValue={formatDate(userData?.createdAt)}
                    disabled={true}
                  />
                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label htmlFor="city" className="pb-1">City</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    disabled={!profileEdit}
                    // defaultValue={userData?.city ? userData.city.name : ''}
                    id="city" {...registerUserForm('city', { required: true })}
                    onClick={() => handleCityModalOpen('user_city')}
                  />
                </div>

              </div>

              <div className="flex md:flex-row flex-col gap-10 pb-3">

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label className="pb-1">ZIP Code</label>
                  <input
                    className="py-2 px-2 rounded-md border w-full"
                    type="text"
                    defaultValue={userData?.zipCode}
                    id='zipCode' {...registerUserForm('zipCode', { required: true })}
                    disabled={!profileEdit}
                  />
                </div>

                <div className="flex flex-col text-sm text-gray-500 w-full">
                  <label className="pb-1">Address</label>
                  <textarea
                    className="py-2 px-2 rounded-md border w-full h-20"
                    type="address"
                    defaultValue={userData?.address}
                    id='address' {...registerUserForm('address', { required: true })}
                    disabled={!profileEdit}
                  />
                </div>
              </div>
              {profileEdit &&
                <>
                  <div className="flex md:flex-row flex-col gap-10 pb-3">

                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label htmlFor="description" className="pb-1">Reason</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="text"
                        disabled={!profileEdit}
                        id="description" {...registerUserForm('description')}
                      />
                    </div>

                  </div>
                  <div className="flex justify-end gap-3">
                    <button
                      type="submit"
                      className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                      onClick={() => setProfileEdit(!profileEdit)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary text-white py-2 px-4 rounded-lg"
                      onClick={handleSubmitUser(handleServiceProviderSubmit)}
                    >
                      Save
                    </button>
                  </div>
                </>
              }

            </div>
          </form>



          {/* <div className="flex flex-row items-center justify-around py-5">
            <div className="flex flex-col items-center justify-between gap-5">
              <img
                src={businessLogo.base64 !== '' ? businessLogo.base64 : (userData.business?.logoPath ? BASE_URL + userData.business?.logoPath : "images/image-placeholder.jpg")}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              <input
                className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                id="file_input"
                type="file"
                onChange={(event) => handleImageChange(event, 'busi_logo')}
              />
            </div>
            <div className="flex flex-col items-center justify-between gap-5">
              <img
                src={businessProfile.base64 !== '' ? businessProfile.base64 : (userData.business?.bProfileName !== null ? BASE_URL + userData.business?.bProfileName : "images/image-placeholder2.jpg")
                }
                className="w-[600px] h-[280px] object-cover rounded-md"
                atl='business profile image'
              />
              <input
                className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                id="file_input"
                type="file"
                onChange={(event) => handleImageChange(event, 'busi_profile')}
              />
            </div>
          </div> */}
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-xl text-gray-700 font-semibold pb-5">
                Buisness Info
              </h3>
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setBusinessEdit(!businessEdit)}>
                <EditIcon />
              </button>
            </div>
            {/* Business Details */}
            <form
              onSubmit={handleSubmit(onSubmit)}>


              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">

                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Title</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            defaultValue={userData?.business?.title}
                            id='title' {...register('title', { required: true })}
                            disabled
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Phone</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="tel"
                            defaultValue={userData?.business?.phone}
                            disabled
                            id='busi_phone' {...register('busi_phone', { required: true })}
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Email</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="email"
                            defaultValue={userData?.business?.email}
                            disabled
                            id='busi_email' {...register('busi_email', { required: true })}
                          />
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">City</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            // defaultValue={userData?.business?.city?.name}
                            disabled={!businessEdit}
                            id='busi_city' {...register('busi_city', { required: true })}
                            onClick={() => handleCityModalOpen('busi_city')}
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Zip Code</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            defaultValue={userData?.business?.zipCode}
                            disabled={!businessEdit}
                            id='busi_zipCode' {...register('busi_zipCode', { required: true })}
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Address</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            defaultValue={userData?.business?.address}
                            disabled={!businessEdit}
                            id='busi_address' {...register('busi_address', { required: true })}
                          />
                        </div>
                      </div>

                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">License Number</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            defaultValue={userData?.business?.facilitylicense}
                            disabled
                            id='facilitylicense' {...register('facilitylicense', { required: true })}
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Document</label>
                          <button onClick={handlePdfDownload} className="flex flex-row items-center justify-between py-2 px-2 rounded-md border w-full">
                            <span className="overflow-hidden whitespace-nowrap overflow-ellipsis">{userData?.business?.fileName}</span>
                            <DownloadIcon />
                          </button>
                      </div>
                      {/* {businessEdit &&
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Upload Document</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="file"
                            onClick={(e) => handleFileChange(e)}
                          />
                        </div>
                      } */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {businessEdit &&
                <>


              <div className="flex flex-col text-sm text-gray-500 w-full">
                <label htmlFor="description" className="pb-1">Reason</label>
                <input
                  className="py-2 px-2 rounded-md border w-full"
                  type="text"
                  disabled={!businessEdit}
                  id="description" {...register('description')}
                />
              </div>
              <div className="flex justify-end my-4 gap-3">
                    <button
                      type="submit"
                      className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                      onClick={() => setBusinessEdit(!businessEdit)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary text-white py-2 px-4 rounded-lg"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Save
                    </button>
                  </div>
                </>
              }
          
          </form>


            {/* working hours */}
            <div className="bg-gray-100 p-4 rounded-md">
              <div className="flex flex-row items-center justify-between">
                <h3 className="text-primary text-lg font-bold  ">Working days</h3>
                <button className="text-primary hover:bg-white py-2 px-4 rounded-lg" onClick={() => setTimeModal(!timeModal)}>
                  Sync All
                </button>
              </div>
              <div className="flex flex-col gap-5">

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Monday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[0]?.isClosed }
                          className="sr-only peer"
                          checked={!monday.isClosed}
                          onChange={(e) => setMonday({ ...monday, isClosed: !e.target.checked })}
                          disabled={!mondayEdit}

                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border"
                        type="time"
                        defaultValue={monday.openingTime}
                        onChange={(e) => setMonday({ ...monday, openingTime: e.target.value })}
                        disabled={!mondayEdit}
                      />

                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        defaultValue={monday.closingTime}
                        onChange={(e) => setMonday({ ...monday, closingTime: e.target.value })}
                        disabled={!mondayEdit}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('monday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {mondayEdit &&
                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setMondayEdit(!mondayEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(monday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Tuesday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[1]?.isClosed ? "true" : 'false'}
                          checked={!tuesday.isClosed}
                          onChange={(e) => setTuesday({ ...tuesday, isClosed: !e.target.checked })}
                          className="sr-only peer"
                          disabled={!tuesdayEdit}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        defaultValue={tuesday.openingTime}
                        onChange={(e) => setTuesday({ ...tuesday, openingTime: e.target.value })}
                        disabled={!tuesdayEdit}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        defaultValue={tuesday.closingTime}
                        onChange={(e) => setTuesday({ ...tuesday, closingTime: e.target.value })}
                        disabled={!tuesdayEdit}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('tuesday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {tuesdayEdit &&
                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setTuesday(!mondayEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(tuesday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Wednesday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[2]?.isClosed ? "true" : 'false'}
                          checked={!wednesday.isClosed}
                          onChange={(e) => setWednesday({ ...wednesday, isClosed: !e.target.checked })}
                          className="sr-only peer"
                          disabled={!wednesdayEdit}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        disabled={!wednesdayEdit}
                        defaultValue={wednesday.openingTime}
                        onChange={(e) => setWednesday({ ...wednesday, openingTime: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        disabled={!wednesdayEdit}
                        defaultValue={wednesday.closingTime}
                        onChange={(e) => setWednesday({ ...wednesday, closingTime: e.target.value })}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('wednesday')}>
                      <EditIcon />
                    </button>

                  </div>
                  {wednesdayEdit &&
                    <div className="flex justify-end gap-3">
                      <button

                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setWednesdayEdit(!wednesdayEdit)}
                      >
                        Cancel
                      </button>
                      <button

                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(wednesday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Thursday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[3]?.isClosed ? "true" : 'false'}
                          checked={!thursday.isClosed}
                          className="sr-only peer"
                          disabled={!thursdayEdit}
                          onChange={(e) => setThursday({ ...thursday, isClosed: !e.target.checked })}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        disabled={!thursdayEdit}
                        defaultValue={thursday.openingTime}
                        onChange={(e) => setThursday({ ...thursday, openingTime: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        disabled={!thursdayEdit}
                        defaultValue={thursday.closingTime}

                        onChange={(e) => setThursday({ ...thursday, closingTime: e.target.value })}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('thursday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {thursdayEdit &&

                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setThursdayEdit(!thursdayEdit)}
                      >
                        Cancel
                      </button>
                      <button

                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(thursday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Friday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[4]?.isClosed ? "true" : 'false'}
                          checked={!friday.isClosed}
                          onChange={(e) => setFriday({ ...friday, isClosed: !e.target.checked })}
                          className="sr-only peer"
                          disabled={!fridayEdit}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        disabled={!fridayEdit}
                        defaultValue={friday.openingTime}
                        onChange={(e) => setFriday({ ...friday, openingTime: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        disabled={!fridayEdit}
                        defaultValue={friday.closingTime}

                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('friday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {fridayEdit &&

                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setFridayEdit(!fridayEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(friday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Saturday
                        </span>
                        <input
                          type="checkbox"
                          checked={!saturday.isClosed}
                          onChange={(e) => setSaturday({ ...saturday, isClosed: !e.target.checked })}
                          className="sr-only peer"
                          disabled={!saturdayEdit}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        disabled={!saturdayEdit}
                        defaultValue={saturday.openingTime}
                        onChange={(e) => setSaturday({ ...saturday, openingTime: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        disabled={!saturdayEdit}
                        defaultValue={saturday.closingTime}
                        onChange={(e) => setSaturday({ ...saturday, closingTime: e.target.value })}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('saturday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {saturdayEdit &&
                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setSaturdayEdit(!saturdayEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(saturday)}
                      >
                        Save

                      </button>
                    </div>
                  }
                </div>

                <div className="flex flex-col gap-2 ">
                  <div className="flex flex-row gap-5 items-center justify-between">
                    <div className="w-[33.33%]">
                      <label className="flex flex-row justify-between items-center cursor-pointer">
                        <span className="ms-3 text-sm font-medium text-gray-900 ">
                          Sunday
                        </span>
                        <input
                          type="checkbox"
                          // defaultChecked={userData?.business?.businessHours[6]?.isClosed ? "true" : 'false'}
                          checked={!sunday.isClosed}
                          onChange={(e) => setSunday({ ...sunday, isClosed: !e.target.checked })}
                          className="sr-only peer"
                          disabled={!sundayEdit}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div>
                    <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                      <label className="pb-1">Open Time</label>
                      <input
                        className="py-2 px-2 rounded-md border "
                        type="time"
                        disabled={!sundayEdit}
                        defaultValue={sunday.openingTime}
                        onChange={(e) => setSunday({ ...sunday, openingTime: e.target.value })}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                      <label className="pb-1">Close Time</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="time"
                        disabled={!sundayEdit}
                        defaultValue={sunday.closingTime}
                        onChange={(e) => setSunday({ ...sunday, closingTime: e.target.value })}
                      />
                    </div>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setActiveDay('sunday')}>
                      <EditIcon />
                    </button>
                  </div>
                  {sundayEdit &&
                    <div className="flex justify-end gap-3">
                      <button
                        type="submit"
                        className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                        onClick={() => setSundayEdit(!sundayEdit)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-primary text-white py-2 px-4 rounded-lg"
                        onClick={() => handleDayUpdate(sunday)}
                      >
                        Save
                      </button>
                    </div>
                  }
                </div>

              </div>
            </div>

          {/* user Activation Modal */}
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            appElement={document.getElementById('your-app-element-id')}
          >
            <div className="flex flex-col items-center gap-5 p-5">
              <h2 className="text-xl font-semibold text-gray-700">Are you sure you want to {userStatus ? 'deactivate' : 'activate'} the user?</h2>
              {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
            </div>

            <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
              <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
                Cancel
              </button>
              <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                onClick={() => handleStatusChange()}>
                Confirm
              </button>
            </div>
          </Modal>

          {/* City Select Modal */}
          <Modal
            isOpen={cityModal}
            onRequestClose={() => setCityModal(false)}
            style={custom}
            appElement={document.getElementById('your-app-element-id')}
          >
            <div className="flex flex-col items-center gap-5 p-5">
              {city_type === 'busi_city' ? <CitySelect onCitySelect={handleBusiCity} /> :
                <CitySelect onCitySelect={handleUserCity} />}
            </div>
          </Modal>
          
          {/* time sync modal */}
          <Modal
            isOpen={timeModal}
            onRequestClose={() => setTimeModal(false)}
            style={customStyles}
            appElement={document.getElementById('your-app-element-id')}
          >
            <div className="flex flex-col items-center gap-5">
              <div className="flex flex-col text-sm text-gray-500 w-full">
                <label className="pb-1">Open Time</label>
                <input
                  className="py-2 px-2 rounded-md border"
                  type="time"
                  defaultValue={syncTime.openingTime}
                  onChange={(e) => setSyncTime({ ...syncTime, openingTime: e.target.value })}
                />
              </div>
              <div className="flex flex-col text-sm text-gray-500 w-full">
                <label className="pb-1">Close Time</label>
                <input
                  className="py-2 px-2 rounded-md border"
                  type="time"
                  defaultValue={syncTime.closingTime}
                  onChange={(e) => setSyncTime({ ...syncTime, closingTime: e.target.value })}
                />
              </div>
              <div className="flex justify-end gap-3">
                <button
                  type="submit"
                  className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                  onClick={() => setTimeModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary text-white py-2 px-4 rounded-lg"
                  onClick={handleTimeSync}
                >
                  Update
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={mapModal}
            onRequestClose={() => setMapModal(false)}
            style={mapModalStyle}
          >
            <div >
              {renderMap(center)}
            </div>
          </Modal>




        </div>}
    </div>
  );
}
