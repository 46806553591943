import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './userprofile/content'

export default function UserProfile() {
  return (
    <Baselayout>
        <Content />
    </Baselayout>
  )
}
