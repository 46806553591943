import React, { useState, useEffect } from "react";
import { GET, POST, PUT } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import SendIcon from '@mui/icons-material/Send';
import {TokenService} from '../../utils/TokenService.ts';
import { useNavigate } from "react-router-dom";


export default function Content({state}) {
  console.log('state', state);
  let navigate = useNavigate();

  const [tickets, setTickets] = useState([]);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('');
  // const user = TokenService.getUser();


  const handleUpdateComment = async () => {
    // console.log(JSON.stringify({  ticketId: state.id, 
    //   userId: TokenService.getUser().user.id,
    //   description: comment,
    //   status: status  }));
    // //
    const res = await PUT(BASE_URL + "api/Ticket/updateTicket",{
      ticketId: state.id, 
      userId: TokenService.getUser().user.id,
      description: comment,
      status: status  
    });
    if (res.status === 'success') {
      alert('Comment added successfully');
      setComment('');
      window.location.reload();
      // navigate('/tickets')      
    } 
  }

  useEffect(() => {
    if(state){
      getTicketDetails();
    }
  }, [state]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };


  const getTicketDetails = async () => {
    var id = 0;
    if (state.id === undefined) {
      id = state;
    }
    else {
      id = state.id;
    }
    const res = await GET(BASE_URL + "api/Ticket/getTicketDetail?ticketId="+id);
    if (res.status === 'success') {
      setTickets(res.data);
      console.log('tickets', res.data);
    } 
  }

  return (
    <div>
      <div className=" sm:px-6 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden mb-4">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <div className="flex flex-row w-full items-center justify-between">
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      Ticket Details
                    </h2>
                    <button className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-blue-500" >Chat History</button>
                  </div>

                  <div></div>
                </div>
                <div className="px-4 py-4">
                  <div className="flex flex-row gap-4 pb-3 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Name:
                      </h3>
                      <h2 className="text-md font-semibold text-gray-800 ">
                      {/* {state.forUser_FirstName} {state.forUser_LastName} */}
                      {tickets[0]?.ticket.user?.firstName} {tickets[0]?.ticket.user?.lastName}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-3 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Status:
                      </h3>
                      <h2 className="text-md font-semibold text-gray-800 ">
                      {tickets[0]?.ticket.status}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-3 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Created Date
                      </h3>
                      <h2 className="text-md font-semibold text-gray-800 ">
                      {formatDate(tickets[0]?.ticket?.createdAt)}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-3 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Last Update:
                      </h3>
                      <h2 className="text-md font-semibold text-gray-800 ">
                      {formatDate(tickets[tickets.length-1]?.createdAt)}
                    </h2>
                  </div>

                </div>
              </div>

              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden mb-4">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                     Comments
                    </h2>
                  </div>

                  <div></div>
                </div>
                {tickets.map((comment) => (

                <div className="px-4 py-4" key={comment.id}>
                  <div className="flex flex-row gap-4 pb-1 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Name:
                      </h3>
                      <h2 className="text-md font-medium text-gray-800 ">
                      {comment.user?.firstName} {comment.user?.lastName}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-1 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Status:
                      </h3>
                      <h2 className="text-md font-medium text-gray-800 ">
                      {comment.status}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-1 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Posted Date:
                      </h3>
                      <h2 className="text-md font-medium text-gray-800 ">
                      {formatDate(comment.createdAt)}
                    </h2>
                  </div>
                  <div className="flex flex-row gap-4 pb-1 ">
                    <h3 className="text-sm font-regular text-gray-800 w-[200px] ">
                      Comment:
                      </h3>
                      <h2 className="text-md font-medium text-gray-800 ">
                      {comment.description}
                    </h2>
                  </div>
                  
                </div>
                ))}              
              </div>

              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden mb-4 ">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <input type="text" className="w-1/2 border border-gray-200 rounded-lg p-2" placeholder="Add Comment"
                   value={comment} onChange={(event) => setComment(event.target.value)}  />
                  <select className="w-1/4 border border-gray-200 rounded-lg p-2" onChange={(e)=>setStatus(e.target.value)} >
                    <option value="">Select Status</option>
                    <option value="InProgess">InProgess</option>
                    <option value="Closed">Closed</option>
                    <option value="Completed">Completed</option>
                  </select>
                  <div onClick={handleUpdateComment}>
                    <SendIcon className="w-8 h-8 text-gray-400 hover:text-black" />
                  </div>
                  </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


