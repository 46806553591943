import React, {useState, useEffect} from "react";
import { BASE_URL } from "../../utils/config";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader.jsx";

export default function Content({state}) {
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [appointments, setAppointments] = React.useState([]);
  const [reviews, setReviews] = React.useState([]); 
  const [topValue, setTopValue] = useState(10);
  const [withinValue, setWithinValue] = useState(20);

  const formatDate = (dateString) => {
    if (!dateString) return ''; // Return empty string if dateString is falsy
    const date = new Date(dateString); // Parse the dateString into a Date object 
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Format the date as "Feb 24, 2020"
  };

  const formatTime = (timeString) => {
    console.log(timeString.split('T')[1]);
    let time = timeString.split('T')[1];
    return time;

  };

  React.useEffect(() => {
    if (state) {
      handleGetData();
    }
  }, [state]);

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL +`api/User/getUserInfoById?user=${state}`);
    if (res.status === 'success') {
      
      setUserData(res.data);
      handleGetAppointments();
      console.log(res.data);
      setIsLoading(false);
    }
  }

  const handleGetAppointments = async () => {
    const res = await GET(BASE_URL + `api/Appointment/getOnlyAppointmentListByCreatorId?userId=${state}`);
    if (res.status === 'success') {
        const currentDate = new Date(); // Current date
        const thirtyDaysAgo = new Date(); // Date 30 days ago
        thirtyDaysAgo.setDate(currentDate.getDate() - 30); // Set date 30 days ago

        const filteredAppointments = res.data.filter((appointment) => {
            const appointmentDate = new Date(appointment.appointmentDate); // Assuming appointmentDate is the date field of each appointment
            return appointmentDate >= thirtyDaysAgo; // Filter appointments within the last 30 days
        });

        setAppointments(filteredAppointments);
    } else {
        // Handle error
        console.log('Error fetching appointments:', res);
    }
};

const handleReviews = async () => { 
     try {
    const res = await GET(BASE_URL+`api/Reports/getReviews?customerId=${state}&top=${topValue}&withIn=${withinValue}`);
    if (res.status === 'success') {
        console.log(res.data);
        setReviews(res.data);
    } else {
        console.error('Error fetching offer data:', res);
    }
} catch (error) {
    console.error('Error fetching offer data:', error);
}
};

useEffect(() => {
    handleReviews();
}, [topValue, withinValue]);
const handleTopChange = (e) => {
    setTopValue(parseInt(e.target.value));
};

const handleWithinChange = (e) => {
    setWithinValue(parseInt(e.target.value));
};


  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl text-primary font-bold pb-5">
          Customer Details
        </h3>
        <label className="flex cursor-pointer gap-2 items-center justify-end">
          <p className="text-sm">In Active</p>
          <input type="checkbox" defaultChecked={userData.status} className="sr-only peer" />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
          <p className="text-sm">Active</p>
        </label>
      </div>
      {isLoading ? 
       <Loader />
      :
      <>
      <div className="pb-5">
        <img
          src={userData?.profilePath === null || userData?.profilePath === "" ? "images/image-placeholder.jpg" : BASE_URL + userData?.profilePath}
          className="w-[150px] h-[150px] object-cover rounded-full"
        />

        {/* <div className="py-3">
          <input
            className="block w-full text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
            id="file_input"
            type="file"
            disabled={true}
          />
        </div> */}
      </div>
      <div>
      {/* Person  nal Info */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
          <h3 className="text-xl text-gray-700 font-semibold pb-5">
          Personnal Info
        </h3>
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">

                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">First Name</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      // placeholder="Jhango"
                      defaultValue={userData?.firstName}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Last Name</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      // placeholder="Jhango"
                      defaultValue={userData?.lastName}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Gender</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.gender}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Marital Status</label>
                    <select className="py-2 px-2 rounded-md border w-full"
                    defaultValue={userData?.maritalStatus} 
                    disabled={true}>
                      <option>Single</option>
                      <option>Married</option>
                      <option>Divorced</option>
                      <option>Student</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">DOB</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="date"
                      // placeholder="Jhango"
                      defaultValue={userData?.dob ? new Date(userData?.dob).toISOString().split('T')[0] : ''}
                      disabled={true}
                    />

                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Monthly Income</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.monthlyIncome}
                      disabled={true}
                    />
                  </div>
                 
                </div>

                <div className="flex md:flex-row flex-col gap-10 pb-3">

                <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Email</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.email}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Phone</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.phone}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Created At</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={formatDate(userData?.createdAt)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-10 pb-3">

                <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">City</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.city?.name}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">ZIP Code</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.zipCode}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Address</label>
                    {/* <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={formatDate(userData?.createdAt)}
                      disabled={true}
                    /> */}
                    <textarea
                      className="py-2 px-2 rounded-md border w-full h-20"
                      type="address"
                      // placeholder="Jhango"
                      defaultValue={userData?.address}
                      disabled={true}
                      />
                  </div>
                </div>

              </div>
            </div>
          </div>
            </div>



            {/* Appointments Data */}
            {appointments && appointments.length > 0 &&
              <div>
                <h3 className="text-xl text-gray-700 font-semibold py-5">

                  Appointments
                </h3>
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">

                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Description
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Service
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Assignee/ Cancellee
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Business
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {appointments.map((appointment, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">

                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    {appointment.description}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                {appointment.status}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              <span className="text-sm text-gray-500">
                                {appointment.serviceType?.name}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {
                                appointment.assignee ?
                                  <Link to='/customerinfodetails' state={appointment.assigneeId}>
                                    <span className="text-sm text-gray-500 hover:underline hover:text-gray-800">
                                      {appointment.assignee?.firstName + ' ' + appointment.assignee?.lastName}
                                    </span>
                                  </Link>
                                  :
                                  appointment.cancellee ?
                                    <Link to='/customerinfodetails' state={appointment.cancelleeId}>
                                      <span className="text-sm text-gray-500">
                                        {appointment.cancellee?.firstName + ' ' + appointment.cancellee?.lastName}
                                      </span>
                                    </Link>
                                    :
                                    <span className="text-sm text-gray-500">
                                      Not assigned yet
                                    </span>
                              }
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {appointment.business?.title}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(appointment.appointmentDate)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">

                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <Link to={'/appointmentdetails'} state={appointment.id}>
                                  <button className="text-sm text-white bg-primary p-2 rounded-xl">
                                    View
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>

                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{appointments?.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>
            }
<div>
                <div className="flex flex-row items-center justify-between mt-5"> 
                <h3 className="text-xl text-gray-700 font-semibold py-5">

                  Reviews
                </h3>
                <div className="flex justify-end mb-4 gap-3">
                <div className="flex items-center">
                    <label className="mr-2">Top:</label>
                    <select
                        className="border rounded p-2"
                        value={topValue}
                        onChange={handleTopChange}
                    >
                        {[5,10, 20, 30, 40, 50].map((value) => (
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </select>
                </div>
                <div className="flex items-center">
                    <label className="mr-2">Within:</label>
                    <select
                        className="border rounded p-2"
                        value={withinValue}
                        onChange={handleWithinChange}
                    >
                        {[5,10, 20, 30, 40, 50].map((value) => (
                            <option key={value} value={value}>{value}</option>
                        ))}
                    </select>
                </div>
            </div>
            </div>
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">

                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                       

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Comment
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Rating
                            </span>
                          </div>
                        </th>
                        
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {reviews.map((item, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">

                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    {item.comment}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                {item.rating}
                              </span>
                            </div>
                          </td>

                          
                        </tr>

                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{reviews?.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>

          </div>
        </>
      }

    </div>
  );
}
  