import axios from "axios";
import Cookies from "js-cookie";
import { BASE_URL } from "./config.jsx";

export class TokenService {
    private static token: string | null = null;
    private static refreshToken: string | null = null;
    private static user: any = null;
    private static role: any = null;
    private static email: any = null;
    private static password: any = null;
    private static timer: any = null;

    static setTimer(timer: any) {
        TokenService.timer = timer;
        sessionStorage.setItem('timer', timer);
    }

    static getTimer() {
        return TokenService.timer ?? 
            sessionStorage.getItem('timer') ?? "";
    }

    static getPassword(): string {
        return TokenService.password ??
            localStorage.getItem('password') ?? "";
    }

    static setPassword(password: string): void {
        TokenService.password = password
        localStorage.setItem('password', password);
    }

    static getEmail(): string {
        return TokenService.email ??
            localStorage.getItem('email') ?? "";
    }

    static setEmail(email: string): void {
        TokenService.email = email;
        localStorage.setItem('email',email);
    }

    static getAccessToken(): string {
        return TokenService.token ??
            sessionStorage.getItem('accessToken') ?? "";
    }

    static setAccessToken(token: string): void {
        TokenService.token = token;
        sessionStorage.setItem('accessToken',token);
    }

    static getRefreshToken(): string {
        return TokenService.refreshToken ??
            sessionStorage.getItem('refreshToken') ?? "";
    }

    static setRefreshToken(token: string): void {
        TokenService.refreshToken = token;
        sessionStorage.setItem('refreshToken', token);
    }

    static async refreshAccessToken() {
        try {
            const res = await axios.post(
                `api/User/refresh-token`,
                {
                    refreshToken: this.getRefreshToken()
                },
                {
                    baseURL: BASE_URL,
                    headers: {
                        refreshToken: this.getRefreshToken()
                    },
                    validateStatus: (status: any) => {
                        return status >= 200
                    }
                }
            );

            if (res.status === 200) {
                this.setAccessToken(res.data.data.access_token.token);
                this.setRefreshToken(res.data.data.refreshToken.token);

                // Setting refreshToken in Cookies
                let user = JSON.parse(sessionStorage.getItem("user") ?? "{}");
                user.refreshToken = res.data.data.refreshToken;
                user.accessToken = res.data.data.access_token;
                this.setMasterState(user);
                return this.getAccessToken();
            } else {
                sessionStorage.removeItem("user");
                return "";
            }
        } catch (err) {
            console.log(err);
            sessionStorage.removeItem("user");
            return "";
        }
    }

    static setMasterState(data: any) {
        if (sessionStorage.getItem("user")) {
            sessionStorage.setItem("user", JSON.stringify(data));
        }
    }

    static clearToken(): void {
        TokenService.token = null;
        TokenService.refreshToken = null;
    }


    static getUser(): any {
        return TokenService.user ??
            JSON.parse(sessionStorage.getItem('user') ?? "{}");
    }

    static setUser(user: any): void {
        TokenService.user = user;
        sessionStorage.setItem('user', JSON.stringify(user));
    }


    static clearUser(): void {
        TokenService.user = null;
        sessionStorage.removeItem('user');
    }

    static getUserRole(): any {
        return TokenService.role ??
            JSON.parse(sessionStorage.getItem('userrole') ?? "{}");
    }

    static setUserRole(role: any): void {
        TokenService.role = role;
        sessionStorage.setItem('userrole', role);
    }


    static clearUserRole(): void {
        TokenService.role = null;
        sessionStorage.removeItem('userrole');
    }
    
}

