import React, { useState, useEffect } from "react";
import { GET, PUT, POST } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Dropdown from "../../components/dropdown.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import Loader from "../../components/Loader.jsx";
import { AllUserDropdown } from "../../components/drodown.jsx";
import { set } from "react-hook-form";

export default function Content() {
  const [loading , setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [assignee, setAssignee] = useState(''); 
  const [ticketId, setTicketId] = useState('');
  const [ticketModal, setTicketModal] = useState(false);
  const [description, setDescription] = useState('');
  const [userId, setUserId] = useState('');

  const handleGetTickets = async () => {
    setLoading(true);
    const res = await GET(BASE_URL + "api/Ticket/getTicketList",{
        Authorization: `Bearer ${sessionStorage.getItem('token')}`
    });
    if (res.status === 'success') {
      setTickets(res.data);
      setLoading(false);
    }
    else{
      
      // alert(res.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    handleGetTickets();
    if (TokenService.getUserRole() === 4) {
      setUserId(TokenService.getUser().user.id);
    }
  }, []);

  const custom = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  console.log('tickets', userId)


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  Modal.setAppElement(document.getElementById('your-app-element-id'))

  const handleOpenModal = async (ticketId) => {
    console.log('ticketId', ticketId);
    setTicketId(ticketId);
    setModalIsOpen(true);
  }

  const handleAssignTicket = async () => {
    setLoading(true);
    const res = await PUT(BASE_URL + `api/Ticket/assignTicket`, {
      Authorization: `Bearer ${TokenService.getAccessToken()}`,
      ticketId: ticketId,
      assignToId: assignee
    });

    if (res.status === 'success') {
      setLoading(false);
      alert('Ticket assigned successfully');
      setModalIsOpen(false);
      handleGetTickets();
    }
    else{
      alert(res.message);
      setLoading(false);
      setModalIsOpen(false);
      handleGetTickets();
    }
  }

  const handleAssigneeSelect = (id) => {
    console.log('Assignee id', id);
    setAssignee(id);
  }

  const handleSubmitTicket = async (event) => {
  
    if (description === '') {
      alert('Please enter a description');
      return;
    }
    else if ( userId === '') {
      alert('Please select a user');
      return;
    }
    else {
        event.preventDefault();
        const res = await POST(BASE_URL + "api/Ticket/createTicket", {
          description: description,
          userId: userId,
          status: "Open",
        });
        if (res.status === "success") {
          console.log("Ticket created successfully");
          alert("Ticket created successfully");
          setModalIsOpen(false);
          window.location.reload();

        }
        else {
          alert(res.message);
          // alert("Error creating ticket");
          setModalIsOpen(false);
        }
      }
    }
  

  return (
    <div>
      {
        loading ?
          <Loader />
          : 
      <div className=" sm:px-6 mx-auto">
          <div className="flex flex-row items-center justify-between py-6">
            <h1 className="text-2xl font-semibold text-gray-800">Tickets</h1>
            {/* <button className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer" onClick={(e)=>setTicketModal(!ticketModal)}>
              Create Ticket
            </button> */}
            </div>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      All Tickets
                    </h2>
                  </div>

                  <div></div>
                </div>

                <table className="min-w-full divide-y divide-gray-200 ">

                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Created BY
                          </span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Created For
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Status
                          </span>
                        </div>
                      </th>




                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Created
                          </span>
                        </div>
                      </th>

                     
                      {TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6 || TokenService.getUserRole() === 7 &&
                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Assign To
                          </span>
                        </div>
                      </th>
                      }

                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            View
                          </span>
                        </div>
                      </th>

                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {tickets.map((ticket) => (
                      console.log('ticket', ticket),
                      <tr key={ticket.id}>
                      <td className="size-px whitespace-nowrap">
                        <div className="ps-6 lg:ps-3  px-6 py-3">
                          <div className="flex items-center gap-x-3">

                            <div className="grow">
                              <span className="block text-sm font-semibold text-gray-800 ">
                                {ticket.createdBy_FirstName} {ticket.createdBy_LastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="ps-6 lg:ps-3  px-6 py-3">
                          <div className="flex items-center gap-x-3">

                            <div className="grow">
                              <span className="block text-sm font-semibold text-gray-800 ">
                                {ticket.forUser_FirstName} {ticket.forUser_LastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                           
                            {ticket.status}
                          </span>
                        </div>
                      </td>


                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500">
                            {formatDate(ticket.createdAt)}
                          </span>
                        </div>
                      </td>
                          {TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6 || TokenService.getUserRole() === 7 &&
                            (ticket.assigneeId === null ?
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3 flex flex-row gap-2">
                                  <button className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer"
                                    onClick={() => handleOpenModal(ticket.id)}
                                  >
                                    Assign
                                  </button>
                                </div>
                              </td> :
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {ticket.assignee_FirstName} {ticket.assignee_LastName}
                                  </span>
                                </div>
                              </td>
                            )
                          }

                      

                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3 flex flex-row gap-2">
                          <Link state={ticket} to={"/ticketdetails"}>
                            <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                              View Details
                            </span>
                          </Link>
                        </div>
                      </td>

                    

                    </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">{tickets.length}</span>{" "}
                      results
                    </p>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={custom}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 ">
            <h1 className="text-2xl font-bold">Assign Ticket</h1>
         
            <Dropdown url={ "api/User/GetAllSupportUser"} onBusinessSelect={handleAssigneeSelect} />
          
            <button onClick={handleAssignTicket} className="bg-primary text-white rounded-lg p-2">Submit</button>
         
        </div>
      </Modal>

      <Modal
        isOpen={ticketModal}
        onRequestClose={() => setTicketModal(false)}
        style={custom}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 ">
            <h1 className="text-2xl font-bold">Create Ticket</h1>
          <form className="flex flex-col gap-2">
            { TokenService.getUserRole() === 1 &&
            <AllUserDropdown onSelect={setUserId} />
}
            <label className="text-sm text-gray-600">Description</label>
            <textarea
              className=" h-32 rounded-lg  border border-gray-300 w-96 p-2 focus:outline-none focus:border-primary"
              placeholder="Enter your message here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <button onClick={handleSubmitTicket} className="bg-primary text-white rounded-lg p-2">Submit</button>
          </form>
        </div>
      </Modal>
      
    </div>
  );
}


