import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./products/content";

export default function Products() {
  return (
    <>
      <Baselayout>
        <Content />
      </Baselayout>
    </>
  );
}
