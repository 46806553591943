import React, { useState } from "react";
import { BASE_URL } from "../../utils/config";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader.jsx";
import { TokenService } from "../../utils/TokenService.ts";

export default function Content() {
    const state = TokenService.getUser().user.id;
    const [userData, setUserData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [appointments, setAppointments] = React.useState([]);
    const [v_data, setV_data] = useState([]);

    const ResultsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    let startIndex = (currentPage - 1) * ResultsPerPage;
    let endIndex = startIndex + ResultsPerPage;


    const formatDate = (dateString) => {
        if (!dateString) return ''; // Return empty string if dateString is falsy
        const date = new Date(dateString); // Parse the dateString into a Date object 
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('en-US', options); // Format the date as "Feb 24, 2020"
    };

    const formatTime = (timeString) => {
        console.log(timeString.split('T')[1]);
        let time = timeString.split('T')[1];
        return time;

    };

    React.useEffect(() => {
        if (state) {
            handleGetData();
            handleGetVehicle();
        }
    }, [state]);

    const handleGetData = async () => {
        setIsLoading(true);
        const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${state}`);
        if (res.status === 'success') {

            setUserData(res.data);
            handleGetAppointments();
            console.log(res.data);
            setIsLoading(false);
        }
    }

    const handleGetAppointments = async () => {
        const res = await GET(BASE_URL + `api/Appointment/getOnlyAppointmentListByCreatorId?userId=${state}`);
        if (res.status === 'success') {
            setAppointments(res.data);
        }
    }

    const handleGetVehicle = async () => {
        const res = await GET(BASE_URL + `api/User/getVehicleHistoryByUserId?userId=${state}`);
        if (res.status === 'success') {
            setV_data(res.data);
        }
    }


    return (
        <div>
            <div className="flex flex-row items-center justify-between">
                <h3 className="text-xl text-primary font-bold pb-5">
                    User Info
                </h3>
              <div></div>
            </div>
            {isLoading ?
                <Loader />
                :
                <>

                    <div>
                        {/* Personnal Info */}
                        <div className="flex flex-col">
                            <div className="-m-1.5 overflow-x-auto">
                               
                                <div className="p-1.5 min-w-full inline-block align-middle">
                                    <div className="overflow-hidden">

                                        <div className="flex md:flex-row flex-col gap-10 pb-3">
                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">First Name</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="text"
                                                    // placeholder="Jhango"
                                                    defaultValue={userData?.firstName}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">Last Name</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="text"
                                                    // placeholder="Jhango"
                                                    defaultValue={userData?.lastName}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">Gender</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="email"
                                                    // placeholder="Jhango"
                                                    defaultValue={userData?.gender}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Marital Status</label>
                    <select className="py-2 px-2 rounded-md border w-full"
                    defaultValue={userData?.maritalStatus} 
                    disabled={true}>
                      <option>Single</option>
                      <option>Married</option>
                      <option>Divorced</option>
                      <option>Student</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">DOB</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="date"
                      // placeholder="Jhango"
                      defaultValue={userData?.dob ? new Date(userData?.dob).toISOString().split('T')[0] : ''}
                      disabled={true}
                    />

                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Monthly Income</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.monthlyIncome}
                      disabled={true}
                    />
                  </div>
                 
                </div> */}

                                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">Email</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="email"
                                                    // placeholder="Jhango"
                                                    defaultValue={userData?.email}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">Phone</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="email"
                                                    // placeholder="Jhango"
                                                    defaultValue={userData?.phone}
                                                    disabled={true}
                                                />
                                            </div>

                                            <div className="flex flex-col text-sm text-gray-500 w-full">
                                                <label className="pb-1">Created At</label>
                                                <input
                                                    className="py-2 px-2 rounded-md border w-full"
                                                    type="email"
                                                    // placeholder="Jhango"
                                                    defaultValue={formatDate(userData?.createdAt)}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-end gap-3">
                            <button className="bg-primary text-white py-2 px-4 rounded-md" onClick={() => window.history.back()}>Back</button>
                            <Link to={'/passwordchange'} >
                                <button className="bg-primary text-white py-2 px-4 rounded-md" >Password Change</button>
                            </Link>
                            </div>


                    </div>
                </>
            }

        </div>
    );
}
