import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

export default function Content({state}) {
  console.log('State: ',state);

  return (
    <div>
      {/* <div>
        <label className="flex cursor-pointer gap-2 items-center justify-end">
          <p className="text-sm">In Active</p>
          <input type="checkbox" 
          // value="" 
          className="sr-only peer"
           />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
          <p className="text-sm">Active</p>
        </label>
      </div> */}

      <div>
        <h3 className="text-xl text-primary font-bold pb-5">
          Product Info
        </h3>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Name</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      placeholder="Jhango"
                      value={state?.name}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Base Price</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      placeholder="Jhango"
                      value={state?.basePrice}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Discounted Price</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      placeholder="Jhango"
                      value={state?.discountedPrice}
                      disabled='true'
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Total Price</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      placeholder="Jhango"
                      value={state?.totalPrice}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Discount %</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      placeholder="Jhango"
                      value={state?.discount}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Standard Discount %</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      placeholder="Jhango"
                      value={state?.standardDiscount}
                      disabled='true'
                    />
                  </div>
                  
                </div>

                <div className="flex md:flex-row w-1/3 flex-col gap-10 pb-3">
                  <div className="flex flex-col  text-sm text-gray-500 w-full">
                    <label className="pb-1">Description</label>
                    <textarea value={state.description}
                     name="" id="" rows="3" className="py-2 px-2 rounded-md border"
                     disabled></textarea>
                  </div>
                 
                  
                </div>
              
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <button className="bg-primary text-white py-2 px-4 rounded-md">
            Edit
          </button>
          <button className="bg-gray-200 text-primary py-2 px-4 rounded-md" disabled>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
