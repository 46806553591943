import React from 'react'
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import DescriptionIcon from '@mui/icons-material/Description';

export default function content() {
  return (
    <div>
    <div className=" sm:px-6 mx-auto">
      <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block align-middle">  
            <div className="flex flex-row items-center gap-3 py-2">
              <Link to="/serviceprovideraggreement" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                <div className="flex flex-row ">

                  <DescriptionIcon className="w-8 h-8 text-gray-400" />
                  <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Service Provider </h3>
                </div>
              </Link>
              <Link to="/customeraggreement" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                <div className="flex flex-row ">
                  <DescriptionIcon className="w-8 h-8 text-gray-400" />
                  <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Customer</h3>
                </div>
              </Link>
              

            </div>
            
          </div>
        </div>
    </div>
  </div>
  </div >
  )
}
