import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET } from '../../utils/ApiRequestProvider.tsx'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/config.jsx";

const ResultsPerPage = 10;

export default function Content(state) {
  const [ history, setHistory ] = React.useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [business, setBusiness] = useState([]);
  const [appointmentCount, setAppointmentCount] = useState([]);
  

  // Function to calculate startIndex and endIndex
  const startIndex = (currentPage - 1) * ResultsPerPage;
  const endIndex = currentPage * ResultsPerPage;

  // Function to handle pagination
  const changePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if(state?.state?.type === 'business'){
      handleGetBusinessHistory(state?.state?.id)
    }
    else if(state?.state?.Type === 'customer'){
      handleGetUserHistory(state?.state?.id)
    }
  }, [])

  const handleGetUserHistory = async (id) => {
    console.log(id)
    const res = await GET(`${BASE_URL}api/Subscription/getSubscriptionHistoryByUserId?userId=${id}`)  
    if(res.status === 'success')
      setHistory(res.data)
  }

  const handleGetBusinessHistory = async (id) => {
    const res = await GET(`${BASE_URL}api/Subscription/getSubscriptionHistoryByBusinessId?businessId=${id}`)
    if(res.status === 'success'){
      setBusiness(res.data);
      handleGetAppointmentCount(id)
    }

  }

  const handleGetAppointmentCount = async (id) => {
    const res = await GET(`${BASE_URL}api/Subscription/GetAppointmentCountList?businessId=${id}`)
    if(res.status === 'success')
      setAppointmentCount(res.data)
  }





  const subcriptionStatus = (status) => {
    if (status === 1) {
      return "Active";
    } else if (status === 2) {
      return "Inactive";
    } else if (status === 3) {
      return "Expired";
    } else {
      return "Cancelled";
    }
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className=" sm:px-6 mx-auto">

      {/* Subscription History */}
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
              <div>
                <h2 className="text-xl font-semibold text-gray-800 ">
                  Subscription History
                </h2>
              </div>

            </div>
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50 ">
                <tr>
                  <th
                    scope="col"
                    className="ps-6 lg:ps-3 px-6 py-3 text-center"
                  >
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Business Title
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Subscription Type
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Description
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                       Start Date
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        End Date
                      </span>
                    </div>
                  </th>
                  

                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Status
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Actions
                      </span>
                    </div>
                  </th>

                </tr>
              </thead>
              
              <tbody className="divide-y divide-gray-200 ">
              {business.slice(startIndex, endIndex).map((pro, index) => (
                <tr key={index}>
                  <td className="size-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3  px-6 py-3">
                      <div className="flex items-center gap-x-3">

                        <div className="grow">
                          <Link to="/subscriptionhistory" >
                          <span className="block text-sm font-semibold text-gray-800 ">
                          {pro.business?.title}
                          </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </td>
             
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {pro.product?.name}
                      </span>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3 flex flex-row gap-3">
                      <span className="text-sm text-gray-500">
                        {pro.product?.description}
                      </span>
                    </div>
                  </td>
                 

                 
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {formatDate(pro.startDate)}
                      </span>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {formatDate(pro.endDate)}
                      </span>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      { pro.status !== 1  ? (
                          <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                            <CancelIcon className="text-red-500" fontSize="12" />
                            {subcriptionStatus(pro.status)}
                          </span>
                        ) : (
                          <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                            <CheckCircleIcon className="text-teal-500" fontSize="12" />
                            {subcriptionStatus(pro.status)}
                          </span>
                        )}
                      </span>
                    </div>
                  </td>
                  
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-1.5 flex gap-3">
                      <Link state={pro} to="/edit-product">
                      <a
                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                        href="#"
                       
                      >
                        <EditIcon className="text-primary hover:text-black" />
                      </a>
                      </Link>
                      {/* <a
                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                        href="#"
                       
                      >
                        <DeleteIcon className="text-primary hover:text-black" />
                      </a> */}
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
              <div>
                <p className="text-sm text-gray-600 ">
                  <span className="font-semibold text-gray-800 ">{business.length}</span>{" "}
                  results
                </p>
              </div>

              {business.length>= 10 &&
              <div className="flex gap-3 items-center">
                <span className="text-sm text-gray-600 px-2 underline">
                  Page {currentPage} of {Math.ceil(business.length / ResultsPerPage)} 
                </span>
                <button
                  disabled={currentPage === 1}
                  onClick={() => changePage(currentPage - 1)}
                  className="bg-gray-200 rounded-lg text-primary hover:underline hover:shadow px-4 py-2 "
                >
                  Previous
                </button>
                <button
                  disabled={endIndex >= business.length}
                  onClick={() => changePage(currentPage + 1)}
                  className="bg-gray-200 rounded-lg text-primary hover:underline hover:shadow px-4 py-2 mr-2"
                  >
                    Next
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Appointment Count */}
    <div className="flex flex-col my-12">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
              <div>
                <h2 className="text-xl font-semibold text-gray-800 ">
                  Appointment Count
                </h2>
              </div>

            </div>
            <table className="min-w-full divide-y divide-gray-200 ">
              <thead className="bg-gray-50 ">
                <tr>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Subscription Type
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Remaining Count
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                       Total Count
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                       Add on
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Renew Date
                      </span>
                    </div>
                  </th>

                  {/* <th scope="col" className="px-6 py-3 text-center">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                        Actions
                      </span>
                    </div>
                  </th> */}

                </tr>
              </thead>
              
              <tbody className="divide-y divide-gray-200 ">
              {appointmentCount.slice(startIndex, endIndex).map((pro, index) => (
                <tr key={index}>
             
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {pro.serviceType?.name}
                      </span>
                    </div>
                  </td>
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3 flex flex-row gap-3">
                      <span className="text-sm text-gray-500">
                        {pro.count}
                      </span>
                    </div>
                  </td>

                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {pro.totalCount}
                      </span>
                    </div>
                  </td>
                 
                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {pro.addOns}
                      </span>
                    </div>
                  </td>

                  <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                      {formatDate(pro.renewDate)}
                      </span>
                    </div>
                  </td>
                  
                  {/* <td className="size-px whitespace-nowrap">
                    <div className="px-6 py-1.5 flex gap-3">
                      <Link state={pro} to="/edit-product">
                      <a
                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                        href="#"
                       
                      >
                        <EditIcon className="text-primary hover:text-black" />
                      </a>
                      </Link>
                      <a
                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                        href="#"
                       
                      >
                        <DeleteIcon className="text-primary hover:text-black" />
                      </a>
                    </div>
                  </td> */}
                </tr>
              ))}
              </tbody>
            </table>
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
              <div>
                <p className="text-sm text-gray-600 ">
                  <span className="font-semibold text-gray-800 ">{appointmentCount.length}</span>{" "}
                  results
                </p>
              </div>

              {business.length>= 10 &&
              <div className="flex gap-3 items-center">
                <span className="text-sm text-gray-600 px-2 underline">
                  Page {currentPage} of {Math.ceil(appointmentCount.length / ResultsPerPage)} 
                </span>
                <button
                  disabled={currentPage === 1}
                  onClick={() => changePage(currentPage - 1)}
                  className="bg-gray-200 rounded-lg text-primary hover:underline hover:shadow px-4 py-2 "
                >
                  Previous
                </button>
                <button
                  disabled={endIndex >= appointmentCount.length}
                  onClick={() => changePage(currentPage + 1)}
                  className="bg-gray-200 rounded-lg text-primary hover:underline hover:shadow px-4 py-2 mr-2"
                  >
                    Next
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  )
}
