import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './frequentuserdetail/content'
import { useLocation } from 'react-router-dom'

export default function FrequentUserDetail() {
    const { state } = useLocation();
  return (
    <Baselayout>
       <Content state={state} />
    </Baselayout>
  )
}
