import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./serviceproviderdetailsview/Content";
import { useParams, useLocation } from "react-router-dom";
import { TokenService } from "../utils/TokenService.ts";

const ServiceProviderDetails = () => {
  const { state } = useLocation();

  return (
    // TokenService.getUserRole() === 4 ? (
    //   <Baselayout>
    //     <Content state={TokenService.getUser().user.id} />
    //   </Baselayout>
    // ) : (
      <Baselayout>
        <Content state={state} />
      </Baselayout>
    // )
  );
}

export default ServiceProviderDetails;
