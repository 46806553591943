import React from 'react'
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ChatIcon from '@mui/icons-material/Chat';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MailIcon from '@mui/icons-material/Mail';
import {TokenService} from '../../utils/TokenService.ts';

export default function Content() {
  return (
    <div>
      <div className=" sm:px-6 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">  


              <div className="flex flex-row items-center gap-3 py-2">
                {
                  (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7 ) && (
                    <Link to="/sendnotification" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                      <div className="flex flex-row ">
                        <NotificationsIcon className="w-8 h-8 text-gray-400" />
                        <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Send Notification</h3>
                      </div>
                    </Link>
                  )
                }
                  {
                    (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6) &&(
                      <Link to="/sendEmail" className="flex flex-row border w-1/2 border-gray-200 hover:shadow p-4  sm:rounded-lg">
                        <div className="flex flex-row ">
                          <MailIcon className="w-8 h-8 text-gray-400" />
                          <h3 className="pl-4 text-lg font-medium leading-6 text-gray-900">Send Email</h3>
                        </div>
                      </Link>
                    )
                  }
               
               
              </div>
            </div>
          </div>
      </div>
    </div>
    </div >
  )
}
