import React, { useEffect } from 'react'
import { GET } from '../../utils/ApiRequestProvider.tsx'
import { BASE_URL } from '../../utils/config'
import { set, useForm } from 'react-hook-form'
import {PUT, POST} from '../../utils/ApiRequestProvider.tsx'
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'react-modal'
import {TokenService} from '../../utils/TokenService.ts'
import Loader from '../../components/Loader.jsx'

export default function Content(state) {
  const [offer, setOffer] = React.useState([])
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const { register, handleSubmit, watch, formState: { errors } } = useForm()
  const [isLoading, setIsLoading] = React.useState(false)
  const [offerEdit, setOfferEdit] = React.useState(false)
  const [offerImage, setOfferImage] = React.useState({
    base64: '',
    name: '',
    type: ''
  });
  const [ImgEdit, setImgEdit] = React.useState(false);
  const [validTill, setValidTill] = React.useState('');
  const [loading, setLoading] = React.useState(false)
  const [reviews, setReviews] = React.useState([]);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };

  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID


  useEffect(() => {
    const handleGetoffer =async (id) => {
      setLoading(true)
      const response = await GET(`${BASE_URL}api/Offer/GetOfferById?offerId=${id}`)
      if (response.status === 'success') {
        console.log('response=', response.data)
        setOffer(response.data)
        setLoading(false)
        handleGetReviews()
      }
      else{
        alert(response.message)
        setLoading(false)
      }
        // handleGetofferApp(response.data.id)
    }

    handleGetoffer(state.state)
  }, [state])

  // const handleGetofferApp =async (id) => {
  //   const response = await GET(`${BASE_URL}api/Appointment/getOnlyAppointmentListByOfferId?offerId=${id}`)
  //   if(response.status === 'success')
  //     console.log('response=',response.data)
  // }

  const handleOfferImgUpdate = async () => {
    setLoading(true)
    const res = await PUT(BASE_URL + `api/User/updateUserProfilePic`, {
      userId: offer.offerId,
      profilePic: offerImage.base64,
      profilePicName: offerImage.name,
      profilePicType: offerImage.type
    });
    if (res.status === 'success') {
      alert('Profile Image Updated Successfully');
      setLoading(false)
      window.location.reload();
    }
    else{
      alert(res.message)
      setLoading(false)
    }
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setOfferImage({
        base64: base64Data,
        name: file.name,
        type: file.type,
      });
    }
    reader.readAsDataURL(file);
  };

  const DatetoUTC = (date) => {
    const dateObj = new Date(date);
  
    return dateObj.toString().split(' (')[0];
  }

  const handleStatusChange = async () => {
    console.log(offer.offerId)
    if (offer.status === 'Active') {
      const res = await PUT(BASE_URL + `api/Offer/updateOfferStatus`, {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
            offerId: offer.offerId,
            status: 'InActive'
        });
      if (res.status === 'success') {
        alert('Offer Status Updated Successfully');
        window.location.reload();
      }
    }
    else if (offer.status === 'InActive') {
      const res = await PUT(BASE_URL + `api/Offer/updateOfferStatus`, {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
          offerId: offer.offerId,
          status: 'Active'
      });
    if (res.status === 'success') {
      alert('Offer Status Updated Successfully');
      window.location.reload();
    }
    }
  }

  const dateFormat = (d) => {
    if (d) {
      const date = d?.split('T')[0].slice(0, 10).split('-');
      const formattedDate = `${date[0]}-${date[1]}-${date[2]}`;
      return formattedDate;
    }
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await PUT(BASE_URL + `api/Offer/UpdateOffer`, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
      offerId: offer.offerId,
      name: data.name,
      description: data.description,
      price: parseFloat(data.price),
      discount: parseInt(data.discount),
      validDate: data.validDate,
    });
    if (res.status === 'success') {
      alert('Offer Updated Successfully');
      setLoading(false);
      window.location.reload();
    }
    else{
      alert(res.message);
      setLoading(false);
    }
    // console.log(JSON.stringify({
    //  offerId: offer.offerId,
    //   name: data.name,
    //   description: data.description,
    //   price: parseFloat(data.price),
    //   discount: parseInt(data.discount),
    //   validDate: data.validDate,
    // }))
  }

  const allowOfferedit = () => {
      setOfferEdit(!offerEdit);
  }

  const handleGetReviews = async () => {
    const res = await GET(BASE_URL + `api/Review/getReviewsByOfferId?offerId=${state.state}`);
    if (res.status === 'success') {
      console.log(res.data);
      setReviews(res.data);
    }
  }



 
  return (
    <div>
      {loading ? <Loader /> :
        <div>
          <div className="flex justify-between items-center">

            <h3 className="text-xl text-primary font-bold pb-5">
              Offer Info
            </h3>
            <label className="flex cursor-pointer gap-2 items-center justify-end">
              <p className="text-sm">In Active</p>
              <input
                type="checkbox"
                defaultChecked={offer?.status === 'Active' ? true : false} className="sr-only peer"
                checked={offer?.status === 'Active' ? true : false}
                onChange={() => setModalIsOpen(true)}
              />
              <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
              <p className="text-sm">Active</p>
            </label>
          </div>

          {/* image section */}
          <div className="flex flex-row items-start justify-between w-fit">
            <div className="flex flex-col pb-5" >
              <img
                src={offerImage.base64 !== '' ? offerImage.base64 : (offer.imagePath ? BASE_URL + offer.imagePath : "images/image-placeholder.jpg")}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              {ImgEdit &&
                <div className="py-3">
                  <input
                    className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                    id="file_input"
                    type="file"
                    onChange={(event) => handleImageChange(event)}
                    disabled={!ImgEdit}
                  />

                  <div className="flex gap-3 py-3">
                    <button className="text-white bg-primary rounded-lg px-4 py-2"
                      onClick={handleOfferImgUpdate}
                    >
                      Save
                    </button>
                    <button
                      className="text-primary bg-gray-200 rounded-lg px-4 py-2"
                      onClick={() => setImgEdit(!ImgEdit)}
                    >
                      Cancel
                    </button>
                  </div>

                </div>
              }
            </div>
            <div className="flex flex-col flex-grow items-between">
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setImgEdit(!ImgEdit)}>
                <EditIcon />
              </button>

            </div>
          </div>

          {/* offerDetails */}
          <div className="flex flex-row items-start justify-end w-full">
                <button
                  className="text-primary py-2 px-4 rounded-lg"
                  onClick={allowOfferedit}
                >
                  <EditIcon />
                </button>
              </div>
          <form
            onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
             
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="overflow-hidden">

                    <div className="flex md:flex-row flex-col gap-10 pb-3">

                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Name</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          defaultValue={offer?.name}
                          disabled={!offerEdit}
                          id='name' {...register('name')}
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Discount</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          defaultValue={offer?.discount}
                          disabled={!offerEdit}
                          id='discount' {...register('discount')}

                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Price</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          defaultValue={offer?.price}
                          disabled={!offerEdit}
                          id='price' {...register('price')}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">service Type</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          defaultValue={offer?.serviceTypeName}
                          disabled
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Business</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          defaultValue={offer?.businessName}
                          disabled
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Status</label>
                        <select className="py-2 px-2 rounded-md border w-full"
                          disabled>
                          <option defaultValue="Active">Active</option>
                          <option defaultValue="InActive">InActive</option>
                        </select>
                      </div>

                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">

                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Valid till</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="Date"
                          defaultValue={dateFormat(offer?.validDate)}
                          disabled={!offerEdit}
                          id='validDate' {...register('validDate')}
                        />
                      </div>
                      <div className="flex flex-col  text-sm text-gray-500 w-full">
                        <label className="pb-1">Description</label>
                        <textarea defaultValue={offer?.description}
                          name="" rows="3" className="py-2 px-2 rounded-md border"
                          id='description' {...register('description')}
                          disabled={!offerEdit}></textarea>
                      </div>


                    </div>

                  </div>
                </div>
              </div>
            </div>
            {offerEdit &&

              <div className="flex justify-end my-4 gap-3">
                <button
                  type="submit"
                  className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                  onClick={() => setOfferEdit(!offerEdit)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-primary text-white py-2 px-4 rounded-lg"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div>

            }
          </form>

          <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      All Reviews
                    </h2>
                  </div>

                  <div></div>
                </div>

                <table className="min-w-full divide-y divide-gray-200 ">

                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Customer
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Rating
                          </span>
                        </div>
                      </th>




                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Comment
                          </span>
                        </div>
                      </th>


                      {/* <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Action
                          </span>
                        </div>
                      </th> */}

                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {reviews.map((rev) => (
                      console.log('ticket', rev),
                      <tr key={rev?.id}>
                      <td className="size-px whitespace-nowrap">
                        <div className="ps-6 lg:ps-3  px-6 py-3">
                          <div className="flex items-center gap-x-3">

                            <div className="grow">
                              <span className="block text-sm font-semibold text-gray-800 ">
                                {rev?.customer?.firstName} {rev?.customer?.lastName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="ext-sm text-gray-500">
                           
                            {rev?.rating}
                          </span>
                        </div>
                      </td>


                      <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500">
                            {rev?.comment}
                          </span>
                        </div>
                      </td>

                      {/* <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                          <span className="text-sm text-gray-500">
                            {rev?.comment}
                          </span>
                        </div>
                      </td> */}

                      

                    
                    

                    </tr>
                    ))}
                  </tbody>
                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">{reviews.length}</span>{" "}
                      results
                    </p>
                  </div>

                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>


          <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            appElement={document.getElementById('your-app-element-id')}
          >
           
                <div className="flex flex-col items-center gap-5 p-5">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you want to {offer.status === 'Active' ? 'deactivate' : 'activate'} the offer?</h2>
                  {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
                </div>

                <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              

             
          </Modal>
        </div>
      }
    </div>
  )
}