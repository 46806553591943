import React, { useState, useEffect, useRef } from "react";
import { GET } from "../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../utils/config.jsx";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { first } from "@amcharts/amcharts5/.internal/core/util/Array";
import { TokenService } from "../utils/TokenService.ts";

export default function Dropdown({ onBusinessSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [businessTitle, setBusinessTitle] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleBusinessList = async () => {
            const res = await GET(BASE_URL + "api/Admin/GetBusinessesList");
            if (res.status === 'success') {
                setUsers(res.data);
                setFilteredUsers(res.data); // Initialize filteredUsers with all users
            }
        };

        handleBusinessList();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (event) => {
        const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
        setSearchTerm(searchText);

        const filtered = users.filter((user) =>
            user.title.toLowerCase().includes(searchText) // Filter based on search term in title
        );
        setFilteredUsers(filtered);
    };

    const handleSelect = (id, title) => {
        setIsOpen(false);
        // Call the parent component's callback function with the selected ID
        onBusinessSelect(id);
        setBusinessTitle(title);
    };

    return (
        <div ref={dropdownRef} className="">
            <button
                id="dropdownSearchButton"
                onClick={toggleDropdown}
                data-dropdown-toggle="dropdownSearch"
                data-dropdown-placement="bottom"
                className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                type="button"
            >
                {businessTitle === '' ? 'Select Business' : businessTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
                {!isOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
            </button>

            {isOpen && (
                <div id="dropdownSearch" className="z-10 absolute right-10 bg-white rounded-lg shadow w-60">
                    <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                id="input-group-search"
                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearch} // Add onChange handler for search input
                            />
                        </div>
                    </div>
                    <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
                        {filteredUsers.map((user) => (
                            <li key={user.id} onClick={() => handleSelect(user.id, user.title)}>
                                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                                    <label htmlFor="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                                        {user.title}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export function AllUserDropdown({ onSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [businessTitle, setBusinessTitle] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleBusinessList = async () => {
            const res = await GET(BASE_URL + "api/User/GetAllUsersList",{
                "Authorization": "Bearer " + TokenService.getAccessToken(),
            });
            if (res.status === 'success') {
                const data = res.data.filter((user) => user.roleId !== 1 && user.roleId !== 6 && user.roleId !== 7);
                setUsers(data);
                setFilteredUsers(data); // Initialize filteredUsers with all users
            }
        };

        handleBusinessList();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (event) => {
        const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
        setSearchTerm(searchText);

        const filtered = users.filter((user) =>
            user.firstName.toLowerCase().includes(searchText) || user.lastName.toLowerCase().includes(searchText) // Filter based on search term in title;
        );
        setFilteredUsers(filtered);
    };

    const handleSelect = (id, firstName, lastName) => {
        let title = firstName + ' ' + lastName;
        setIsOpen(false);
        // Call the parent component's callback function with the selected ID
        onSelect(id);
        setBusinessTitle(title);
    };

    return (
        <div ref={dropdownRef} className="">
            <button
                id="dropdownSearchButton"
                onClick={toggleDropdown}
                data-dropdown-toggle="dropdownSearch"
                data-dropdown-placement="bottom"
                className="w-full justify-between text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                type="button"
            >
                {businessTitle === '' ? 'Select User' : businessTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
                {!isOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
            </button>

            {isOpen && (
                <div id="dropdownSearch" className="z-10 absolute  bg-white rounded-lg shadow w-fit">
                    <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                id="input-group-search"
                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearch} // Add onChange handler for search input
                            />
                        </div>
                    </div>
                    <ul className="h-40 w-full px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
                        {filteredUsers.map((user) => (
                            <li key={user.id} onClick={() => handleSelect(user.id, user.firstName, user.lastName)}>
                                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                                    <label htmlFor="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                                        {user.firstName + ' ' + user.lastName}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export function UserDropdown({ onSelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [businessTitle, setBusinessTitle] = useState('');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleBusinessList = async () => {
            const res = await GET(BASE_URL + "api/User/GetAllCustomers");
            if (res.status === 'success') {
                setUsers(res.data);
                setFilteredUsers(res.data); // Initialize filteredUsers with all users
            }
        };

        handleBusinessList();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSearch = (event) => {
        const searchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
        setSearchTerm(searchText);

        const filtered = users.filter((user) =>
            user.firstName.toLowerCase().includes(searchText) || user.lastName.toLowerCase().includes(searchText) // Filter based on search term in title;
        );
        setFilteredUsers(filtered);
    };

    const handleSelect = (id, firstName, lastName) => {
        let title = firstName + ' ' + lastName;
        setIsOpen(false);
        // Call the parent component's callback function with the selected ID
        onSelect(id);
        setBusinessTitle(title);
    };

    return (
        <div ref={dropdownRef} className="">
            <button
                id="dropdownSearchButton"
                onClick={toggleDropdown}
                data-dropdown-toggle="dropdownSearch"
                data-dropdown-placement="bottom"
                className="text-white bg-primary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                type="button"
            >
                {businessTitle === '' ? 'Select User' : businessTitle} <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" />
                {!isOpen ? <ExpandMoreIcon className="text-white" /> : <ExpandLessIcon className="text-white" />}
            </button>

            {isOpen && (
                <div id="dropdownSearch" className="z-10 absolute right-10 bg-white rounded-lg shadow w-60">
                    <div className="p-3">
                        <label htmlFor="input-group-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-500 " aria-hidden="true" />
                            </div>
                            <input
                                type="text"
                                id="input-group-search"
                                className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearch} // Add onChange handler for search input
                            />
                        </div>
                    </div>
                    <ul className="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700" aria-labelledby="dropdownSearchButton">
                        {filteredUsers.map((user) => (
                            <li key={user.id} onClick={() => handleSelect(user.id, user.firstName, user.lastName)}>
                                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                                    <label htmlFor="checkbox-item-11" className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded ">
                                        {user.firstName + ' ' + user.lastName}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}
