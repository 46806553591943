// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDDknuH0-2tzdtdoky14tSN_GPc2ta6P3Q",
    authDomain: "lowincome.firebaseapp.com",
    projectId: "lowincome-ac806",
    storageBucket: "lowincome-ac806.appspot.com",
    messagingSenderId: "921101260451",
    appId: "com.madison.lowincome"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);