import React from 'react'
import Baselayout from '../components/layouts/Baselayout';
import Content from './register/content';

export default function SupportRegister() {
  return (
    <Baselayout>
        <Content />
    </Baselayout>
  )
}
