import React from 'react'
import Baselayout from "../components/layouts/Baselayout";
import Content from "./dashboard/content";
import { TokenService } from "../utils/TokenService.ts";
import BusinessDashboard from './businessdashboard/content'

export default function Dashboard() {
    return (
      <Baselayout>
  {(TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6) ? (
        <Content />
       ) : (
        <BusinessDashboard />
        )
      } 
      </Baselayout>
    );
}
