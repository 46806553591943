import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import { useParams, useLocation } from "react-router-dom";
import Content from "./ticketdetails/content";

export default function Support() {
  const { state } = useLocation();

  return (
    <Baselayout>
      <Content state={state} />
    </Baselayout>
  );
}
