import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./aggreement/content";

export default function Aggrement() {
  return (
    <Baselayout>
      <Content />
    </Baselayout>
  );
}
