import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './offerdetails/content'
import {useLocation} from 'react-router-dom'

export default function OfferDetails() {
  const {state} = useLocation();
  return (
    <Baselayout>
    <Content state={state}/>
    </Baselayout>
  )
}
