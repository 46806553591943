import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './notificationlogs/content'


export default function NotificationLogs() {
    return (
        <Baselayout>
            <Content />
        </Baselayout>
    )
}
