import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./appointmentdetails/Content.jsx";
import { useParams, useLocation } from "react-router-dom";

export default function AppointmentDetails() {
  const { state } = useLocation();
  return (
    <>
      <Baselayout>
        <Content state={state} />
      </Baselayout>
    </>
  );
}
