import React, { useState } from "react";
import { set, useForm } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PUT } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import Loader from "../../components/Loader.jsx";


const Content = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState(false); 
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); 
  const [passwordsMatch, setPasswordsMatch] = useState(true); 
  const [isLoading, setIsLoading] = useState(false); 
  let firebaseUID = '';
  let email = '';
  const auth = getAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const Token = urlParams.get('vt');
  console.log(Token);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle confirm password visibility
  };

  const handlePasswordChange = (e) => {
    const confirmPassword = e.target.value;
    const password = e.target.form.password.value;
    setPasswordsMatch(password === confirmPassword);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const res = await PUT(BASE_URL + 'api/Admin/SetPassword', {
      password: data.password,
      confirmPassword: data.confirmPassword,
      verificationToken: Token
    });

    if (res.status === 'success') {
      console.log('Password set successfully:', res.data);
      email = res.data;
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, res.data, data.password).then(async (userCredential) => {
          firebaseUID = userCredential.user.uid;
          console.log('firebaseUID', firebaseUID);
        });
        if (firebaseUID !== '') {
          const res = await PUT(BASE_URL + 'api/Admin/setFirebaseId', {

            email: email,
            firebaseUserId: firebaseUID
          });
          if (res.status === 'success') {
            alert('Password set successfully');            
          }
          else {
            alert(res.message);
            setIsLoading(false);
          }
        }
        window.location.href = './';
        setIsLoading(false);


      }
      catch (error) {
        console.error('Firebase user creation error:', error);
      }
    }
  };

 

  return (
    <div>
      {!isLoading ? (
        <main className="flex items-center justify-center h-[100vh] w-full mx-auto p-6">
          <div className="w-full lg:w-[30%] bg-white border border-gray-200 rounded-xl shadow-sm ">
            <div className="p-4 sm:p-7   ">
              <div className="flex items-center justify-center">
                <img className="w-32" src="/images/logo.png" alt="Logo" />
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-5">
                  <div className="grid gap-y-4">
                    <div>
                      <label htmlFor="password" className="block text-sm mb-2">Password</label>
                      <div className="relative">
                        <input type={showPassword ? "text" : "password"} id="password" {...register('password', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.password ? 'border-red-500' : 'border-gray-300'}`} />
                        {/* Eye icon to toggle password visibility */}
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          {showPassword ? (
                            <VisibilityOff onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                          ) : (
                            <Visibility onClick={handleTogglePassword} className="text-gray-400 cursor-pointer" />
                          )}
                        </span>
                      </div>
                      {errors.password && <p className="text-xs text-red-600 mt-2">Password is required</p>}
                    </div>
                    <div>
                      <label htmlFor="confirmPassword" className="block text-sm mb-2">Confirm Password</label>
                      <div className="relative">
                        <input type={showConfirmPassword ? "text" : "password"} id="confirmPassword" {...register('confirmPassword', { required: true })} onChange={handlePasswordChange} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'}`} />
                        {/* Eye icon to toggle confirm password visibility */}
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          {showConfirmPassword ? (
                            <VisibilityOff onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                          ) : (
                            <Visibility onClick={handleToggleConfirmPassword} className="text-gray-400 cursor-pointer" />
                          )}
                        </span>
                      </div>
                      {errors.confirmPassword && <p className="text-xs text-red-600 mt-2">Confirm Password is required</p>}
                      {!passwordsMatch && <p className="text-xs text-red-600 mt-2">Passwords do not match</p>}
                    </div>
                    <div className="mt-5">
                      <button type="submit" className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg">Set Password</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      ) : <Loader />

      }
    </div>

  );
};

export default Content;
