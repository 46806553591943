import React, { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../utils/config";
import { DELETE, GET, PUT } from "../../utils/ApiRequestProvider.tsx";
import { Link } from "react-router-dom";
import { TokenService } from "../../utils/TokenService.ts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../components/Loader.jsx";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import EditIcon from '@mui/icons-material/Edit';
import Modal from "react-modal";


export default function Content({ state }) {
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [workers, setWorkers] = React.useState([]);
  const [myAppointments, setMyAppointments] = React.useState([]);
  const [myOffers, setMyOffers] = React.useState([]);
  const [pdfUrl, setPdfUrl] = React.useState('');
  const [monday, setMonday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [tuesday, setTuesday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [wednesday, setWednesday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [thursday, setThursday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [friday, setFriday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [saturday, setSaturday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [sunday, setSunday] = useState({ id: 0, isClosed: true, openingTime: '', closingTime: '' });
  const [deleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [description, setDescription] = useState('');

  Modal.setAppElement(document.getElementById('modal')); 

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };


  let today = new Date();

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const formatTime = (timeString) => {
    if (timeString !== null) {
      let time = timeString.split('T')[1]; // Extract time part from the string
      time = time.split(':').slice(0, 2).join(':'); // Extract hours and minutes and join them
      return time;
    }
  };

  React.useEffect(() => {
    if (state) {
      handleGetData();
    }
  }, [state]);

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${state}`);
    if (res.status === 'success') {

      setUserData(res.data);

      setPdfUrl(BASE_URL + res.data?.business?.filePath);
      // console.log('userData', res.data);
      if (res.data.businessId) {
        handleGetWorkers(res.data.businessId);
        handleGetAppointments(res.data.businessId);
        handleGetOffers(res.data.businessId);
        getBusinessHours(res.data.businessId)
      }
      // handleGetWorkers(res.data.businessId);
      setIsLoading(false);
    }
  }


  const getBusinessHours = async (business_id) => {
    const res = await GET(BASE_URL + `api/BusinessHour/getBusinessHourByBusinessId?businessId=${business_id}`);
    if (res.status === 'success') {
      console.log('business hours:', res.data);
      setMonday({
        id: res.data[0]?.id,
        isClosed: res.data[0]?.isClosed,
        openingTime: formatTime(res.data[0]?.openingTime),
        closingTime: formatTime(res.data[0]?.closingTime),
      });
      setTuesday({
        id: res.data[1]?.id,
        isClosed: res.data[1]?.isClosed,
        openingTime: formatTime(res.data[1]?.openingTime),
        closingTime: formatTime(res.data[1]?.closingTime),
      });
      setWednesday({
        id: res.data[2]?.id,
        isClosed: res.data[2]?.isClosed,
        openingTime: formatTime(res.data[2]?.openingTime),
        closingTime: formatTime(res.data[2]?.closingTime),
      });
      setThursday({
        id: res.data[3]?.id,
        isClosed: res.data[3]?.isClosed,
        openingTime: formatTime(res.data[3]?.openingTime),
        closingTime: formatTime(res.data[3]?.closingTime),
      });
      setFriday({
        id: res.data[4]?.id,
        isClosed: res.data[4]?.isClosed,
        openingTime: formatTime(res.data[4]?.openingTime),
        closingTime: formatTime(res.data[4]?.closingTime),
      });
      setSaturday({
        id: res.data[5]?.id,
        isClosed: res.data[5]?.isClosed,
        openingTime: formatTime(res.data[5]?.openingTime),
        closingTime: formatTime(res.data[5]?.closingTime),
      });
      setSunday({
        id: res.data[6]?.id,
        isClosed: res.data[6]?.isClosed,
        openingTime: formatTime(res.data[6]?.openingTime),
        closingTime: formatTime(res.data[6]?.closingTime),
      });
    }
    else {
      alert(res.message);
    }
  }

  const handlePdfDownload = () => {
    window.open(`${pdfUrl}`, '_blank');
  };

  const renderLoader = () => {
    return (
      <div className="flex items-center justify-center w-full h-90">
        <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleGetWorkers = async (business_id) => {
    setIsLoading(true);
    // console.log('business_id', business_id);
    const res = await GET(BASE_URL + `api/User/GetAllBusinessWorkerList?businessId=${business_id}`);
    if (res.status === 'success') {
      setWorkers(res.data);
      // console.log('workers', res.data);
      setIsLoading(false);
    }
  }

  const handleGetAppointments = async (id) => {

    const res = await GET(BASE_URL + `api/Appointment/GetAllAppointmentListByBusiness?businessId=${id}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
    });

    if (res.status === 'success') {

      setMyAppointments(res.data); // Update users state with filtered data
      console.log(res.data);
    }
  }

  const handleGetOffers = async (id) => {

    const res = await GET(BASE_URL + `api/Admin/getAllOffersByBusinessList?BusinessId=${id}`);
    if (res.status === 'success') {
      setMyOffers(res.data);
      console.log('my Offers:', res.data);
    }
  }

  const dateFormat = (d) => {
    if (d) {
      const date = d?.split('T')[0].slice(0, 10).split('-');
      const formattedDate = `${date[0]}-${date[1]}-${date[2]}`;
      // console.log('formattedDate', formattedDate);
      return formattedDate;
    }
  }

  const handleStatusChange = async () => {
    console.log('userId', userId);
    const res = await DELETE(BASE_URL + "api/User/deleteUser", {
      userId: userId,
      note: description
    });
    if (res.status === 'success') {
      setDeleteModal(false);
      alert('business deleted successfully');
      window.location.reload();
    }
    else {
      alert(res.message);
    }
  }

  return (
    <div>
      {isLoading ?
        <Loader />
        :
        <div>


          {TokenService.getUserRole() === 4 ?
            <div className="flex flex-row items-center justify-between my-2">
              <Link to="/serviceproviderinfoedit" state={userData.id}
                className="flex items-center justify-end py-2 px-2  hover:bg-gray-100 rounded-md cursor-pointer w-fit"
              >
                <button className="flex flex-row  gap-2 ">
                  <span>Edit</span>
                  <EditIcon />
                </button>
              </Link>
              <label className="flex cursor-pointer gap-2 items-center justify-end">
                <p className="text-sm">In Active</p>
                <input type="checkbox" defaultChecked={userData.status} className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
                <p className="text-sm">Active</p>
              </label>


            </div>
            :
            <div className="flex flex-row items-center justify-end my-2">
              <label className="flex cursor-pointer gap-2 items-center justify-end">
                <p className="text-sm">In Active</p>
                <input type="checkbox" defaultChecked={userData.status} className="sr-only peer" />
                <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
                <p className="text-sm">Active</p>
              </label>
            </div>
          }
          <div className="pb-5">
            <img
              src={userData.profilePath ? BASE_URL + userData.profilePath : "images/image-placeholder.jpg"}
              className="w-[150px] h-[150px] object-cover rounded-full"
            />

            {/* <div className="py-3">
          <input
            class="block w-full text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
            id="file_input"
            type="file"
            disabled='true'
          />
        </div> */}
          </div>
          <div>
            <h3 className="text-xl text-primary font-bold pb-5">
              Service Provider Info
            </h3>

            {/* Business User Details */}
            <div class="flex flex-col">
              <div class="-m-1.5 overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                  <div class="overflow-hidden">

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">First Name</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={userData?.firstName}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Last Name</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={userData?.lastName}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Gender</label>
                        <select className="py-2 px-2 rounded-md border w-full"
                          value={userData?.gender}
                          disabled='true'>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Other</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Marital Status</label>
                        <select className="py-2 px-2 rounded-md border w-full"
                          value={userData?.maritalStatus}
                          disabled='true'>
                          <option>Single</option>
                          <option>Married</option>
                          <option>Divorced</option>
                          <option>Student</option>
                        </select>
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">DOB</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="date"
                          // placeholder="Jhango"
                          defaultValue={dateFormat(userData?.dob)}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Email</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={userData?.email}
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Phone</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={userData?.phone}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Created At</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={formatDate(userData?.createdAt)}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">City</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          defaultValue={userData?.city?.name}
                          disabled='true'
                        />
                      </div>
                    </div>

                    <div className="flex md:flex-row flex-col gap-10 pb-3">
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Zip Code</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          defaultValue={userData?.zipCode}
                          disabled='true'
                        />
                      </div>
                      <div className="flex flex-col text-sm text-gray-500 w-full">
                        <label className="pb-1">Address</label>
                        <input
                          className="py-2 px-2 rounded-md border w-full"
                          type="text"
                          // placeholder="Jhango"
                          value={userData?.address}
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* Business Details */}
            <>
              <h3 className="text-xl text-primary font-bold pb-5">
                Business Info
              </h3>
              <div className="flex flex-row items-center justify-around py-5">
                <div className="flex flex-col items-center justify-between gap-5">
                  <img
                    src={userData?.business?.logoPath ? BASE_URL+userData?.business?.logoPath:"images/image-placeholder.jpg"}
                    className="w-[150px] h-[150px] object-cover rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center justify-between gap-5">
                  <img
                    src={userData.business?.bProfilePath ? BASE_URL+userData.business?.bProfilePath : "images/image-placeholder2.jpg"}
                    className="w-[600px] h-[280px] object-cover rounded-md"
                  />
                </div>
              </div>
              <div class="flex flex-col">
                <div class="-m-1.5 overflow-x-auto">
                  <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="overflow-hidden">
                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Title</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            // placeholder="Jhango"
                            value={userData?.business?.title}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Phone</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="tel"
                            // placeholder="Jhango"
                            value={userData?.business?.phone}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Email</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="email"
                            // placeholder="Jhango"
                            value={userData?.business?.email}
                            disabled='true'
                          />
                        </div>
                      </div>
                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">City</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            // placeholder="Jhango"
                            value={userData?.business?.city?.name}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Zip Code</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="tel"
                            // placeholder="Jhango"
                            value={userData?.business?.zipCode}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Address</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="email"
                            // placeholder="Jhango"
                            value={userData?.business?.address}
                            disabled='true'
                          />
                        </div>
                      </div>
                      <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">License Number</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            // placeholder="Jhango"
                            value={userData?.business?.facilitylicense}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Document</label>
                          <button onClick={handlePdfDownload} className="flex flex-row items-center justify-between py-2 px-2 rounded-md border w-full">
                            <span>{userData?.business?.fileName}</span>
                            <DownloadIcon />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>

            {/* working hours */}
            <div className="bg-gray-100 p-4 rounded-md">
              <h3 className="text-primary text-lg font-bold">Working days</h3>
              <div className="flex flex-col gap-5">

                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Monday
                      </span>
                      <input
                        type="checkbox"
                        // defaultChecked={monday.isClosed}
                        checked={!monday.isClosed}
                        onChange={(e) => console.log(e.target.value)}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={monday.openingTime}

                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={monday.closingTime}
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Tuesday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={tuesday?.isClosed}
                        checked={!tuesday?.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border"
                      type="time"
                      disabled={true}
                      defaultValue={tuesday?.openingTime}
                    // disabled={true}
                    />

                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      defaultValue={tuesday?.closingTime}

                    />
                  </div>
                </div>

                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Wednesday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={wednesday?.isClosed ? "true" : 'false'}
                        checked={!wednesday?.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={wednesday?.openingTime}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      value={wednesday?.closingTime}
                    // placeholder="Jhango"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Thursday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={thursday.isClosed ? "true" : 'false'}
                        checked={!thursday.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={thursday.openingTime}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={thursday.closingTime}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Friday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={friday.isClosed ? "true" : 'false'}
                        checked={!friday.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={friday.openingTime}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      value={friday.closingTime}
                    // placeholder="Jhango"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Saturday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={saturday.isClosed ? "true" : 'false'}
                        checked={!saturday.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={saturday.openingTime}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      value={saturday.closingTime}
                    // placeholder="Jhango"
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-5 items-center justify-between">
                  <div className="w-[33.33%]">
                    <label class="flex flex-row justify-between items-center cursor-pointer">
                      <span class="ms-3 text-sm font-medium text-gray-900 ">
                        Sunday
                      </span>
                      <input
                        type="checkbox"
                        defaultChecked={sunday?.isClosed ? "true" : 'false'}
                        checked={!sunday?.isClosed}
                        class="sr-only peer"
                      />
                      <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500  w-[33.33%]">
                    <label className="pb-1">Open Time</label>
                    <input
                      className="py-2 px-2 rounded-md border "
                      type="time"
                      disabled={true}
                      value={sunday?.openingTime}
                    // placeholder="Jhango"

                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-[33.33%]">
                    <label className="pb-1">Close Time</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="time"
                      disabled={true}
                      // placeholder="Jhango"
                      value={sunday?.closingTime}

                    />
                  </div>
                </div>
              </div>
            </div>

            {/* workers details */}
            <div>
              <h3 className="text-xl text-gray-700 font-semibold py-5">
                Workers
              </h3>
              {/* {workers === null?
                renderLoader()
                : */}
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Phone
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              DOB
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Address
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Service Type
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Martial Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Actions
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {workers.map((user) => (
                        <tr key={user.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to='/workerinfodetails' state={user.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {user.firstName} {user.lastName}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {user.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                <svg
                                  className="size-2.5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </svg>
                                {user.status}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.dob)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.address}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.serviceType}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.maritalStatus}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.createdAt)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to='/workeredit' state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm text-primary px-2 py-1 rounded-lg  decoration-2 hover:text-black font-medium ">
                                  <EditIcon />
                                </button>
                              </Link>
                              <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    onClick={() => {setDeleteModal(true); setUserId(user.id)}}
                                  >
                                    <DeleteIcon className="text-primary hover:text-black" />
                                  </a>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to='/workerinfodetails' state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium ">
                                  View
                                </button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{workers?.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div></div>
                  </div>
                </div>
              {/* } */}
            </div>

            {/* Appointments Info */}
            <div>
              <h3 className="text-xl text-gray-700 font-semibold py-5">
                Appointments
              </h3>
              {/* {myAppointments.length === 0 ?
                renderLoader()
                : */}
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Creator
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Service
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Assignee/ Cancellee
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Business
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {myAppointments && myAppointments.map((appointment) => (
                        <tr key={appointment.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to='/customerinfodetails' state={appointment.creator.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {appointment.creator.firstName} {appointment.creator.lastName}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                {appointment.status}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              <span className="text-sm text-gray-500">
                                {appointment.offer?.serviceType?.name}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {
                                appointment.assignee ?
                                  <Link to='/workerinfodetails' state={appointment.assigneeId}>
                                    <span className="text-sm text-gray-500 hover:underline hover:text-gray-800">
                                      {appointment.assignee?.firstName + ' ' + appointment.assignee?.lastName}
                                    </span>
                                  </Link>
                                  :
                                  appointment.cancellee ?
                                    <Link to='/workerinfodetails' state={appointment.cancelleeId}>
                                      <span className="text-sm text-gray-500">
                                        {appointment.cancellee?.firstName + ' ' + appointment.cancellee?.lastName}
                                      </span>
                                    </Link>
                                    :
                                    <span className="text-sm text-gray-500">
                                      Not assigned yet
                                    </span>
                              }
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {appointment.offer?.business?.title}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(appointment.appointmentDate)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">

                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <Link to={'/appointmentdetails'} state={appointment.id}>
                                  <button className="text-sm text-white bg-primary p-2 rounded-xl">
                                    View
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>

                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{myAppointments?.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div></div>
                  </div>
                </div>
              {/* } */}
            </div>

            {/* Offers Info */}
            <div>
              <h3 className="text-xl text-gray-700 font-semibold py-5">
                Offers
              </h3>
              {/* {myOffers.length === 0 ?
                renderLoader()
                : */}
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Title
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Service
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Business Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Valid till
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Price
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Discount
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {myOffers && myOffers.map((offer, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <span className="block text-sm font-semibold text-gray-800 ">
                                    {offer.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              {new Date(offer.validDate) < today ? (
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                  <CancelIcon className="text-red-500" fontSize="12" />
                                  Expired
                                </span>
                              ) : (
                                <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                  <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                  Active
                                </span>
                              )}
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              <span className="text-xs text-white bg-primary p-2 rounded-full">
                                {offer.serviceType?.name}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {offer.business?.title}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(offer.createdAt)}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(offer.validDate)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  ${offer.price}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {offer.discount}%
                              </span>
                            </div>
                          </td>
                          {/* <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-1.5 flex gap-3 items-center">
                            <Link to="/workeredit"> *
                            <span
                              className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                              href="#"
                            >
                              <EditIcon className="text-primary hover:text-black" />
                            </span>
                            </Link>
                           
                            <a
                              className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                              href="#"
                            >
                              <BlockIcon className="text-primary hover:text-black" />
                            </a>
                          </div>
                        </td> */}
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <Link to="/offerdetails" state={offer.id}>
                                <button className="bg-primary text-white px-4 py-1 rounded-lg hover:bg-primary-dark">
                                  View
                                </button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{myAppointments?.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div></div>
                  </div>
                </div>
              {/* } */}
            </div>

          </div>
        </div>}
        <Modal
            isOpen={deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={customStyles}
            appElement={document.getElementById('modal')}
          >
           
                <div className="flex flex-col items-center gap-5 px-4 ">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you delete the user?</h2>
                  {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
  
                  <textarea
                    className="w-full p-2 min-h-20 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
    

                <div className="flex justify-center items-center gap-x-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setDeleteModal(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              </div>
              

             
          </Modal>
    </div>
  );
}
