import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './notifications/content'

export default function Notifications() {
  return (
    <Baselayout>
    <Content />
    </Baselayout>
  )
}
