import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./offers/content";

export default function Home() {
  return (
    <>
      <Baselayout>
        <Content />
      </Baselayout>
    </>
  );
}
