import React, { useState, useEffect, useRef } from "react";
import { TokenService } from '../utils/TokenService.ts';
import { GET } from "../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../utils/config.jsx";
import { Link } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';



function Timer() {
  const [loginTime, setLoginTime] = useState(TokenService.getTimer());
  const [timeRemaining, setTimeRemaining] = useState(1);

  useEffect(() => {
    const storedLoginTime = TokenService.getTimer();
    setLoginTime(storedLoginTime);

    const calculateTimeRemaining = () => {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - new Date(storedLoginTime).getTime();
      setTimeRemaining(24 * 60 * 60 * 1000 - elapsedTime); // 24 hours in milliseconds
    };

    calculateTimeRemaining();

    const timer = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(timer);
  }, []);


  useEffect(() => {
    const remainingTime = timeRemaining;
    const alertThresholds = [60 * 60 * 1000, 30 * 60 * 1000, 10 * 60 * 1000]; // 1 hour, 30 minutes, and 10 minutes in milliseconds
    const thresholdsMessages = ["1 hour", "30 minutes", "10 minutes"];

    alertThresholds.forEach((threshold, index) => {
      if (remainingTime <= threshold && remainingTime > threshold - 1000) {
        alert(`Your session will expire in ${thresholdsMessages[index]}. Please save your work and refresh the page.`);
      }
    });
  }, [timeRemaining]);

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = '/';
    TokenService.setAccessToken('');
  };

  const formatTime = (ms) => {
    const hours = Math.floor(ms / (1000 * 60 * 60));
    const minutes = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((ms % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <header>
        <p>Time remaining: {formatTime(timeRemaining)}</p>
      </header>
      {timeRemaining <= 0 && (
        <div>
          <p>Your session has expired. You are being logged out...</p>
          {logout()}
        </div>
      )}
    </div>
  );
}

export default function Header() {
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);
  const [notifications, setNotifications] = useState([]);

  const handleGetNotifications = async () => {
    try {
      const res = await GET(BASE_URL + 'api/Notification/getNotificationLogs');
      if (res.status === "success") {
        // Slice the latest 4 notifications from the fetched data
        const latestNotifications = res.data.slice(0, 4);
        setNotifications(latestNotifications);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }

  const handleLogout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
    TokenService.setAccessToken('');
  };

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications) {
      handleGetNotifications(); // Fetch notifications when opening the dropdown
    }
  }
const limitString = (str, limit) => {
    return str.length > limit ? str.substring(0, limit) + '...' : str;
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); 

  useEffect(() => {
    if(TokenService.getAccessToken() === ""){
      window.location.href = "/";
    }
  } ,[]);

  const getLink = (ref) => {
    if (ref === 'Ticket') {
      return '/ticketdetails';
    }
    if (ref === 'BusinessUpdate') {
      return '/requests';
    }
    if (ref === 'Offer') {
      return '/offerdetails';
    }
    if (ref === 'Appointment') {
      return '/appointmentdetails';
    }
    if (ref === 'customer') {
      return '/customerinfodetails';
    }
    if (ref === 'business') {
      return '/serviceproviderdetails';
    }
    if (ref === 'UserUpdate') {
      return '/requests';
    }
  }

  return (
    <header className="sticky top-0 inset-x-0 flex flex-wrap sm:justify-start sm:flex-nowrap z-[48] w-full bg-primary border-b text-sm py-2.5 sm:py-4 lg:max-w-[84%] lg:m-auto lg:mr-0">
      <nav className="flex basis-full items-center w-full mx-auto px-4 sm:px-6 md:px-8" aria-label="Global">

        <div className="w-full flex items-center justify-between ms-auto sm:gap-x-3 sm:order-3">
          <div>
            <Timer />
          </div>
          <div className="flex flex-row items-center justify-end gap-4">
            <Link to="/userprofile" className="flex items-center ">
              <span className=" p-2 rounded-full  hover:text-primary text-white hover:bg-white">

              <PersonIcon className="w-6 h-6 " />
              </span>
            </Link>

            {TokenService.getUserRole() === 1 && (
              <button
                type="button"
                className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold 
                rounded-full border border-transparent text-white hover:text-primary hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none"
                onClick={handleShowNotifications}
              >
                <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9" />
                  <path d="M10.3 21a1.94 1.94 0 0 0 3.4 0" />
                </svg>
              </button>
            )}
            {showNotifications && (
              <div ref={notificationRef} className="absolute right-4 top-16 mt-2 bg-white rounded-md shadow overflow-hidden z-20" style={{ width: '20rem' }}>
                <div className="py-2">
                  {notifications.map((notification) => (
                    <Link key={notification.id} to={getLink(notification.ref)} state={notification.refId} onClick={()=>setShowNotifications(false)} className="flex flex-col items-start px-4 py-3 border-b hover:bg-gray-100 -mx-2"  >
                      <span className="font-bold text-primary">{notification.title}</span>
                      <p className="text-gray-600 text-sm mx-2">{limitString(notification.message, 50)}</p>
                    </Link>
                  ))}
                </div>
                <Link to="/notifications" className="block bg-primary text-white text-center font-bold py-2">See all notifications</Link>
                {/* <<a href="#" >className="block bg-primary text-white text-center font-bold py-2">See all notifications</a> */}
              </div>
            )}

            <div className="hs-dropdown relative inline-flex [--placement:bottom-right]">
              <button
                id="hs-dropdown-with-header"
                type="button"
                onClick={handleLogout}
                className="w-[2.375rem] h-[2.375rem] inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent text-white hover:text-black  disabled:opacity-50 disabled:pointer-events-none"
              >
                <p>Logout</p>
              </button>
            </div>
          </div>
        </div>
        
      </nav>
    </header>
  );
}