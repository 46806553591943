import React, { useEffect } from "react";
import { set, useForm } from "react-hook-form";
import EditIcon from '@mui/icons-material/Edit';
import { PUT } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";


export default function Content({state}) {
  // console.log('State: ',state);
  
  const [productedit, setProductedit] = React.useState(false);
  const [product, setProduct] = React.useState({
    name: state?.name, 
    basePrice: state?.basePrice,
    discountedPrice: state?.discountedPrice,
    totalPrice: state?.totalPrice,
    discount: state?.discount,
    standardDiscount: state?.standardDiscount,
    freeCount: state?.freeCount,
    totalCount: state?.totalCount,
    itration: state?.itration,
    range: state?.range,
    description: state?.description
  });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  useEffect(() => {
    setValue('name', state?.name);
    setValue('basePrice', state?.basePrice);
    setValue('discountedPrice', state?.discountedPrice);
    setValue('totalPrice', state?.totalPrice);
    setValue('discount', state?.discount);
    setValue('standardDiscount', state?.standardDiscount);
    setValue('freeCount', state?.freeCount);
    setValue('totalCount', state?.totalCount);
    setValue('itration', state?.itration);
    setValue('range', state?.range);
    setValue('description', state?.description);
    setProduct({
      name: state?.name, 
      basePrice: state?.basePrice,
      discountedPrice: state?.discountedPrice,
      totalPrice: state?.totalPrice,
      discount: state?.discount,
      standardDiscount: state?.standardDiscount,
      freeCount: state?.freeCount,
      totalCount: state?.totalCount,
      itration: state?.itration,
      range: state?.range,
      description: state?.description
    });
  }
  , [state]);

  const onSubmit =async (data) => {
    const res =await PUT(BASE_URL + 'api/Product/updateProduct', {
      productId: state.productId,
      name: data.name,
      range: state.range,
      description: data.description,
      discount: data.discount,
      basePrice: data.basePrice,
      standardDiscount: data.standardDiscount,
      discountedPrice: data.discountedPrice,
      totalCount: data.totalCount,
      freeCount: data.freeCount,
      totalPrice: data.totalPrice,
    })
     if(res.status === 'success'){
       console.log("Product Updated Successfully");
        alert("Product Updated Successfully")
        // window.location.reload('/products');
      }
      else{
        // alert(res,"Product Update Failed")
        console.log(res,"Product Update Failed");
      }

  };

  const handleTotalPrice = (value) => {
    let totalPrice = parseFloat(value);
    let discountedPrice = totalPrice / product.totalCount;
    let basePrice = discountedPrice / (1 - ((product.standardDiscount + product.discount) / 100));
  
    setProduct({ ...product, discountedPrice: parseFloat(discountedPrice.toFixed(2)) });
    setProduct({ ...product, basePrice: parseFloat(basePrice.toFixed(2)) });
    setProduct({ ...product, totalPrice: totalPrice }); // Keep totalPrice as float
    setValue('discountedPrice', parseFloat(discountedPrice.toFixed(2)));
    setValue('totalPrice', totalPrice); // Keep totalPrice as float
    setValue('basePrice', parseFloat(basePrice.toFixed(2)));
  }
  


  const handleDiscount = (value) => {
    console.log("discount:", ( (1 - ((parseFloat(value) + product.standardDiscount) / 100))));
    let discountedPrice = product.basePrice * (1 - ((parseFloat(value) + product.standardDiscount) / 100));
    let totalPrice = discountedPrice * product.totalCount;
    setProduct({ ...product, discountedPrice: discountedPrice.toFixed(2) });
    setProduct({ ...product, totalPrice: totalPrice.toFixed(2) });
    setProduct({ ...product, discount: parseFloat(value) });
    setValue('discountedPrice', discountedPrice.toFixed(2));
    setValue('totalPrice', totalPrice.toFixed(2));
    setValue('discount', parseFloat(value));
  }

  const handleStandardDiscount = (value) => {
    console.log("discount:", (product.basePrice * (1 - ((parseFloat(value) + product.discount) / 100))));
    let discountedPrice = product.basePrice * (1 - ((parseFloat(value) + product.discount) / 100));
    let totalPrice = discountedPrice * product.totalCount;
    setProduct({ ...product, discountedPrice: discountedPrice.toFixed(2) });
    setProduct({ ...product, totalPrice: totalPrice.toFixed(2) });
    setProduct({ ...product, standardDiscount: parseFloat(value) });
    setValue('discountedPrice', discountedPrice.toFixed(2));
    setValue('totalPrice', totalPrice.toFixed(2));
    setValue('standardDiscount', parseFloat(value));
  }

  const handleCount = (value) => {
    console.log("count:", (product.totalPrice / parseFloat(value)));
    let discountedPrice = product.totalPrice / parseFloat(value);
    let basePrice = discountedPrice / (1 - ((product.standardDiscount + product.discount) / 100));
    setProduct({ ...product, discountedPrice: discountedPrice.toFixed(2) });
    setProduct({ ...product, basePrice: basePrice.toFixed(2) });
    setProduct({ ...product, totalCount: parseFloat(value) });
    setValue('discountedPrice', discountedPrice.toFixed(2));
    setValue('totalCount', parseFloat(value));
    setValue('basePrice', basePrice.toFixed(2));
  }
  

  return (
    <div>

      <div>
        <div className="flex justify-between items-center">
        <h3 className="text-xl text-primary font-bold pb-5">
          Product Info
        </h3>
        <button
          className="text-primary py-2 px-4"
          onClick={() => setProductedit(!productedit)}
        >
          <EditIcon />      
          
        </button>
        </div>
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div className="flex md:flex-row flex-col gap-10 pb-3">
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Name</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="text"
                        // defaultValue={product.name}
                        // value={product.name}
                        id='name' {...register('name')}
                        disabled={!productedit}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Base Price</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number"
                        id='basePrice' {...register('basePrice')}
                        disabled
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Discounted Price</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number"
                        // defaultValue={product.discountedPrice}
                        // value={product.discountedPrice}
                        id='discountedPrice' {...register('discountedPrice')}
                        disabled
                      />
                    </div>
                  </div>

                  <div className="flex md:flex-row flex-col gap-10 pb-3">
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Total Price</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number" // Change input type to "number"
                        step="0.01" // Specify step to allow decimal input
                        id='totalPrice' {...register('totalPrice')}
                        onChange={(e) => handleTotalPrice(e.target.value)}
                        disabled={!productedit}
                      />

                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Discount %</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number"
                        // defaultValue={product.discount}
                        // value={product.discount}
                        id='discount' {...register('discount')}
                        onChange={(e) => handleDiscount(e.target.value)}
                        disabled={!productedit}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Standard Discount %</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number"
                        // defaultValue={product.standardDiscount}
                        // value={product.standardDiscount}
                        id='standardDiscount' {...register('standardDiscount')}
                        onChange={(e) => handleStandardDiscount(e.target.value)}
                        disabled={!productedit}
                      />
                    </div>
                    
                  </div>

                  <div className="flex md:flex-row flex-col gap-10 pb-3">
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Free Count</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="text"
                        // defaultValue={product.freeCount}
                        // value={product.freeCount}
                        id='freeCount' {...register('freeCount')}
                        disabled
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Total Count</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="number"
                        // defaultValue={product.totalCount}
                        // value={product.totalCount}
                        id='totalCount' {...register('totalCount')}
                        onChange={(e) => handleCount(e.target.value)}
                        disabled={!productedit}
                      />
                    </div>
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Iteration</label>
                      <input
                        className="py-2 px-2 rounded-md border w-full"
                        type="text"
                        // defaultValue={product.itration}
                        // value={product.itration}
                        id='itration' {...register('itration')}
                        disabled
                      />
                    </div>
                    
                  </div>

                  <div className="flex md:flex-row w-full flex-col gap-10 pb-3">  
                  {state.range !== '' &&
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                      <label className="pb-1">Salary Range</label>
                      <select 
                        className="py-2 px-2 rounded-md border w-full"
                        // defaultValue={product.range}
                        // value={product.range}
                        id='range' {...register('range')}
                        disabled
                      >
                        <option >Select</option>
                        <option value="$1000 - $2000">$1000 - $2000</option>
                        <option value="$2000 - $3000">$2000 - $3000</option>
                        <option value="$3000 - $4000">$3000 - $4000</option>
                        <option value="$4000 +">$4000 +</option>
                      </select>
                    </div>
                  }

                    <div className="flex flex-col  text-sm text-gray-500 w-full">
                      <label className="pb-1">Description</label>
                      <textarea 
                        defaultValue={product.description}
                        // onChange={(e) => console.log(e.target.value)}
                        id='description' {...register('description')}
                        name="" rows="3" className="py-2 px-2 rounded-md border"
                        disabled={!productedit}
                        ></textarea>
                    </div>
                  
                    
                  </div>
               
                </form>
              </div>
            </div>
          </div>
        </div>
        {productedit && (
          <div className="flex justify-end gap-2">
            <button className="bg-primary text-white hover:bg-blue-700 py-2 px-4 rounded-md" onClick={handleSubmit(onSubmit)}>
              Save
            </button>
          </div>
        )
        }
        
      </div>
    </div>
  );
}
