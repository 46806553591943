import React from 'react'
import Baselayout from "../components/layouts/Baselayout";
import Content from './emaillog/content';


export default function EmailLog() {
  return (
    <Baselayout>
        <Content/>
    </Baselayout>
  )
}
