import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { DELETE, GET, PUT } from "../../utils/ApiRequestProvider.tsx";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import { TokenService } from "../../utils/TokenService.ts";
import { getAuth } from "firebase/auth";
import Loader from "../../components/Loader.jsx";
import Modal from "react-modal";


const ResultsPerPage = 10;

export default function Content() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [salesUser, setSalesUser] = useState([]);
  const [supportUser, setSupportUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(0);
  const [description, setDescription] = useState('');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };
  Modal.setAppElement(document.getElementById('modal')); 
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;
  const auth = getAuth();
  const user = auth.currentUser?.uid;
  // console.log(user)
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {


    const handleSalesUser = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/User/GetAllSalesUser", {
        Authorization: 'Bearer' + TokenService.getAccessToken('access_token')
      });

      if (res.status === 'success') {
        setSalesUser(res.data); // Update users state with filtered data
        setLoading(false);
      }
      else {
        alert(res.message);
        setLoading(false);
      }
    }

    const handleSupportUser = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/User/GetAllSupportUser",
        { Authorization: 'Bearer' + TokenService.getAccessToken('access_token') });
      if (res.status === 'success') {
        setSupportUser(res.data);
        setLoading(false);
      }
      else {
        alert(res.message);
        setLoading(false);
      }
    }

    handleSalesUser()
    handleSupportUser()
  }, []);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleStatusChange = async () => {
    console.log('userId', userId);
    const res = await DELETE(BASE_URL + "api/User/deleteUser", {
      userId: userId,
      note: description
    });
    if (res.status === 'success') {
      setModalIsOpen(false);
      alert('User deleted successfully');
      window.location.reload();
    }
    else {
      alert(res.message);
    }
  }

  return (
    <div>
      {
        loading ? <Loader />
          :
          <div>

            <div className="flex flex-row items-center justify-between sm:px-6 mb-6">
              <div className="flex flex-row items-center  gap-3 bg-gray-300 px-2 py-2 rounded-md">
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  Support
                </button>
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  Sales
                </button>
              </div>
              <div>

              </div>
            </div>

            {/* Support users tab */}
            <div
              className=" sm:px-6 mx-auto"
              id="tab1"
              style={{ display: activeTab === "tab1" ? "block" : "none" }}
            >
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                        <div>
                          <h2 className="text-xl font-semibold text-gray-800 ">
                            Support Users
                          </h2>
                        </div>

                      </div>
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50 ">
                          <tr>
                            <th
                              scope="col"
                              className="ps-6 lg:ps-3 px-6 py-3 text-start"
                            >
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Name
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Status
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Phone
                                </span>
                              </div>
                            </th>


                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Created
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Action
                                </span>
                              </div>
                            </th>
                            {/* <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  View
                                </span>
                              </div>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 ">
                          {supportUser.slice(startIndex, endIndex).map((user, index) => (
                            <tr key={index}>
                              <td className="size-px whitespace-nowrap">
                                <div className="ps-6 lg:ps-3  px-6 py-3">
                                  <div className="flex items-center gap-x-3">
                                    <img
                                      className="inline-block size-[38px] rounded-full"
                                      src="images/image-placeholder.jpg"
                                      alt="Image Description"
                                    />
                                    <div className="grow">
                                      <Link to="/serviceproviderdetails" state={user.id}>
                                        <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                          {user.firstName} {user.lastName}
                                        </span>
                                      </Link>
                                      <span className="block text-sm text-gray-500">
                                        {user.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                    <svg
                                      className="size-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                    Active
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <div className="flex items-center gap-x-3">
                                    <span className="text-sm text-gray-500">
                                      {user.phone}
                                    </span>
                                  </div>
                                </div>
                              </td>

                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {formatDate(user.createdAt)}
                                  </span>
                                </div>
                              </td>

                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    href="#"
                                  >
                                    <EditIcon className="text-primary hover:text-black" />
                                  </a>

                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    onClick={() => {setModalIsOpen(true); setUserId(user.id)}}
                                  >
                                    <DeleteIcon className="text-primary hover:text-black" />
                                  </a>
                                </div>
                              </td>
                              {/* <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3 flex flex-row gap-2">
                                  <Link state={user.id} to={"/serviceproviderdetails"}>
                                  <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                                    View Details
                                  </span>
                                  </Link>
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                        <div>
                          <p className="text-sm text-gray-600 ">
                            <span className="font-semibold text-gray-800 ">{supportUser.length}</span>{" "}
                            results
                          </p>
                        </div>

                        <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(supportUser.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(supportUser.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sales users tab */}
            <div
              className=" sm:px-6 mx-auto"
              id="tab2"
              style={{ display: activeTab === "tab2" ? "block" : "none" }}
            >
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                        <div>
                          <h2 className="text-xl font-semibold text-gray-800 ">
                            Sales Users
                          </h2>
                        </div>

                        <div></div>
                      </div>
                      <table className="min-w-full divide-y divide-gray-200 ">
                        <thead className="bg-gray-50 ">
                          <tr>
                            <th
                              scope="col"
                              className="ps-6 lg:ps-3 px-6 py-3 text-start"
                            >
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Name
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Status
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Phone
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-start">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Created
                                </span>
                              </div>
                            </th>

                            <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Action
                                </span>
                              </div>
                            </th>
                            {/* <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  View
                                </span>
                              </div>
                            </th> */}
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 ">
                          {salesUser.slice(startIndex, endIndex).map((user, index) => (
                            <tr key={index}>
                              <td className="size-px whitespace-nowrap">
                                <div className="ps-6 lg:ps-3  px-6 py-3">
                                  <div className="flex items-center gap-x-3">
                                    <img
                                      className="inline-block size-[38px] rounded-full"
                                      src="images/image-placeholder.jpg"
                                      alt="Image Description"
                                    />
                                    <div className="grow">
                                      <Link to="/serviceproviderdetails" state={user.id}>
                                        <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                          {user.firstName} {user.lastName}
                                        </span>
                                      </Link>
                                      <span className="block text-sm text-gray-500">
                                        {user.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                    <svg
                                      className="size-2.5"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                    Active
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <div className="flex items-center gap-x-3">
                                    <span className="text-sm text-gray-500">
                                      {user.phone}
                                    </span>
                                  </div>
                                </div>
                              </td>




                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3">
                                  <span className="text-sm text-gray-500">
                                    {formatDate(user.createdAt)}
                                  </span>
                                </div>
                              </td>
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    href="#"
                                  >
                                    <EditIcon className="text-primary hover:text-black" />
                                  </a>

                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    onClick={() => {setModalIsOpen(true); setUserId(user.id)}}
                                  >
                                    <DeleteIcon className="text-primary hover:text-black" />
                                  </a>
                                </div>
                              </td>
                              {/* <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-3 flex flex-row gap-2">
                                  <Link state={user.id} to={"/serviceproviderdetails"}>
                                  <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                                    View Details
                                  </span>
                                  </Link>
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                        <div>
                          <p className="text-sm text-gray-600 ">
                            <span className="font-semibold text-gray-800 ">{salesUser.length}</span>{" "}
                            results
                          </p>
                        </div>

                        <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(salesUser.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(salesUser.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
      }
       <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            appElement={document.getElementById('modal')}
          >
           
                <div className="flex flex-col items-center gap-5 px-4 ">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you delete the user?</h2>
                  {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
  
                  <textarea
                    className="w-full p-2 min-h-20 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
    

                <div className="flex justify-center items-center gap-x-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              </div>
              

             
          </Modal>
    </div>
  );
}
