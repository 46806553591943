import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./sendemail/content";
import { TokenService } from "../utils/TokenService.ts";


export default function SendEmail() {
  return (
    <Baselayout>
     {(TokenService.getUserRole() === 1 || TokenService.getUserRole() === 6) ? (
        <Content />
      ) : (
        <p> You are not authorized to Perform this Task.</p>
      )
      }
    </Baselayout>
  );
}
