import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { BASE_URL } from "../../utils/config.jsx";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { Link } from "react-router-dom";
import { TokenService } from "../../utils/TokenService.ts";
import Loader from "../../components/Loader.jsx";

export default function Content({state}) {
  // console.log(state);
  const [userData, setUserData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [appointments, setAppointments] = React.useState([]);
  const [pdfUrl, setPdfUrl] = React.useState('');


  const formatDate = (dateString) => {
    if (!dateString) return ''; // Return empty string if dateString is falsy
    const date = new Date(dateString); // Parse the dateString into a Date object 
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Format the date as "Feb 24, 2020"
  };

  const formatTime = (timeString) => {
    console.log(timeString.split('T')[1]);
    let time = timeString.split('T')[1];
    return time;

  };
  React.useEffect(() => {
    if (state) {
      handleGetData();
    }
  }, [state]);

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL +`api/User/GetUserById?user=${state}`);
    if (res.status === 'success') {
      console.log(res.data);
      setUserData(res.data);
      handleGetAppointments(res.data.id);
      setPdfUrl(BASE_URL + res.data?.business?.filePath);
      setIsLoading(false);
    }
  }

  const handleGetAppointments = async (id) => {
    setIsLoading(true);
    const res = await GET(BASE_URL +`api/Appointment/getOnlyAppointmentListAssignTo?userId=${id}`);
    if (res.status === 'success') {
      setAppointments(res.data);
      console.log(res.data);
      setIsLoading(false);
    }
  }

  const handlePdfDownload = () => {
    window.open(`${pdfUrl}`, '_blank');
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl text-primary font-bold pb-5">
          Worker Details
        </h3>
        <label className="flex cursor-pointer gap-2 items-center justify-end">
          <p className="text-sm">In Active</p>
          <input type="checkbox" defaultChecked={userData.status} className="sr-only peer" />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
          <p className="text-sm">Active</p>
        </label>
      </div>
      {isLoading ? 
       <Loader />
      :
      <>
      <div className="pb-5">
      <img
              src={userData.profilePath ? BASE_URL + userData.profilePath : "images/image-placeholder.jpg"}
              className="w-[150px] h-[150px] object-cover rounded-full"
            />

        {/* <div className="py-3">
          <input
            className="block w-full text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
            id="file_input"
            type="file"
            disabled={true}
          />
        </div> */}
      </div>
      <div>

      {/* Person  nal Info */}
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
          <h3 className="text-xl text-gray-700 font-semibold pb-5">
          Personnal Info
        </h3>
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">

                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">First Name</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      // placeholder="Jhango"
                      defaultValue={userData?.firstName}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Last Name</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      // placeholder="Jhango"
                      defaultValue={userData?.lastName}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Gender</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.gender}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Marital Status</label>
                    <select className="py-2 px-2 rounded-md border w-full"
                    defaultValue={userData?.maritalStatus} 
                    disabled={true}>
                      <option>Single</option>
                      <option>Married</option>
                      <option>Divorced</option>
                      <option>Student</option>
                    </select>
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">DOB</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="date"
                      // placeholder="Jhango"
                      defaultValue={userData?.dob ? new Date(userData?.dob).toISOString().split('T')[0] : ''}
                      disabled={true}
                    />

                  </div>
                  {/* <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Monthly Income</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.monthlyIncome}
                      disabled={true}
                    />
                  </div> */}
                    <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Email</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.email}
                      disabled={true}
                    />
                  </div>
                 
                </div>

                <div className="flex md:flex-row flex-col gap-10 pb-3">

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Phone</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.phone}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Created At</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={formatDate(userData?.createdAt)}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">City</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.city?.name}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-10 pb-3">

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">ZIP Code</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={userData?.zipCode}
                      disabled={true}
                    />
                  </div>

                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Address</label>
                    {/* <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      defaultValue={formatDate(userData?.createdAt)}
                      disabled={true}
                    /> */}
                    <textarea
                      className="py-2 px-2 rounded-md border w-full h-20"
                      type="address"
                      // placeholder="Jhango"
                      defaultValue={userData?.address}
                      disabled={true}
                      />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

           {/* Business Details */}
           <>
        <h3 className="text-xl text-primary font-bold pb-5">
          Business Info
        </h3>
        <div className="flex flex-row items-center justify-around py-5">
        <div className="flex flex-col items-center justify-between gap-5">
                  <img
                    src={userData?.business?.logoPath ? BASE_URL+userData?.business?.logoPath:"images/image-placeholder.jpg"}
                    className="w-[150px] h-[150px] object-cover rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center justify-between gap-5">
                  <img
                    src={userData.business?.bProfilePath ? BASE_URL+userData.business?.bProfilePath : "images/image-placeholder2.jpg"}
                    className="w-[600px] h-[280px] object-cover rounded-md"
                  />
                </div>
        </div>
        <div class="flex flex-col">
          <div class="-m-1.5 overflow-x-auto">
            <div class="p-1.5 min-w-full inline-block align-middle">
              <div class="overflow-hidden">
                <div className="flex md:flex-row flex-col gap-10 pb-3">
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Business Title</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="text"
                      // placeholder="Jhango"
                      value={userData?.business?.title}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Phone</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="tel"
                      // placeholder="Jhango"
                      value={userData?.business?.phone}
                      disabled='true'
                    />
                  </div>
                  <div className="flex flex-col text-sm text-gray-500 w-full">
                    <label className="pb-1">Business Email</label>
                    <input
                      className="py-2 px-2 rounded-md border w-full"
                      type="email"
                      // placeholder="Jhango"
                      value={userData?.business?.email}
                      disabled='true'
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col gap-10 pb-3">
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">License Number</label>
                          <input
                            className="py-2 px-2 rounded-md border w-full"
                            type="text"
                            // placeholder="Jhango"
                            value={userData?.business?.facilitylicense}
                            disabled='true'
                          />
                        </div>
                        <div className="flex flex-col text-sm text-gray-500 w-full">
                          <label className="pb-1">Business Document</label>
                          <button onClick={handlePdfDownload} className="flex flex-row items-center justify-between py-2 px-2 rounded-md border w-full">
                            <span>{userData?.business?.fileName}</span>
                            <DownloadIcon />
                          </button>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </div>
        </>


        {/* Appointments Data */}
        <div>
          <h3 className="text-xl text-gray-700 font-semibold py-5">
            Appointments
          </h3>
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-y-hidden  w-full overflow-scroll">
          <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Creator 
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Status
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Service
                          </span>
                        </div>
                      </th>
                      {/* <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Assignee/ Cancellee
                          </span>
                        </div>
                      </th> */}

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Business
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Created
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            View
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {appointments && appointments.map((appointment) => (
                      <tr key={appointment.id}>
                        <td className="size-px whitespace-nowrap">
                          <div className="ps-6 lg:ps-3  px-6 py-3">
                            <div className="flex items-center gap-x-3">
                              <img
                                className="inline-block size-[38px] rounded-full"
                                src="images/image-placeholder.jpg"
                                alt="Image Description"
                              />
                              <div className="grow">
                                <Link to='/customerinfodetails' state={appointment.creator.id}>
                                <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                  {appointment.creator.firstName} {appointment.creator.lastName}
                                </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                {appointment.status}
                              </span>
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3 flex flex-row gap-3">
                            <span className="text-sm text-gray-500">
                              {appointment.serviceType?.name}
                            </span>
                          </div>
                        </td>
                        {/* <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3">
                            {
                              appointment.assignee ?
                                <Link to='/workerinfodetails' state={appointment.assigneeId}>
                                  <span className="text-sm text-gray-500 hover:underline hover:text-gray-800">
                                    {appointment.assignee?.firstName + ' ' + appointment.assignee?.lastName}
                                  </span>
                                </Link>
                                :
                                appointment.cancellee ?
                                  <Link to='/workerinfodetails' state={appointment.cancelleeId}>
                                    <span className="text-sm text-gray-500">
                                      {appointment.cancellee?.firstName + ' ' + appointment.cancellee?.lastName}
                                    </span>
                                  </Link>
                                  :
                                  <span className="text-sm text-gray-500">
                                  Not assigned yet
                                  </span>
                            }
                          </div>
                        </td> */}
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                             {appointment.business?.title}
                            </span>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {formatDate(appointment.appointmentDate)}
                            </span>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                         
                          <div className="px-6 py-3">
                            <div className="flex items-center gap-x-3">
                              <Link to={'/appointmentdetails'} state={appointment.id}>
                              <button className="text-sm text-white bg-primary p-2 rounded-xl">
                               View
                              </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                      
                    ))}
                  </tbody>
                </table>
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
              <div>
                <p className="text-sm text-gray-600 ">
                  <span className="font-semibold text-gray-800 ">{appointments?.length}</span>{" "}
                  results
                </p>
              </div>

              <div></div>
            </div>
          </div>
        </div>
      </div>
      </>
      }
      
    </div>
  );
}
  