import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./customerinfodetails/content";
import { useLocation } from "react-router-dom";

const CustomerInfoDetails = () => {
  const { state } = useLocation();

  return (
    <Baselayout>
      <Content state={state} />
      </Baselayout>
  );
}
export default CustomerInfoDetails;
