import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader.jsx";
import { TokenService } from "../../utils/TokenService.ts";

export default function Content() {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [business, setBusiness] = useState([]);
  const [customer, setCustomer] = useState([]);
  const auth = getAuth();
  const user = auth.currentUser?.uid;
  // console.log(user)
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };



  // console.log(email, password);
  useEffect(() => {


    const handleGetProducts = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/Subscription/GetSubscriptionProductList");
      if (res.status === 'success') {

        const filteredCustomer = res.data.filter(
          (pro) => pro.range !== '' // Filter Products with non-empty monthlyIncome
        );
        setCustomer(filteredCustomer); // Update users state with filtered data

        const filteredBusiness = res.data.filter(
          (pro) => pro.range === '' // Filter Products with non-empty monthlyIncome
        );
        setBusiness(filteredBusiness); // Update users state with filtered data
        setLoading(false);
      }
      else {
        alert(res.message);
        setLoading(false);
      }

    }



    handleGetProducts()
  }, []);




  return (
    <div>
      {loading ? (
        <Loader />
      ) :
        <div>
          <div className="flex flex-row items-center sm:px-6 mb-6">
            <div className="flex flex-row items-center  gap-3 bg-gray-300 px-2 py-2 rounded-md">
              <button
                className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                  }`}
                onClick={() => handleTabClick("tab1")}
              >
                Business
              </button>
              <button
                className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                  }`}
                onClick={() => handleTabClick("tab2")}
              >
                Customer
              </button>
            </div>
          </div>
          {/* Business Products tab */}
          <div
            className=" sm:px-6 mx-auto"
            id="tab1"
            style={{ display: activeTab === "tab1" ? "block" : "none" }}
          >
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">
                          Business Products
                        </h2>
                      </div>

                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="ps-6 lg:ps-3 px-6 py-3 text-center"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Name
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Description
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Base Price
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Discounted Price
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Total Price
                              </span>
                            </div>
                          </th>


                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Total Count
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Iteration
                              </span>
                            </div>
                          </th>
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                              <th scope="col" className="px-6 py-3 text-center">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                    Actions
                                  </span>
                                </div>
                              </th>

                            )
                          }

                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {business.map((pro, index) => (
                          <tr>
                            <td className="size-px whitespace-nowrap">
                              <div className="ps-6 lg:ps-3  px-6 py-3">
                                <div className="flex items-center gap-x-3">

                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 ">
                                      {pro.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.description}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3 flex flex-row gap-3">
                                <span className="text-sm text-gray-500">
                                  {pro.basePrice}
                                </span>
                              </div>
                            </td>



                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.discountedPrice}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.totalPrice}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.totalCount}
                                </span>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.itration}
                                </span>
                              </div>
                            </td>
                            {
                              (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                                <td className="size-px whitespace-nowrap">
                                  <div className="px-6 py-1.5 flex gap-3">
                                    <Link state={pro} to="/edit-product">
                                      <a
                                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                      // href="#"

                                      >
                                        <EditIcon className="text-primary hover:text-black" />
                                      </a>
                                    </Link>

                                  </div>
                                </td>
                              )
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{business.length}</span>{" "}
                          results
                        </p>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Customer Products tab */}
          <div
            className=" sm:px-6 mx-auto"
            id="tab2"
            style={{ display: activeTab === "tab2" ? "block" : "none" }}
          >
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                      <div>
                        <h2 className="text-xl font-semibold text-gray-800 ">
                          Customer Products
                        </h2>
                      </div>

                    </div>
                    <table className="min-w-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            className="ps-6 lg:ps-3 px-6 py-3 text-center"
                          >
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Name
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Salary Range
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Description
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Base Price
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Discounted Price
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Total Price
                              </span>
                            </div>
                          </th>


                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Discount %
                              </span>
                            </div>
                          </th>

                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Standard Discount%
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Free months
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="px-6 py-3 text-center">
                            <div className="flex items-center gap-x-2">
                              <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                Iteration
                              </span>
                            </div>
                          </th>
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                              <th scope="col" className="px-6 py-3 text-center">
                                <div className="flex items-center gap-x-2">
                                  <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                    Actions
                                  </span>
                                </div>
                              </th>

                            )
                          }
                          

                        </tr>
                      </thead>

                      <tbody className="divide-y divide-gray-200 ">
                        {customer.map((pro, index) => (
                          <tr>
                            <td className="size-px whitespace-nowrap">
                              <div className="ps-6 lg:ps-3  px-6 py-3">
                                <div className="flex items-center gap-x-3">

                                  <div className="grow">
                                    <span className="block text-sm font-semibold text-gray-800 ">
                                      {pro.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <div className="flex items-center gap-x-3">
                                  <span className="text-sm text-gray-500">
                                    {pro.range}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.description}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3 flex flex-row gap-3">
                                <span className="text-sm text-gray-500">
                                  {pro.basePrice}
                                </span>
                              </div>
                            </td>



                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.discountedPrice}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.totalPrice}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.discount}
                                </span>
                              </div>
                            </td>

                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.standardDiscount}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.freeCount}
                                </span>
                              </div>
                            </td>
                            <td className="size-px whitespace-nowrap">
                              <div className="px-6 py-3">
                                <span className="text-sm text-gray-500">
                                  {pro.itration}
                                </span>
                              </div>
                            </td>
                            {
                              (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                                <td className="size-px whitespace-nowrap">
                                  <div className="px-6 py-1.5 flex gap-3">
                                    <Link state={pro} to="/edit-product">
                                      <a
                                        className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                      // href="#"

                                      >
                                        <EditIcon className="text-primary hover:text-black" />
                                      </a>
                                    </Link>

                                  </div>
                                </td>
                              )
                            }

                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                      <div>
                        <p className="text-sm text-gray-600 ">
                          <span className="font-semibold text-gray-800 ">{customer.length}</span>{" "}
                          results
                        </p>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
