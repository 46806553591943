import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
// import { models } from 'powerbi-client';
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { models, Report, Embed, service, Page } from "powerbi-client";
import { CustomDropdown } from "../../components/dropdowncustom";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { UserDropdown } from "../../components/dropdown";
import { Pie, Line } from "react-chartjs-2";
import "chart.js/auto";
ChartJS.register(ArcElement, Tooltip, Legend);
export default function Content() {
  const [EarningchartData, setEarningchartData] = useState(null);
  const [appointmentStatusData, setAppointmentStatusData] = useState(null);
  const [appointmentStatusByServiceData, setAppointmentStatusServiceData] =
    useState(null);
  const [earningData, setEarningData] = useState(null);
  const [totalEarning, setTotalEarning] = useState(0);
  const [userData, setUserData] = useState(null);
  const [userdisable, setUserDisable] = useState(false);
  const [RNuserData, setRNUserData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [users, setUsers] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedAge, setSelectedAge] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState("");
  const [selectedMS, setSelectedMS] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [businessData, setBusinessData] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [businessCount, setBusinessCount] = useState(0);
  const [ticketCount, setTicketCount] = useState(0);
  const [remainingSubs, setRemainingSubs] = useState(0);
  const [frequentUsers, setFrequentUsers] = useState([]);
  const [ratingChart, setRatingChart] = useState(null);
  const [usersCreatedMonthly, setUsersCreatedMonthly] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (add 1 because month is zero-indexed) and pad
    const year = date.getFullYear(); // Get full year
    return `${day}-${month}-${year}`; // Return formatted date in dd-mm-yyyy format
  };
  // const [selectedCountry, setSelectedCountry] = useState('');
  const [searchTerm, setSearchTerm] = useState("");

  // Filter countries based on the search term

  // Define a color palette array
  const colorPalette = [
    "#EFD82B",
    "#275DAB",
    "#FF6384",
    "#36A2EB",
    "#FF99C3",
    "#4BC0C0",
    "#C9DE55",
    "#B5928E",
    "#8B008B",
    "#808080",
  ];
  // Function to get a color from the palette based on index
  const getColorFromPalette = (index) => {
    return colorPalette[index % colorPalette.length];
  };


  const formatEarningChartData = (data) => {
    const chartData = {
      labels: [],
      datasets: [
        {
          label: "Customer Earnings",
          data: [],
          backgroundColor: getColorFromPalette(0), // Color for Customer Earnings
        },
        {
          label: "Business Earnings",
          data: [],
          backgroundColor: getColorFromPalette(1), // Color for Business Earnings
        },
      ],
    };
    data.forEach((item) => {
      const formattedDate = formatDate(item.startDate);
      chartData.labels.push(formattedDate);
      if (item.userType === "Customer") {
        chartData.datasets[0].data.push(item.earning);
      } else if (item.userType === "Business") {
        chartData.datasets[1].data.push(item.earning);
      }
    });
    return chartData;
  };
  const formatAppointmentStatusData = (data) => {
    const labels = data.map((item) => item.appointmentStatus);
    const counts = data.map((item) => item.count);
    const formattedData = {
      labels: labels,
      datasets: [
        {
          label: "Appointments",
          data: counts,
          backgroundColor: labels.map((label, index) =>
            getColorFromPalette(index)
          ), // Assign colors dynamically
        },
      ],
    };
    return formattedData;
  };
  const formatAppointmentByServiceTypeData = (data) => {
    const labels = data.map((item) => item.serviceTypeName);
    const counts = data.map((item) => item.count);
    const formattedData = {
      labels: labels,
      datasets: [
        {
          label: "Appointments",
          data: counts,
          backgroundColor: labels.map((label, index) =>
            getColorFromPalette(index)
          ), // Assign colors dynamically
        },
      ],
    };
    return formattedData;
  };
  const formatBusinessChartData = (data) => {
    const cityCounts = {};
    // Count businesses for each city
    data.forEach((business) => {
      const cityName = business.cityName;
      if (cityCounts[cityName]) {
        cityCounts[cityName] += 1;
      } else {
        cityCounts[cityName] = 1;
      }
    });
    // Prepare chart data
    const chartData = {
      labels: Object.keys(cityCounts),
      datasets: [
        {
          label: "Number of Businesses",
          data: Object.values(cityCounts),
          backgroundColor: Object.keys(cityCounts).map((key, index) =>
            getColorFromPalette(index)
          ), // Assign colors dynamically
        },
      ],
    };
    return chartData;
  };
  const formatUserData = (data) => {
    const chartData = {
      labels: [], // City names
      datasets: [], // Array to hold datasets (one per user type)
    };
    if (!Array.isArray(data) || data.length === 0) {
      console.error("Invalid data format or empty data array:", data);
      return chartData;
    }
    // Organize data by city and user type
    const cityMap = {};
    data.forEach((entry) => {
      const { cityName, userType, count } = entry;
      const label = cityName || "Unknown"; // Use 'Unknown' if cityName is null
      if (!cityMap[label]) {
        cityMap[label] = {};
        chartData.labels.push(label);
      }
      cityMap[label][userType] = count;
    });
    // Create datasets based on user types
    const userTypes = ["Business", "Customer", "Worker", "Sale", "Support"];
    userTypes.forEach((userType, index) => {
      const dataPoints = chartData.labels.map(
        (cityName) => cityMap[cityName]?.[userType] || 0
      );
      chartData.datasets.push({
        label: userType,
        data: dataPoints,
        backgroundColor: getColorFromPalette(index), // Assign color based on index
      });
    });
    return chartData;
  };
  const formatEarningData = (data) => {
    const labels = data.map((item) => item.subscription);
    const earnings = data.map((item) => item.earning);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "Earning by Type",
          data: earnings,
          backgroundColor: ["#275DAB", "#EFD82B", "#FF6384"], // Colors for each slice
        },
      ],
    };

    return chartData;
  };
  const formatRNUserData = (data) => {
    const labels = data.map((item) => item.user_type);
    const counts = data.map((item) => item.user_count);

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: "User Type Count",
          data: counts,
          backgroundColor: [
            "#275DAB",
            "#EFD82B",
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
          ], // Colors for each slice
        },
      ],
    };

    return chartData;
  };
  const calculate_age = (dob1) => {
    var today = new Date();
    // const dob = users[1].users[0].dob;
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    // console.log(age_now);
    return age_now;
  };
  const formatRemainingSubsData = (data) => {
    const labels = [];
    const counts = [];

    data.forEach((business) => {
        labels.push(business.businessTitle);
        // Calculate total remaining counts for each business
        const totalRemainingCount = business.remainingSubs.reduce((acc, sub) => {
            return acc + sub.remainingCount;
        }, 0);
        counts.push(totalRemainingCount);
    });

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "Remaining Counts",
                data: counts,
                backgroundColor: colorPalette.slice(0, labels.length), // Use a subset of the color palette
            },
        ],
    };

    return chartData;
};

const formatBusinessRatingData = (data) => {
  const labels = [];
        const ratings = [];

        data.forEach((business) => {
            labels.push(business.title);
            ratings.push(business.rating);
        });

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: "Business Ratings",
                    data: ratings,
                    backgroundColor: colorPalette.slice(0, labels.length), // Use a subset of the color palette
                },
            ],
        };

        return chartData;
      };
      const formatUserCountsByRole = (data) => {
        const chartData = {
          labels: [], // Month labels
          datasets: [], // Array to hold datasets (one per role)
        };
      
        if (!Array.isArray(data) || data.length === 0) {
          console.error("Invalid data format or empty data array:", data);
          return chartData;
        }
      
        const userTypes = ["Customer", "Business", "Worker", "Support", "Sales"];
      
        // Initialize datasets for each user type
        userTypes.forEach((userType, index) => {
          chartData.datasets.push({
            label: userType,
            data: [],
            backgroundColor: getColorFromPalette(index),
          });
        });
      
        // Process each entry in the data
        data.forEach((entry) => {
          const { month, detail } = entry;
      
          // Add month to labels array
          chartData.labels.push(month);
      
          // Initialize user counts map for the current month
          const userCountsMap = {};
          detail.forEach(({ role, userCount }) => {
            userCountsMap[role] = userCount;
          });
      
          // Populate data for each user type
          userTypes.forEach((userType, index) => {
            const userCount = userCountsMap[userType] || 0;
            chartData.datasets[index].data.push(userCount);
          });
        });
      
        return chartData;
      };
      
      
      // Function to generate random colors from a predefined palette
      
  useEffect(() => {
    const handlegetTotalUserCreatedMonthly = async () => {
      const res = await GET(BASE_URL + "api/Reports/getTotalUserCreatedMonthly");
      if (res.status === "success") {
        const formattedData = formatUserCountsByRole(res.data);
        console.log("FORMAT 2",formattedData);
        setUsersCreatedMonthly(formattedData);
      } else {
        console.error("Error fetching user data:", res);
      }
    };

    const handleGetFrequentUsers = async () => {
      const res = await GET(BASE_URL + "api/Reports/getFrequentCustomers");
    //   if (res.status === "success") {
    //     // Assuming the API response structure remains consistent
    //     const formattedCustomersData = res.data.map((customerEntry) => {
    //         // Extract the firstName and lastName from the first customer in the array
    //         const { firstName, lastName } = customerEntry.customers[0];
    //         return { firstName, lastName };
    //     });
    //     setFrequentUsers(formattedCustomersData);
    // } else {
    //     console.error("Error fetching customer data:", res);
    // }
    if (res.status === "success") {
      // Extracting data from the response
      const formattedFrequentCustomersData = res.data.map((customerEntry) => {
          const customer = customerEntry.customers[0];
          const { id, firstName, lastName, dob, gender, address, email, phone } = customer;
          return { id, firstName, lastName, dob, gender, address, email, phone };
      });
      setFrequentUsers(formattedFrequentCustomersData);
  } else {
      console.error("Error fetching frequent customers data:", res);
  }
    };
    const handleGetbusinessrating = async () => {
      const res = await GET(BASE_URL + 'api/Reports/getBusinessRating');
      if (res.status === 'success') {
          const formattedData = formatBusinessRatingData(res.data);
          setRatingChart(formattedData);
      } else {

          console.log('Error fetching business rating:', res);
      }
  };
    const handleGetRemainingSubs = async () => {
      const res = await GET(BASE_URL + "api/Reports/getRemainingSubsCount");
      if (res.status === "success") {
        const formattedData = formatRemainingSubsData(res.data);
        setRemainingSubs(formattedData);
      } else {
        console.log("Error fetching remaining subscriptions:", res);
      }
    };

    const fetchBusinessEarning = async () => {
      const res = await GET(BASE_URL + `api/Reports/getBusinessEarning`);
      if (res.status === "success") {
        // console.log(res.data);
        const formattedData = formatEarningChartData(res.data);
        console.log("format  1",formattedData);
        setEarningchartData(formattedData);
      } else {
        console.log("Total Earning", res);
      }
    };
    const fetchActiveAppointment = async () => {
      const res = await GET(BASE_URL + `api/Reports/getAppointmentStatusCount`);
      if (res.status === "success") {
        //   console.log(res.data);
        const formattedData = formatAppointmentStatusData(res.data);
        setAppointmentStatusData(formattedData);
      } else {
        console.log("Total Earning", res);
      }
    };
    const fetchActiveAppointmentByServiceType = async () => {
      const res = await GET(
        BASE_URL + `api/Reports/getAppointmentServiceCount`
      );
      if (res.status === "success") {
        //   console.log(res.data);
        const formattedData = formatAppointmentByServiceTypeData(res.data);
        setAppointmentStatusServiceData(formattedData);
      } else {
        console.log("Total Earning", res);
      }
    };

    const fetchTotalEarning = async () => {
      try {
        const res = await GET(BASE_URL + "api/Reports/getTotalEarning");
        if (res.status === "success") {
          setTotalEarning(res.data);
        } else {
          console.log("Error fetching business data:", res);
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    const fetchTotalUser = async () => {
      try {
        const res = await GET(BASE_URL + "api/Reports/getUserCount");
        if (res.status === "success") {
          setUserCount(res.data);
        } else {
          console.log("Error fetching business data:", res);
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    const fetchTotalBusiness = async () => {
      try {
        const res = await GET(BASE_URL + "api/Reports/getBusinessCount");
        if (res.status === "success") {
          setBusinessCount(res.data);
        } else {
          console.log("Error fetching business data:", res);
        }
      } catch (error) {
        console.error("Error fetching business data:", error);
      }
    };
    const fetchUser = async () => {
      const res = await GET(BASE_URL + `api/Reports/getUsers`);
      if (res.status === "success") {
        console.log(res.data);
        const formattedData = formatUserData(res.data);
        console.log("User Data", formattedData);
        setUserData(formattedData);
      } else {
        console.log("Total Earning", res);
      }
    };
    const fetchUserData = async () => {
      const res = await GET(BASE_URL + `api/Reports/getUsers`);
      if (res.status === "success") {
        console.log(res.data);
        setUsers(res.data);
      } else {
        console.log("Total Earning", res);
      }
    };
    const fetchData = async () => {
      try {
        const res = await GET(BASE_URL + "api/Reports/getEarningByType");
        if (res.status === "success") {
          const formattedData = formatEarningData(res.data);
          setEarningData(formattedData);
        } else {
          console.log("Error fetching earning data:", res);
        }
      } catch (error) {
        console.error("Error fetching earning data:", error);
      }
    };
    const fetchRNCustomerData = async () => {
      try {
        const res = await GET(BASE_URL + "api/Reports/getNewAndReturneeCount");
        if (res.status === "success") {
          const formattedData = formatRNUserData(res.data);
          setRNUserData(formattedData);
        } else {
          console.log("Error fetching user data:", res);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    handlegetTotalUserCreatedMonthly();
    fetchRNCustomerData();
    fetchData();
    fetchActiveAppointment();
    fetchBusinessEarning();
    fetchUser();
    fetchActiveAppointmentByServiceType();
    fetchTotalEarning();
    fetchTotalUser();
    fetchTotalBusiness();
    fetchUserData();
    handleGetRemainingSubs();
    handleGetbusinessrating();
    handleGetFrequentUsers();
    // calculate_age();
  }, []);
  const filteredUsers = users.filter((user) => {
    const matchesUserType =
      selectedUserType === "" || user.userType === selectedUserType;
    const matchesCountry =
      selectedCountry === "" || user.state === selectedCountry;
    const matchesCity = selectedCity === "" || user.city === selectedCity;
    const fileteredinner = user.users.filter((e) => {
      const ages = calculate_age(e.dob);
      const age1 = selectedAgeGroup.split("-");
      const matchesAgeGroup =
        selectedAgeGroup === "" || (ages >= age1[0] && ages <= age1[1]);
      const matchesMS = selectedMS === "" || e.maritalStatus === selectedMS;
      const matchesGender =
        selectedGender === "" || e.gender === selectedGender;
      return matchesAgeGroup && matchesMS && matchesGender;
    });
    return (
      matchesUserType &&
      matchesCountry &&
      matchesCity &&
      fileteredinner.length > 0
    );
  });

  const userTypes = ["Business", "Customer", "Worker", "Sale", "Support"]; // Add more if needed
  const countries = [...new Set(users.map((user) => user.state))]; // Extract unique countries
  const cities = [...new Set(users.map((user) => user.city))]; // Extract unique cities
  // const age = [...new Set(users.map((user) => user.users.map((e)=> calculate_age(e.dob) !== 0) ))]; // Extract unique cities
  const ageGroup = [
    "0-10",
    "11-20",
    "21-30",
    "31-40",
    "41-50",
    "51-60",
    "61-70",
    "71-80",
    "81-90",
    "91-100",
  ];
  const maritalStatus = ["Single", "Married", "Divorced", "Student", "Other"];
  const gender = ["Male", "Female", "Other"];

  // Prepare data for the bar chart
  const chartData = {
    labels: cities,
    datasets: userTypes.map((type, index) => ({
      label: type,
      backgroundColor: colorPalette[index % colorPalette.length],
      borderColor: colorPalette[index % colorPalette.length],
      borderWidth: 1,
      hoverBackgroundColor: colorPalette[index % colorPalette.length],
      hoverBorderColor: colorPalette[index % colorPalette.length],
      data: cities.map(
        (city) =>
          filteredUsers
            .filter((user) => user.city === city && user.userType === type)
            .flatMap((user) => user.users).length // Flatten the array of users
      ),
    })),
  };
  const filteredCountries = countries.filter((country) =>
    country.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // useEffect(() => {
  //   console.log('filteredCountries', selectedCountry);
  // }, [selectedCountry]);

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setUserDisable(true);
    console.log("selectedCountry", selectedCountry);
  };

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        {/* Total Users Card */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            Total Users
          </h2>
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-600">Registered Users:</p>
            <p className="text-lg font-semibold text-gray-800">{userCount}</p>
          </div>
        </div>

        {/* Earnings Card */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">Earnings</h2>
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-600">Total Earnings:</p>
            <p className="text-lg font-semibold text-gray-800">
              ${totalEarning.toFixed(2)}
            </p>
          </div>
        </div>

        {/* Active Users Card */}
        <div className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-lg font-semibold text-gray-800 mb-2">
            Businesses
          </h2>
          <div className="flex items-center justify-between">
            <p className="text-sm text-gray-600">Total Busniess:</p>
            <p className="text-lg font-semibold text-gray-800">
              {businessCount}
            </p>
          </div>
        </div>

        {/* <div className="bg-white shadow-md rounded-lg p-4">
    <h2 className="text-lg font-semibold text-gray-800 mb-2">Total Tickets</h2>
    <div className="flex items-center justify-between">
      <p className="text-sm text-gray-600">Tickets:</p>
      <p className="text-lg font-semibold text-gray-800">3000</p>
    </div>
  </div> */}
      </div>
      <div className="container mx-auto p-6">
        {/* <h1 className="text-3xl font-bold mb-4">User Data Dashboard</h1> */}

        {/* Filters Section */}
        <div className="flex flex-wrap justify-between mb-4">
          {/* User Type Filter */}
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="userTypeFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by User Type
            </label>
            <select
              id="userTypeFilter"
              value={selectedUserType}
              onChange={(e) => setSelectedUserType(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All</option>
              {userTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Country Filter */}
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="countryFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by State
            </label>
            <CustomDropdown
              disable={userdisable}
              onBusinessSelect={handleSelect}
              data={countries}
              url=""
              title="State"
            />
            <div className="absolute left-0 right-0 mt-10"></div>
            {/* <UserDropdown disable={userdisable} onUserSelect={(e)=>setSelectedCountry(e.target.value)} data={countries} url="" /> */}
          </div>

          {/* City Filter */}
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="cityFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by City
            </label>
            {/* <select
            id="cityFilter"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">All</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select> */}
            <CustomDropdown
              disable={userdisable}
              onBusinessSelect={(e) => setSelectedCity(e)}
              data={cities}
              url=""
              title="City"
            />
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="ageFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Age Group
            </label>
            <select
              id="ageFilter"
              value={selectedAgeGroup}
              onChange={(e) => setSelectedAgeGroup(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All</option>
              {ageGroup.map((age) => (
                <option key={age} value={age}>
                  {age}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="MSFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Marital Status
            </label>
            <select
              id="MSFilter"
              value={selectedMS}
              onChange={(e) => setSelectedMS(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All</option>
              {maritalStatus.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 mb-4">
            <label
              htmlFor="genderFilter"
              className="block text-sm font-medium text-gray-700"
            >
              Filter by Gender
            </label>
            <select
              id="genderFilter"
              value={selectedGender}
              onChange={(e) => setSelectedGender(e.target.value)}
              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">All</option>
              {gender.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Bar Graph */}
        <div>
          <h2 className="text-xl font-bold mb-2">User Data Bar Graph</h2>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <div style={{ height: "400px" }}>
              <Bar
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </div>
        </div>
        {/* User Data Table */}
      </div>

      <div className="container mx-auto p-6">
        <div className="flex lg:flex-row md:flex-col sm:flex-col xs:flex-col gap-8">
          {/* Left Section: User Data Table */}
          <div className="flex-1">
            <div className="bg-white rounded-lg shadow-md p-6 overflow-hidden">
              <h2 className="text-xl font-bold mb-2">User Data Table</h2>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {filteredUsers.map((user) =>
                      user.users.map((userInfo, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {userInfo.firstName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {userInfo.lastName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {userInfo.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {userInfo.phone}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Right Section: Pie Charts */}
          <div className="flex lg:flex-col md:flex-row sm:flex-col md:justify-between">
            {/* First Pie Chart */}
            {appointmentStatusData && (
              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <Link to="/appointments" className="text-sm font-bold mb-4 underline" >
                <h2 >
                  Appointment By Status
                </h2>
                </Link>
                <div className="h-64">
                  <Pie data={appointmentStatusData} />
                </div>
              </div>
            )}

            {/* Second Pie Chart */}
            {appointmentStatusByServiceData && (
              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <Link to="/appointments" className="text-sm font-bold mb-4 underline">
                <h2 >
                  Appointment by Service Type
                </h2>
                </Link>
                <div className="h-64">
                  <Pie data={appointmentStatusByServiceData} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row w-[100%] gap-4 mb-4">
        {earningData && (
          <div className="w-[25%] md:w-[25%]">
            <h2>Earning Data info</h2>
            <Pie
              data={earningData}
              options={{
                plugins: {
                  legend: {
                    position: "top", // Position legend on the right side
                  },
                },
              }}
            />
          </div>
        )}

        {EarningchartData && (
          <div className="w-[50%] md:w-[49%]">
            <h2>Earning History</h2>
            <Bar
              data={EarningchartData}
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </div>
        )}

        
{RNuserData && (
          <div className="w-[25%] md:w-[25%]">
            <Link to="/customerinfo" className="text-xl font-bold mb-4 underline" >
            <h2>New And Returning Customers</h2>
            </Link>
            <Pie
              data={RNuserData}
              options={{
                plugins: {
                  legend: {
                    position: "top", // Position legend on the right side
                  },
                },
              }}
            />
          </div>
        )}


        
      </div>
      <div className="flex flex-row w-[100%] gap-4 mb-4">
      <div className="w-[50%] md:w-[49%]">
      {remainingSubs && (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="font-bold">Remaining Subscriptions</h2>
                <Pie
                data={remainingSubs}
                options={{
                    plugins: {
                        legend: {
                            display: true,
                            position: "top",
                        },
                    },
                }}
            />
            </div>
            )}
            </div>
            <div className="w-[50%] md:w-[49%]">
            {ratingChart && (
              <div  className="bg-white rounded-lg shadow-md p-6 mb-8">
              <h2 className="font-bold">Business Rating</h2>
                <Bar
                data={ratingChart}
                options={{
                    indexAxis: "y",
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: "Business",
                            },
                        },
                        x: {
                            title: {
                                display: true,
                                text: "Rating",
                            },
                        },
                    },
                }}
            />
            </div>
            )}
            </div>
        </div>
          <div className="flex flex-row w-[100%]">
          {
              usersCreatedMonthly && (
                <div className="w-[50%] md:w-[49%]">
                <h2 className="text-xl font-bold mb-2">Users Created Monthly</h2>
                <div className="bg-white p-6 rounded-lg shadow-md">
                {usersCreatedMonthly && (
       <Bar
       data={usersCreatedMonthly}
       options={{
         responsive: true,
         plugins: {
           legend: {
             position: 'top',
           },
         },
         scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
         },
       }}
     />
      )}

                </div>
              </div>
              )
            }
            </div>
        <div className="flex flex-row w-[100%] gap-4 mb-4">
        {frequentUsers && (
            <div className="container mx-auto px-4 py-8">
            <h2 className="text-2xl font-bold mb-4">Frequent Customers Table</h2>
            <table className="min-w-full bg-white border border-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">First Name</th>
                        <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {frequentUsers.map((customer, index) => (
                        <tr key={index}>
                            <td className="px-4 py-2 text-sm text-gray-500">{customer.firstName} {customer.lastName}</td>
                            <td className="px-4 py-2 text-sm text-gray-500">
                              <Link to={'/frequentuserdetail'} state={customer.id}>
                              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                               View
                              </button>
                              </Link>

                              </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

            )}
           
        </div>
    </div>
  );
}