import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './passwordchange/content'
import { useLocation } from 'react-router-dom'

export default function PasswordChange() {
    const { state } = useLocation()
  return (
    <Baselayout>
      <Content state={state}/>
    </Baselayout>
  )
}
