import { BrowserRouter, Route, Routes } from "react-router-dom";
import CustomerInfo from "../../pages/CustomerInfo.js";
import Login from "../../pages/loginpage/Login.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import ServiceProviderInfo from "../../pages/ServiceProviderInfo.js";
import BusinessInfo from "../../pages/BusinessInfo.js";
import ServiceProviderDetails from "../../pages/ServiceProviderDetails.js";
import BusinessWorkers from "../../pages/BusinessWorkers.js";
import WorkerEditForm from "../../pages/WorkerEditForm.js";
import Offers from "../../pages/Offers.js";
import Support from "../../pages/Support.js";
import Tickets from "../../pages/Tickets.js";
import TicketDetails from "../../pages/TicketDetails.js";
import Products from "../../pages/Products.js";
import ProductsDetailsView from "../../pages/ProductsDetailsView.js";
import LiveChat from "../../pages/LiveChat.js";
import AddServiceProvider from "../../pages/AddServiceProvider.js";
import BusinessBoost from "../../pages/BusinessBoost.js";
import Appointments from "../../pages/Appointments.js";
import AppointmentDetails from "../../pages/AppointmentDetails.js";
import Aggrement from "../../pages/Aggrement.js";
import SendEmail from "../../pages/SendEmail.js";
import Subscription from "../../pages/Subscription.js";
import CustomerInfoDetails from "../../pages/CustomerInfoDetails.js";
import WorkerInfoDetails from "../../pages/WorkerInfoDetails.js";
import Register from "../../pages/Register.js";
import SetPassword from "../../pages/setpassword/content.jsx";
import OfferDetails from "../../pages/OfferDetails.js";
import SalesAndSupport from "../../pages/SalesAndSupport.js";
import ServiceProviderAggrement from "../../pages/ServiceProviderAggrement.js";
import CustomerInfoEdit from "../../pages/CustomerInfoEdit.js";
import SubscriptionHistory from "../../pages/SubscriptionHistory.js";
import ServiceProviderInfoEdit from "../../pages/ServiceProviderInfoEdit.js";
import Requests from "../../pages/Requests.js";
import ProductEdit from "../../pages/ProductEdit.js";
import EmailLog from "../../pages/EmailLog.js";
import MailDetails from "../../pages/MailDetails.js";
import FrequentUserDetail from "../../pages/FrequentUserDetail.js";
import Notifications from "../../pages/Notifications.js";
import SendNotification from "../../pages/SendNotification.js";
import Notify from "../../pages/Notify.js";
import CustomerAggrement from "../../pages/CustomerAggrement.js";
import  Dashboard  from "../../pages/Dashboard.js";
import NotificationLogs from "../../pages/NotificationLogs.js";
import ForgotPassword from "../../pages/ForgotPassword.js";
import UserProfile from "../../pages/UserProfile.js";
import PasswordChange from "../../pages/PasswordChange.js";
import DeletedUsers from "../../pages/DeletedUsers.js";

export default function AppRoutes() {



  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              TokenService.getAccessToken() === "" ? <Login /> : <Dashboard />
            }
          />
          <Route 
          path="/customerinfo"
          element={<CustomerInfo />}
          />
          <Route
            path="/login"
            element={<Login />} 
          />
          <Route
            path="/serviceproviderinfo"
            element={<ServiceProviderInfo /> }
          />
          <Route
            path="/businessinfo"
            element={<BusinessInfo />} 
          />
          <Route
            path="/serviceproviderdetails"
            element={<ServiceProviderDetails /> }
          />
          <Route
            path="/workerdetails"
            element={<BusinessWorkers />} 
          />
          <Route
            path="/workeredit"
            element={<WorkerEditForm />} 
          />
          <Route
            path="/offers"
            element={<Offers />} 
          />
          <Route
            path="/support"
            element={<Support />} 
          />
          <Route
            path="/tickets"
            element={<Tickets />} 
          />
          <Route
            path="/ticketdetails"
            element={<TicketDetails />} 
          />
          <Route
            path="/products"
            element={<Products />} 
          />
          <Route
            path="/edit-product"
            element={<ProductEdit />} 
          />
          <Route
            path="/productdetails"
            element={<ProductsDetailsView />} 
          />
          <Route
            path="/livechat"
            element={<LiveChat />} 
          />
          <Route
            path="/addserviceprovider"
            element={<AddServiceProvider />} 
          />
          <Route
            path="/businessboost"
            element={<BusinessBoost />} 
          />
          <Route
            path="/appointments"
            element={<Appointments />} 
          />
          <Route
            path="/livechat"
            element={<LiveChat />} 
          />
          <Route
            path="/appointmentdetails"
            element={<AppointmentDetails />}
          />
          <Route
            path="/agreement"
            element={<Aggrement/>} 
          />
          <Route
            path="/sendEmail"
            element={<SendEmail />} 
          />
          <Route
            path="/subscription"
            element={<Subscription />} 
          />
          <Route
            path="/customerinfodetails"
            element={<CustomerInfoDetails />} 
          />
          <Route
            path="/WorkerInfoDetails"
            element={<WorkerInfoDetails />} 
          />
          <Route
            path="/register"
            element={<Register />}
          />
          <Route
            path="/setpassword"
            element={<SetPassword />}
          />
          <Route
            path="/offerdetails"
            element={<OfferDetails />}
          />
          <Route 
            path="/salesandsupport"
            element={<SalesAndSupport />}
          />
          <Route
            path="serviceprovideraggreement"
            element={<ServiceProviderAggrement />}
          />
          <Route
            path="/customerinfoedit"
            element={<CustomerInfoEdit />}
          />
          <Route
            path="/subscriptionhistory"
            element={<SubscriptionHistory />}
          />
          <Route
            path="/serviceproviderinfoedit"
            element={<ServiceProviderInfoEdit />}
          />
          <Route
            path="/requests"
            element={<Requests />}
          />
          <Route
            path="/emaillog"
            element={<EmailLog />}
          />
          <Route 
            path="/maildetails"
            element={<MailDetails />}
          />
          <Route 
            path="/sendnotification"
            element={<SendNotification />}
          />
          <Route 
            path="/notify"
            element={<Notify />}
          />
          <Route 
            path="/frequentuserdetail"
            element={<FrequentUserDetail />}
          />
          <Route 
            path="/notifications"
            element={<Notifications />}
          />
          <Route 
            path="/customeraggreement"
            element={<CustomerAggrement />}
          />
          <Route 
            path="/notificationlogs"
            element={<NotificationLogs />}
          />
          <Route 
            path="/forgetpassword"
            element={<ForgotPassword />}
          />
          <Route 
            path="/userprofile"
            element={<UserProfile />}
          />
          <Route
            path="/passwordchange"
            element={<PasswordChange />}
          />
          <Route
            path="/deletedusers"
            element={<DeletedUsers />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}


