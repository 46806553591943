import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { DELETE, GET, POST, PUT } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import { TokenService } from "../../utils/TokenService.ts";
import Modal from "react-modal";
import { getAuth } from "firebase/auth";
import { set } from "react-hook-form";
import Loader from "../../components/Loader.jsx";

const ResultsPerPage = 10;

export default function Content() {
  const [activeTab, setActiveTab] = useState("tab1");
  const [users, setUsers] = useState([]);
  const [searchUser, setSearchUser] = useState('');
  const [filterUsers, setFilterUsers] = useState([]);
  const [request, setRequest] = useState([]);
  const [searchRequest, setSearchRequest] = useState('');
  const [filterRequest, setFilterRequest] = useState([]);
  const [reject, setReject] = useState([]);
  const [searchReject, setSearchReject] = useState('');
  const [filterReject, setFilterReject] = useState([]);
  const [rejectMessage, setRejectMessage] = useState('');
  const [rejectID, setRejectID] = useState('');
  const [loading, setLoading] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rej_currentPage, setRej_CurrentPage] = useState(1);
  const [req_currentPage, setReq_CurrentPage] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userId, setUserId] = useState(0);
  const [description, setDescription] = useState('');

  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;
  let rej_startIndex = (rej_currentPage - 1) * ResultsPerPage;
  let rej_endIndex = rej_currentPage * ResultsPerPage;
  let req_startIndex = (req_currentPage - 1) * ResultsPerPage;
  let req_endIndex = req_currentPage * ResultsPerPage;
  const auth = getAuth();
  const user = auth.currentUser?.uid;
  // console.log(user)
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };



  // console.log(email, password);
  useEffect(() => {


    const handleBusinessList = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/User/GetRegisteredUsers", {
        Authorization: 'Bearer' + TokenService.getAccessToken('access_token')
      });

      if (res.status === 'success') {
        let filteredUsers = res.data.filter(
          (user) => user.business !== null && user.status !== 'Rejected' && user.role?.id === 4 // Filter users with non-empty monthlyIncome
        );
        console.log(filteredUsers);
        if(TokenService.getUserRole() === 4){
          filteredUsers = filteredUsers.filter(
            (user) => user.id === TokenService.getUser().user.id)
          
        }
        setUsers(filteredUsers); // Update users state with filtered data
        setFilterUsers(filteredUsers);
        setLoading(false);
      }
      else {
        alert(res.message);
        setLoading(false);
      }

    }

    const handleBusinessReq = async () => {
      setReqLoading(true);
      const res = await GET(BASE_URL + "api/User/GetUnregisteredUsers",
        { Authorization: 'Bearer' + TokenService.getAccessToken('access_token') });
      if (res.status === 'success') {
        // console.log(res.data);
        setRequest(res.data);
        setReqLoading(false);
        setFilterRequest(res.data);
      }
      else {
        alert(res.message);
        setReqLoading(false);
      }
    }

    const handleRejectedReq = async () => {
      setRejectLoading(true);
      const res = await GET(BASE_URL + "api/User/GetRejectedUsers",
        { Authorization: 'Bearer' + TokenService.getAccessToken('access_token') });
      if (res.status === 'success') {
        // console.log(res.data);
        setReject(res.data);
        setFilterReject(res.data);
        setRejectLoading(false);
      }
      else {
        alert(res.message);
        setRejectLoading(false);
      }
    }

    handleBusinessList()
    handleBusinessReq()
    handleRejectedReq()
  }, []);

  
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '30%',
    },
  };


  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  const handleApprove = async (id) => {
    // console.log('approve',id);
    const res = await POST(BASE_URL + `api/User/RegisterUser?UserId=${id}`, {
      Authorization: 'Bearer' + TokenService.getAccessToken('access_token')
    });
    if (res.status === 'success') {
      console.log(res.data);
    }
  }

  const handleRejectModal = (id) => {
    setIsOpen(true);
    console.log('reject', id);
    setRejectID(id);
  }

  const handleReject = async () => {
    console.log('asdfasdf', rejectID);
    console.log('rejeasdfasdfct', rejectMessage);
    setIsOpen(false);
    const res = await POST(BASE_URL + `api/User/RejectUser?UserId=${rejectID}&message=${rejectMessage}`, {
      Authorization: 'Bearer' + TokenService.getAccessToken('access_token')
    });
    if (res.status === 'success') {
      console.log(res.data);
    }
  }

  const handleSearch = (event) => {
    console.log(event.target.value);
    const searchText = event.target.value.toLowerCase();
    setSearchUser(searchText);

    const filtered = users.filter((user) => {
        const email = user?.business?.email.toLowerCase();
        const phone = user.business?.phone.toLowerCase();
        const status = user.status.toLowerCase();
        const title = user?.business?.title.toLowerCase();
        const income = user.monthlyIncome.toLowerCase();

        return email.includes(searchText) || phone.includes(searchText) || status.includes(searchText) || title.includes(searchText) || income.includes(searchText);

        // // return fullName.includes(s_text);
        // return  status.includes(s_text);
    });
    setFilterUsers(filtered);
};

const handleSearchRequest = (event) => {
  console.log(event.target.value);
  const searchText = event.target.value.toLowerCase();
  setSearchRequest(searchText);

  const filtered = users.filter((user) => {
      const email = user?.business?.email.toLowerCase();
      const phone = user.business?.phone.toLowerCase();
      const status = user.status.toLowerCase();
      const title = user?.business?.title.toLowerCase();
      const income = user.monthlyIncome.toLowerCase();

      return email.includes(searchText) || phone.includes(searchText) || status.includes(searchText) || title.includes(searchText) || income.includes(searchText);

      // // return fullName.includes(s_text);
      // return  status.includes(s_text);
  });
  setFilterRequest(filtered);
};

const handleSearchReject = (event) => {
  console.log(event.target.value);
  const searchText = event.target.value.toLowerCase();
  setSearchReject(searchText);

  const filtered = users.filter((user) => {
      const email = user?.business?.email.toLowerCase();
      const phone = user.business?.phone.toLowerCase();
      const status = user.status.toLowerCase();
      const title = user?.business?.title.toLowerCase();
      const income = user.monthlyIncome.toLowerCase();

      return email.includes(searchText) || phone.includes(searchText) || status.includes(searchText) || title.includes(searchText) || income.includes(searchText);

      // // return fullName.includes(s_text);
      // return  status.includes(s_text);
  });
  setFilterReject(filtered);
};

const handleStatusChange = async () => {
  console.log('userId', userId);
  const res = await DELETE(BASE_URL + "api/User/deleteUser", {
    userId: userId,
    note: description
  });
  if (res.status === 'success') {
    setDeleteModal(false);
    alert('business deleted successfully');
    window.location.reload();
  }
  else {
    alert(res.message);
  }
}




  return (
    <div>
      <div className="flex flex-row items-center justify-between sm:px-6 mb-6">
        {
          (TokenService.getUserRole() === 1) && (
              <div className="flex flex-row items-center  gap-3 bg-gray-300 px-2 py-2 rounded-md">
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  Approved
                </button>
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab2")}
                >
                  Pending
                </button>
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab3" ? "bg-primary !text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab3")}
                >
                  Rejected
                </button>
              </div>

            )
        }
        {
          (TokenService.getUserRole() === 6 || TokenService.getUserRole() ===1 ) && (

        <Link to="/addserviceprovider">
          <div className="flex ">
            <button
              className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md bg-primary text-white`}
            >
              Add
            </button>
          </div>
        </Link>
          )
        }
      </div>

      {/* Approved users tab */}
      <div
        className=" sm:px-6 mx-auto"
        id="tab1"
        style={{ display: activeTab === "tab1" ? "block" : "none" }}
      >
        {loading ?
          <Loader />
          :
          <div className="flex flex-col">
                      <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchUser}
              onChange={handleSearch}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        All Business
                      </h2>
                    </div>

                    <div></div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Phone
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Address
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Subscriptions
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>
                        {
                          (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                            <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Action
                                </span>
                              </div>
                            </th>

                          )
                        }
                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 ">
                      {filterUsers.slice(startIndex, endIndex).map((user, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to="/serviceproviderdetails" state={user.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {user?.business?.title}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {user?.business?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                              {
                                user.status === 'Active' ?
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                    <CheckCircleIcon className="text-teal-500" fontSize="12" />
                                    {user.status}
                                  </span>
                                  :
                                  <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-red-100 text-red-800 rounded-full ">
                                    <CancelIcon className="text-red-500" fontSize="12" />
                                    {user.status}
                                  </span>

                              }
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user?.business?.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user?.business?.address} {user?.business?.zipCode}
                              </span>
                            </div>
                          </td>


                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              {user?.business?.businessServices?.map((element, index) => {
                                // console.log(element , index);
                                return (<span key={index} className="text-xs text-white bg-primary p-2 rounded-full">
                                  {element.serviceType.name}
                                </span>)
                              })}
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user?.createdAt)}
                              </span>
                            </div>
                          </td>
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                  <Link to="/serviceproviderinfoedit" state={user.id}
                                  >
                                    <EditIcon className="text-primary hover:text-black" />
                                  </Link>

                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    onClick={() => {setDeleteModal(true); setUserId(user.id)}}
                                  >
                                    <DeleteIcon className="text-primary hover:text-black" />
                                  </a>
                                </div>
                              </td>
                            )
                          }

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-2">
                              <Link state={user.id} to={"/serviceproviderdetails"}>
                                <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                                  View Details
                                </span>
                              </Link>
                              {/* <Link to="/workerdetails">
                            <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                              View Workers
                            </span>
                          </Link> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterUsers.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(users.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(users.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* Pending users tab */}
      <div
        className=" sm:px-6 mx-auto"
        id="tab2"
        style={{ display: activeTab === "tab2" ? "block" : "none" }}
      >
        {reqLoading ?
          <Loader />
          :
          <div className="flex flex-col">
             <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchRequest}
              onChange={handleSearchRequest}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        Business Request
                      </h2>
                    </div>

                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Phone
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Address
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Subscriptions
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                        {
                          (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
                            <th scope="col" className="px-6 py-3 text-end">
                              <div className="flex items-center gap-x-2">
                                <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                                  Action
                                </span>
                              </div>
                            </th>

                          )
                        }
                      </tr>
                    </thead>


                    <tbody className="divide-y divide-gray-200 ">
                      {request.slice(req_startIndex, req_endIndex).map((req, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to="/serviceproviderdetails" state={req?.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {req?.business?.title}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {req?.business?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {req?.business?.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {req?.business?.address} {req?.business?.zipCode}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              {/* <span className="text-xs text-white bg-primary p-2 rounded-full">
                            Towing
                          </span> */}
                              {req?.business?.businessServices?.map((element, index) => {
                                // console.log(element , index);
                                return (<span key={index} className="text-xs text-white bg-primary p-2 rounded-full">
                                  {element.serviceType.name}
                                </span>)
                              })}
                            </div>
                          </td>



                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(req?.business?.createdAt)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-2">
                              <Link state={req.id} to={"/serviceproviderdetails"}>
                                <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                                  View Details
                                </span>
                              </Link>
                            </div>
                          </td>
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (

                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    href="#"
                                    onClick={value => handleApprove(req?.id)}
                                  >
                                    <CheckCircleIcon className="text-primary hover:text-black" />
                                  </a>
                                  <a
                                    className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                    href="#"
                                    onClick={value => handleRejectModal(req?.id)}
                                  >
                                    <CancelIcon className="text-primary hover:text-black" />
                                  </a>
                                </div>
                              </td>
                            )
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterRequest.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setReq_CurrentPage(req_currentPage - 1)}
                        disabled={req_currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {req_currentPage} of {Math.ceil(request.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setReq_CurrentPage(req_currentPage + 1)}
                        disabled={req_currentPage === Math.ceil(request.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* Rejected users tab */}
      <div
        className=" sm:px-6 mx-auto"
        id="tab3"
        style={{ display: activeTab === "tab3" ? "block" : "none" }}
      >
        {rejectLoading ?
          <Loader />
          :
          <div className="flex flex-col">
             <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchReject}
              onChange={handleSearchReject}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        Rejected Request
                      </h2>
                    </div>

                    <div></div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th
                          scope="col"
                          className="ps-6 lg:ps-3 px-6 py-3 text-start"
                        >
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Phone
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Address
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Subscriptions
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Created
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Reason
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {reject.slice(rej_startIndex, rej_endIndex).map((req, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <img
                                  className="inline-block size-[38px] rounded-full"
                                  src="images/image-placeholder.jpg"
                                  alt="Image Description"
                                />
                                <div className="grow">
                                  <Link to="/serviceproviderdetails" state={req?.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {req?.business?.title}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {req?.business?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {req?.business?.phone}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {req?.business?.address} {req?.business?.zipCode}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-3">
                              {/* <span className="text-xs text-white bg-primary p-2 rounded-full">
                            Towing
                          </span> */}
                              {req?.business?.businessServices?.map((element, index) => {
                                // console.log(element , index);
                                return (<span key={index} className="text-xs text-white bg-primary p-2 rounded-full">
                                  {element.serviceType.name}
                                </span>)
                              })}
                            </div>
                          </td>


                          {/* <td className="size-px whitespace-nowrap">
                        <div className="px-6 py-3 flex flex-row gap-3">
                            {
                              req.businessServices.map((sub) => (
                                <span className="text-xs text-white bg-primary p-2 rounded-full">
                                  {sub.serviceType.name}
                                </span>

                              ))
                            }
                        </div>
                      </td> */}
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(req?.business?.createdAt)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {req?.rejectedMessage}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3 flex flex-row gap-2">
                              <Link state={req.id} to={"/serviceproviderdetails"}>
                                <span className="bg-primary text-white text-sm px-3 py-2 rounded-md cursor-pointer">
                                  View Details
                                </span>
                                
                              </Link>
                            </div>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterReject.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setRej_CurrentPage(rej_currentPage - 1)}
                        disabled={rej_currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {rej_currentPage} of {Math.ceil(reject.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setRej_CurrentPage(rej_currentPage + 1)}
                        disabled={rej_currentPage === Math.ceil(reject.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById('your-app-element-id')}
      >

        <h3 className="text-primary py-2 text-xl">Reason</h3>
        <textarea
          className="border p-5 rounded-md w-full h-[250px]"
          value={rejectMessage}
          onChange={(event) => setRejectMessage(event.target.value)} // Corrected closing parenthesis
        />
        <div className="flex justify-end items-center gap-x-2 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none " onClick={()=>setIsOpen(false)} data-hs-overlay="#hs-notifications">
            Cancel
          </button>
          <a className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none" href="#" onClick={handleReject}>
            Reject
          </a>
        </div>
      </Modal>

      <Modal
            isOpen={deleteModal}
            onRequestClose={() => setDeleteModal(false)}
            style={customStyles}
            appElement={document.getElementById('modal')}
          >
           
                <div className="flex flex-col items-center gap-5 px-4 ">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you delete the user?</h2>
                  {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
  
                  <textarea
                    className="w-full p-2 min-h-20 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
    

                <div className="flex justify-center items-center gap-x-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setDeleteModal(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              </div>
              

             
          </Modal>
    </div>
  );
}
