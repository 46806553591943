import axios, { AxiosError, AxiosInstance, AxiosRequestConfig , InternalAxiosRequestConfig, AxiosResponse } from "axios";

/* @internal */
import {BASE_URL} from "./config"
import { TokenService } from "./TokenService.ts";

class Request {
   baseURL: any;
   isRefreshing: boolean;
   failedRequests: any[];
   tokenService: typeof TokenService;
   client: AxiosInstance;
   constructor() {
       this.baseURL = BASE_URL;
       this.isRefreshing = false;
       this.failedRequests = [];
       this.tokenService = TokenService;
       this.client = axios.create({
           baseURL: this.baseURL,
       });
       this.beforeRequest = this.beforeRequest.bind(this);
       this.onRequestFailure = this.onRequestFailure.bind(this);
       this.processQueue = this.processQueue.bind(this);
       this.client.interceptors.request.use(this.beforeRequest);
       this.client.interceptors.response.use(this.onRequestSuccess, this.onRequestFailure);
}

beforeRequest(request: InternalAxiosRequestConfig) {
   const token = this.tokenService.getAccessToken();
   request.headers = request.headers ?? {};
   request.headers.Authorization = `Bearer ${token}`;
   return request;
}

onRequestSuccess(response: AxiosResponse) {
   return response;
}

async onRequestFailure(err:any) {
   console.log(err);
   const { response, code } = err;
   if ((response.status === 401 || code === "ERR_NETWORK") && err && err.config && !err.config.__isRetryRequest) {
       if (this.isRefreshing) {
           try {
               const token = await new Promise((resolve, reject) => {
                   this.failedRequests.push(({ resolve, reject }));
               });
               err.config.headers.Authorization = `Bearer ${token}`;
               return this.client(err.config);
           }
           catch (e) {
               return e;
           }
       }
       this.isRefreshing = true;
       err.config.__isRetryRequest = true;
       return new Promise((resolve, reject) => {
           this.tokenService.refreshAccessToken().then((token:string) => {
               this.tokenService.setAccessToken(token);
               err.config.headers.Authorization = `Bearer ${token}`;
               this.isRefreshing = false;
               this.processQueue(null, token);
               resolve(this.client(err.config));
           }).catch((e:AxiosError) => {
               this.processQueue(e, null);
               reject(err.response);
           });
       });
   }
   throw response;
}

processQueue(error:AxiosError | null, token:string|null = null) {
   this.failedRequests.forEach((prom) => {
       if (error) {
           prom.reject(error);
       } else {
           prom.resolve(token);
       }
      });
       this.failedRequests = [];
   }

}

const request = new Request();

export default request.client;