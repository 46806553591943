import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './requests/content'

export default function Requests() {
  return (
    <Baselayout>
        <Content/>
    </Baselayout>
  )
}
