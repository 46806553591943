import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./serviceprovideraggreement/content";

export default function ServiceProviderAggrement() {
  return (
    <Baselayout>
      <Content />
    </Baselayout>
  );
}
