import React, { useState, useEffect } from "react";
import { GET, PUT } from "../../utils/ApiRequestProvider.tsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BASE_URL } from "../../utils/config.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import Modal from 'react-modal';
import Loader from "../../components/Loader.jsx";
import { set } from "react-hook-form";

const ResultsPerPage = 10;

export default function Content() {
  const [users, setUsers] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalUserIsOpen, setModalUserIsOpen] = useState(false);
  const [changesModal, setChangesModal] = useState(false);
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [currentPage, setCurrentPage] = useState(1);
  const [changes, setChanges] = useState([]);
  const [r_Id, setR_Id] = useState(0);
  const [role, setRole] = useState('');
  const [data, setData] = useState({
    requestId: 0,
    approvedById: 0,
    requestStatus: '',
    note: ''
  });
  const [updateData, setUpdateData] = useState({
    requestId: 0,
    approvedById: 0,
    requestStatus: ''});
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '30%',
    },
  };

  const changesModalStyles = {
    content: {
      top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '10px',
        maxWidth:'50%',
        maxHeight: '80vh',
    },
  };

  let newData;
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
 
  
  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID

  useEffect(() => {

    const UserList = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/Business/getBusinessUpdateInfo");
      if (res.status === 'success') {
        setUsers(res.data);
        setLoading(false);
      }
      else{
        alert(res.message);
        setLoading(false);
      }
    };

    const CustomerList = async () => {
      setLoading(true);
      const res = await GET(BASE_URL + "api/User/getUserUpdateInfo");
      if (res.status === 'success') {
        setCustomer(res.data); // Update users state with filtered data
        // console.log(res.data);
        setLoading(false);
      }
      else{
        alert(res.message);
        setLoading(false);
      }
    };
  
    UserList();
    CustomerList();
  }, []);

  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };
  
  const handleUpdate = async (status) => {

    console.log("newData", status);
    if (status === 'Rejected') {
      const res = await PUT(BASE_URL + "api/Business/verifyUpdateBusinessProfile", {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
          requestId: data.requestId,
          approvedById: TokenService.getUser().user.id,
          requestStatus: data.requestStatus,
          note: note
      });
      if (res.status === 'success') {
        alert('Request Updated Successfully');
        window.location.reload();
      }

    }
    else if(status === 'Approved') {
      const res = await PUT(BASE_URL + "api/Business/verifyUpdateBusinessProfile", {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
          requestId: newData.requestId,
          approvedById: TokenService.getUser().user.id,
          requestStatus: newData.requestStatus,
          note: ''
      });
      if (res.status === 'success') {
        alert('Request Updated Successfully');
        window.location.reload();
      }
    }
  }

  const handleUserUpdate = async (status) => {

    console.log(data);
    console.log("newData", newData, note);
    if (status === 'Rejected') {
      const res = await PUT(BASE_URL + "api/User/approvalUserinfoUpdate", {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
          requestId: data.requestId,
          approvedById: TokenService.getUser().user.id,
          requestStatus: data.requestStatus,
          note: note
      });
      if (res.status === 'success') {
        alert('Request Updated Successfully');
        window.location.reload();
      }

    }
    else {
      const res = await PUT(BASE_URL + "api/User/approvalUserinfoUpdate", {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
          requestId: newData.requestId,
          approvedById: TokenService.getUser().user.id,
          requestStatus: newData.requestStatus,
          note: ''
      });
      if (res.status === 'success') {
        alert('Request Updated Successfully');
        window.location.reload();
      }
    }
  }

  const handleModal = (r_id, status, role) => {
    console.log("request id:",r_id);
    console.log("status:",status);
    console.log("role:",role);
    setR_Id(r_id);

    if (role === 'business') {

      if (status === "Approved") {
        newData = {
          requestId: r_id,
          requestStatus: 'Approved',
        };
        handleUpdate("Approved");
      }
       else if (status === "Rejected"){
        console.log("rejected");
        setData({
          requestId: r_id,
          requestStatus: 'Rejected',
        })
        setModalIsOpen(true);
      }
    }
    else if (role === 'user') {
      if (status === 'Approved') {
        newData = {
          requestId: r_id,
          requestStatus: 'Approved',
        };
        handleUserUpdate('Approved');

      } else if (status === 'Rejected') {
        setData({
          requestId: r_id,
          requestStatus: 'Rejected',
        })
        setModalUserIsOpen(true);
      }
    }
    setChangesModal(false);
  }

  const handleUserModal = (r_id, status) => {

    
    setChangesModal(false);
  }

    let currentInfo = '';
  const handleChangesModal = async (data, role) => {

    if (role === 'user') {
      setR_Id(data);
      const res = await GET(BASE_URL + "api/User/getUserInfoUpdateById?userUpdateId=" + data);
      console.log('currentInfo', res.data.currentInfo);
      console.log('reequested Changes', res.data.requestedChanges);
      currentInfo = res.data.currentInfo;
      delete res.data.currentInfo;
      compareObjects(res.data.requestedChanges, currentInfo);
      setChangesModal(true);
      setRole('user');
    }
    else if (role === 'business') {
      setR_Id(data);
      const res = await GET(BASE_URL + "api/Business/getBusinessUpdateById?userUpdateId=" + data);
      console.log('currentInfo', res.data.currentInfo);
      console.log('requested Changes', res.data.requestedChanges);
      currentInfo = res.data.currentInfo;
      delete res.data.currentInfo;
      compareObjects(res.data.requestedChanges, currentInfo);
      setChangesModal(true);
      setRole('business');
    }
  }

    function compareObjects(obj1, obj2, path = '') {
      const newChanges = [];
      for (const key in obj1) {
        const newPath = (path ? path + '.' : '') + key;
        
          if (obj1[key] !== obj2[key]) {
            console.log(`Change detected at ${newPath}: '${obj2[key]}' => '${obj1[key]}'`);
            newChanges.push({ path: newPath, oldValue: obj2[key], newValue: obj1[key] });
          }
        
      }
      setChanges(newChanges);
    }
    

    function ChangesModal({ changes }) {
      return (
        <div className="modal">
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="flex items-center justify-end">
              <button
                className="text-gray-500 hover:bg-gray-500 rounded-full p-1 hover:text-white"
                onClick={() => setChangesModal(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <h2 className="text-md text-primary text-center font-bold pb-2">Changes Detected</h2>
              <div  className="flex flex-row items-start gap-2 py-2">
                <div className="overflow-x-auto border rounded-xl">
                 <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Description
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Previous
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Change
                            </span>
                          </div>
                        </th>

                       

                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {changes.slice(startIndex, endIndex).map((change, index) => (
                        <tr key={index}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">

                                <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                  {change.path.toUpperCase()}
                                </span>


                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-green-800 gap-x-1 text-xs font-medium bg-green-200">
                                {change.oldValue}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                            <span className="py-1 px-3 inline-flex items-center rounded-full text-red-800 gap-x-1 text-xs font-medium bg-red-200">
                                {change.newValue}
                              </span>
                            </div>
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
             
              </div>
            {changes.length === 0 && <p>No changes detected.</p>}
          </div>
        </div>
      );
    }
    
    
    
    
    



  return (
    <div>
      {loading ?
        <Loader />
        : 
      <div>
        <div className="flex flex-row items-center sm:px-6 mb-6">
          <div className="flex flex-row items-center  gap-3 bg-gray-300 px-2 py-2 rounded-md">
            <button
              className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                }`}
              onClick={() => handleTabClick("tab1")}
            >
              Business
            </button>
            <button
              className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                }`}
              onClick={() => handleTabClick("tab2")}
            >
              User
            </button>
          </div>
        </div>
        {/* Business Requests tab */}
        <div
          className=" sm:px-6 mx-auto"
          id="tab1"
          style={{ display: activeTab === "tab1" ? "block" : "none" }}
        >
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        Business Requests
                      </h2>
                    </div>

                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Description
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Requested By
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Requested Date
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>

                        {/* <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Action
                            </span>
                          </div>
                        </th> */}

                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {users.slice(startIndex, endIndex).map((user) => (
                        <tr key={user.id}>

                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">

                                <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                  {user.forBusiness?.title}
                                </span>


                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                            {user.requestStatus === 'Approved' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-green-800 gap-x-1 text-xs font-medium bg-green-200">
                                {user.requestStatus}
                              </span>
                            }
                            {user.requestStatus === 'Pending' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-yellow-800 gap-x-1 text-xs font-medium bg-yellow-200">
                                {user.requestStatus}
                              </span>
                            }
                            {user.requestStatus === 'Rejected' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-red-800 gap-x-1 text-xs font-medium bg-red-200">
                                {user.requestStatus}
                              </span>
                            }
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium">
                                {user.description}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.requestedBy?.firstName} {user.requestedBy?.lastName}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.requestedDate)}
                              </span>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                          {user.requestStatus === 'Pending' &&
                            <div className="px-6 py-3">
                              <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                                onClick={(e)=>handleChangesModal(user.id, 'business')}
                                >
                                View
                              </button>
                            </div>
                          }
                          </td>

                          {/* <td className="size-px whitespace-nowrap">
                          {user.requestStatus === 'Pending' &&
                            <div className="px-6 py-1.5 flex gap-3">
                              <a onClick={() => handleModal(user.id, "Approved", 'business')}
                                className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                              >
                                <CheckIcon className="text-primary hover:text-black" />
                              </a>
                              <a onClick={() => handleModal(user.id, 'Rejected', 'business')}
                                className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                              >
                                <CloseIcon className="text-primary hover:text-black" />
                              </a>
                            </div>
                          }
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{users.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex items-center gap-x-2">
                        <button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Previous
                        </button>
                        <span className="text-sm text-gray-800 ">
                          {currentPage} of {Math.ceil(users.length / ResultsPerPage)}
                        </span>
                        <button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={currentPage === Math.ceil(users.length / ResultsPerPage)}
                          className="disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Next
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Customer Requests tab */}
        <div
          className=" sm:px-6 mx-auto"
          id="tab2"
          style={{ display: activeTab === "tab2" ? "block" : "none" }}
        >
          <div className="flex flex-col">
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        Customer Requests
                      </h2>
                    </div>

                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Status
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Description
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Requested By
                            </span>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Requested Date
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>

                        {/* <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Action
                            </span>
                          </div>
                        </th> */}

                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {customer.slice(startIndex, endIndex).map((user) => (
                        <tr key={user.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex items-center gap-x-3">

                                <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                  {user.forUser?.firstName} {user.forUser?.lastName}
                                </span>


                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                            {user.requestStatus === 'Approved' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-green-800 gap-x-1 text-xs font-medium bg-green-200">
                                {user.requestStatus}
                              </span>
                            }
                            {user.requestStatus === 'Pending' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-yellow-800 gap-x-1 text-xs font-medium bg-yellow-200">
                                {user.requestStatus}
                              </span>
                            }
                            {user.requestStatus === 'Rejected' &&
                              <span className="py-1 px-3 inline-flex items-center rounded-full text-red-800 gap-x-1 text-xs font-medium bg-red-200">
                                {user.requestStatus}
                              </span>
                            }

                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium">
                                {user.description}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.requestedBy?.firstName} {user.requestedBy?.lastName}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {formatDate(user.requestedDate)}
                              </span>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                          {user.requestStatus === 'Pending' &&
                            <div className="px-6 py-3">
                              <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                                onClick={(e)=>handleChangesModal(user.id,'user')}
                                >
                                View
                              </button>
                            </div>
                          }
                          </td>


                          {/* <td className="size-px whitespace-nowrap">
                            {user.requestStatus !== 'Pending' &&
                              <div className="px-6 py-1.5 flex gap-3">
                                <a onClick={() => handleUserModal(user.id, 'Approved')}
                                  className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                >
                                  <CheckIcon className="text-primary hover:text-black" />
                                </a>
                                <a onClick={() => handleUserModal(user.id, 'Rejected')}
                                  className="inline-flex items-center gap-x-1 text-sm text-blue-600 decoration-2 hover:underline font-medium "
                                >
                                  <CloseIcon className="text-primary hover:text-black" />
                                </a>

                              </div>
                            }
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div className="flex flex-row items-center justify-between">
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{customer.length}</span>{" "}
                        results
                      </p>
                      
                    </div>

                    <div className="flex items-center gap-x-2">
                        <button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                          className="disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Previous
                        </button>
                        <span className="text-sm text-gray-800 ">
                          {currentPage} of {Math.ceil(customer.length / ResultsPerPage)}
                        </span>
                        <button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={currentPage === Math.ceil(customer.length / ResultsPerPage)}
                          className="disabled:opacity-50 disabled:pointer-events-none"
                        >
                          Next
                        </button>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <Modal
        isOpen={changesModal}
        onRequestClose={() => setChangesModal(false)}
        style={changesModalStyles}
        appElement={document.getElementById('your-app-element-id')}
      >
        <ChangesModal changes={changes} />

        <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => handleModal(r_Id, 'Rejected', role )} data-hs-overlay="#hs-notifications">
            Reject
          </button>
          <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => handleModal(r_Id, 'Approved', role)}
            >
            Approve
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalUserIsOpen}
        onRequestClose={() => setModalUserIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById('your-app-element-id')}
      >
         <div className="flex flex-row items-end">
          <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" onClick={() => setModalUserIsOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <h2 className="text-lg font-semibold text-gray-800 text-center">Cancel Request</h2>
        <div className="p-4">
          <label className="block text-sm font-medium text-gray-700">Note</label>
          <textarea
            rows="3"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
            placeholder="Enter Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>

        <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => setModalUserIsOpen(false)} data-hs-overlay="#hs-notifications">
            Cancel
          </button>
          <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => handleUserUpdate('Rejected')}>
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-row items-end">
          <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" onClick={() => setModalIsOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <h2 className="text-lg font-semibold text-gray-800 text-center">Cancel Request</h2>
        <div className="p-4">
          <label className="block text-sm font-medium text-gray-700">Note</label>
          <textarea
            rows="3"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm"
            placeholder="Enter Note"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          ></textarea>
        </div>

        <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
            Cancel
          </button>
          <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => handleUpdate('Rejected')}>
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
}