import React, { children } from "react";
import Header from "../header";
import SideBar from "../sidebar";

export default function Baselayout({ children }) {
  return (
    <div>
      <Header />
      <SideBar />
      <div className="w-full pt-10 md:max-w-[84%] m-auto mr-0 p-5">
        {children}
      </div>
    </div>
  );
}
