import React, { useEffect, useState } from 'react'
import { GET, PUT } from '../../utils/ApiRequestProvider.tsx';
import { BASE_URL } from '../../utils/config.jsx';
import { Link } from 'react-router-dom';
import { TokenService } from '../../utils/TokenService.ts';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../components/Loader';
import Modal from 'react-modal';

export default function Content() {
    const ResultsPerPage = 10;
    const [activeTab, setActiveTab] = useState("tab1");
    const [deletedUsers, setDeletedUsers] = useState([]);
    const [flagedUsers, setFlagedUsers] = useState([]);
    const [filterdeleted, setFilterdeleted] = useState([]);
    const [filterflaged, setFilterflaged] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [searchFlagText, setSearchFlagText] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let startIndex = (currentPage - 1) * ResultsPerPage;
    let endIndex = startIndex + ResultsPerPage;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: 'none',
        },
    };

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };


    useEffect(() => {

        const getDeletedUsers = async () => {
            const res = await GET(BASE_URL + 'api/User/getDeletedUsers');
            if (res.status === "success") {
                setDeletedUsers(res.data);
                setFilterdeleted(res.data);
            }
            else {
                console.error('Error fetching deleted users:', res.message);
            }
        }

        const getFlagedUsers = async () => {
            const res = await GET(BASE_URL + 'api/User/getFlaggedUsers');
            if (res.status === "success") {
                setFlagedUsers(res.data);
                setFilterflaged(res.data);
            }
            else {
                console.error('Error fetching flaged users:', res.message);
            }
        }

        getDeletedUsers();
        getFlagedUsers();
    }, []);

    const handleSearch = (e) => {
        setSearchText(e.target.value);
        if (e.target.value) {
            const filteredData = deletedUsers.filter((user) => {
                return user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    user.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    user.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    user.role.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    user.note.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setFilterdeleted(filteredData);
        } else {
            setFilterdeleted(deletedUsers);
        }
    }

    const handleView = (roleId) => {
        if (roleId === 3) {
            return '/customerinfodetails';
        } else if (roleId === 4) {
            return '/serviceproviderdetails';
        } else if (roleId === 5) {
            return '/workerdetails';
        } else if (roleId === 6 || roleId === 7) {
            return '/suppportandsales';
        }
            
    }

    const handleStatusChange = async () => {
            const res = await PUT(BASE_URL + 'api/User/activateFlagOrDeletedUser?userId=' + userId);
            if (res.status === "success") {
                setModalIsOpen(false);
                alert('User status changed successfully');
                window.location.reload();
            }
            else {
                console.error('Error changing user status:', res.message);
            }
    }

    const handleSearchFlagged = (e) => {
        setSearchFlagText(e.target.value);
        if (e.target.value) {
            const filteredData = flagedUsers.filter((user) => {
                return user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||

                    user.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    user.email.toLowerCase().includes(e.target.value.toLowerCase()) ||  
                    user.role.name.toLowerCase().includes(e.target.value.toLowerCase()) ||  
                    user.note.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setFilterflaged(filteredData);
        } else {
            setFilterflaged(flagedUsers);
        }
    }

    Modal.setAppElement(document.getElementById('modal')); 




  

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) ?  (
        <div className=" sm:px-6  mx-auto">
            <div className="flex flex-row items-center w-fit  gap-3 bg-gray-300 px-2 py-2 rounded-md">
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab1" ? "bg-primary text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab1")}
                >
                  Deleted
                </button>
                <button
                  className={`hover:bg-primary px-7 py-2 hover:text-white  rounded-md ${activeTab === "tab2" ? "bg-primary !text-white" : ""
                    }`}
                  onClick={() => handleTabClick("tab2")}
                >
                    Flagged
                </button>
                
              </div>
        
          <div className="flex flex-col"
            id="tab1"
            style={{ display: activeTab === "tab1" ? "block" : "none" }}>
                  <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">Deleted Users</h2>
                    </div>

                    <div></div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Role
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Reason
                            </span>
                          </div>
                        </th>
                        

                        {
                     ( TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) &&(
                    <th scope="col" className="px-6 py-3 text-end">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Action
                        </span>
                      </div>
                    </th>

                      )
                    }

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {filterdeleted.slice(startIndex, endIndex).map((user) => (
                        <tr key={user.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex flex-col items-start gap-x-3">
                                  <Link to='/customerinfodetails' state={user.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {user.firstName} {user.lastName}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {user.email}
                                  </span>
                              </div>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.role?.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.note}
                              </span>
                            </div>
                          </td>
                         
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (


                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium " onClick={()=>{setUserId(user.id); setModalIsOpen(true)}}>
                                    Activate
                                  </button>

                                </div>
                              </td>
                            )
                          }
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to={handleView(user?.roleId)} state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium ">
                                  View
                                </button>
                              </Link>
                            </div>
                          </td>
  
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterdeleted.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(filterdeleted.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filterdeleted.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col"
            id="tab2"
            style={{ display: activeTab === "tab2" ? "block" : "none" }}>
                  <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={searchFlagText}
              onChange={handleSearchFlagged}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
            <div className="-m-1.5 overflow-x-auto">
              <div className="p-1.5 min-w-full inline-block align-middle">
                <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 ">Flagged Users</h2>
                    </div>

                    <div></div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50 ">
                      <tr>
                        <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Name
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Role
                            </span>
                          </div>
                        </th>

                        <th scope="col" className="px-6 py-3 text-start">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              Reason
                            </span>
                          </div>
                        </th>
                        

                        {
                     ( TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) &&(
                    <th scope="col" className="px-6 py-3 text-end">
                      <div className="flex items-center gap-x-2">
                        <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                          Action
                        </span>
                      </div>
                    </th>

                      )
                    }

                        <th scope="col" className="px-6 py-3 text-end">
                          <div className="flex items-center gap-x-2">
                            <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                              View
                            </span>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 ">
                      {filterflaged.slice(startIndex, endIndex).map((user) => (
                        <tr key={user.id}>
                          <td className="size-px whitespace-nowrap">
                            <div className="ps-6 lg:ps-3  px-6 py-3">
                              <div className="flex flex-col items-start gap-x-3">
                                  <Link to='/customerinfodetails' state={user.id}>
                                    <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                      {user.firstName} {user.lastName}
                                    </span>
                                  </Link>
                                  <span className="block text-sm text-gray-500">
                                    {user.email}
                                  </span>
                              </div>
                            </div>
                          </td>

                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <div className="flex items-center gap-x-3">
                                <span className="text-sm text-gray-500">
                                  {user.role?.name}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-3">
                              <span className="text-sm text-gray-500">
                                {user.note}
                              </span>
                            </div>
                          </td>
                         
                          {
                            (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (


                              <td className="size-px whitespace-nowrap">
                                <div className="px-6 py-1.5 flex gap-3">
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium " onClick={()=>{setUserId(user.id); setModalIsOpen(true)}}>
                                    Activate
                                  </button>

                                </div>
                              </td>
                            )
                          }
                          <td className="size-px whitespace-nowrap">
                            <div className="px-6 py-1.5 flex gap-3">
                              <Link to={handleView(user?.roleId)} state={user.id}>
                                <button className="inline-flex items-center gap-x-1 text-sm bg-primary px-2 py-1 rounded-lg text-white decoration-2 hover:underline font-medium ">
                                  View
                                </button>
                              </Link>
                            </div>
                          </td>
  
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                    <div>
                      <p className="text-sm text-gray-600 ">
                        <span className="font-semibold text-gray-800 ">{filterflaged.length}</span>{" "}
                        results
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(filterflaged.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filterflaged.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      ) : (
        null
      )}
      <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            appElement={document.getElementById('modal')}
          >
           
                <div className="flex flex-col items-center gap-5 px-4 ">
                  <h2 className="text-xl font-semibold text-gray-700">Are you sure you re-activate the user?</h2>
  
                

                <div className="flex justify-center items-center gap-x-4 sm:px-7 ">
                  <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
                    onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
                    Cancel
                  </button>
                  <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => handleStatusChange()}>
                    Confirm
                  </button>
                </div>
              </div>
              

             
          </Modal>
    </div>
  )
}
