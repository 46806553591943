import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { TokenService } from "../utils/TokenService.ts";

export default function SideBar() {
  const [toggleActive,setToggleActive] = React.useState('customer_info');

  const HangleActive = (e) => {
    // console.log(e.target.attributes.name.value);

    setToggleActive(e.target.attributes.name.value);
    // for(let i = 0; i < element.length; i++){
    //   // element.children.children[0].classList.remove('active');
    //   // debugger;
    //   // console.log(element[i].children[0].children[0].classList);
    //   setToggleActive(element[i].children[0].children[0].name);
    //   // if(element[i].children[0].children[0].classList.contains('active')){
    //   //   element[i].children[0].children[0].classList.remove('active');
    //   // }
    // }
      // e.target.classList.add('active');
  }
  let userdata = TokenService.getUserRole();
  useEffect(() => {
    // console.log(userdata)
  }, []);

  return (
    <div>
      <div className="sticky top-0 inset-x-0 z-20 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden">
        <div className="flex items-center py-4">
          <button
            type="button"
            className="text-gray-500 hover:text-gray-600"
            data-hs-overlay="#application-sidebar"
            aria-controls="application-sidebar"
            aria-label="Toggle navigation"
          >
            <span className="sr-only">Toggle Navigation</span>
            <svg
              className="flex-shrink-0 w-4 h-4"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="3" x2="21" y1="6" y2="6" />
              <line x1="3" x2="21" y1="12" y2="12" />
              <line x1="3" x2="21" y1="18" y2="18" />
            </svg>
          </button>
          <ol
            className="ms-3 flex items-center whitespace-nowrap"
            aria-label="Breadcrumb"
          >
            <li className="flex items-center text-sm text-gray-800">
              Application Layout
              <svg
                className="flex-shrink-0 mx-3 overflow-visible h-2.5 w-2.5 text-gray-400"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1L10.6869 7.16086C10.8637 7.35239 10.8637 7.64761 10.6869 7.83914L5 14"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </li>
            <li
              className="text-sm font-semibold text-gray-800 truncate"
              aria-current="page"
            >
              Dashboard
            </li>
          </ol>
        </div>
      </div>
      <div
        id="application-sidebar"
        className="hs-overlay w-[70%] hs-overlay-open:translate-x-0  transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] lg:w-[16%] bg-primary  pt-7 overflow-y-auto lg:block lg:translate-x-0 border-r border-white lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 "
      >
        <div className="px-6">
          <a
            className="flex-none text-xl font-semibold"
            href="#"
            aria-label="Brand"
          >
            <img src="images/logo.png" className="w-full max-w-[100px]" />
          </a>
        </div>
        {
          TokenService.getUserRole() === 1 && (
            <nav
              className="hs-accordion-group p-6 w-full h-[86vh] flex flex-col justify-between overflow-hidden"
              data-hs-accordion-always-open
            >

              <ul className="space-y-1.5">
              <li>
                  <NavLink to="/"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/customerinfo"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Customer Info
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/serviceproviderinfo"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }>
                    Service Provider Info
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/offers"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Offers
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/appointments" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Appointment
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/products" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/subscription" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Subscription
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/notify" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Send Notication
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/businessboost" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Business Boost
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/agreement" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Agreement
                  </NavLink>
                </li>
              </ul>
              <ul className="">
                <li>
                  <NavLink to="/support" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Support
                  </NavLink>
                </li>
              </ul>
            </nav>
          )
        }
        {
          TokenService.getUserRole() === 7 && (
            <nav
              className="hs-accordion-group p-6 w-full h-[86vh] flex flex-col justify-between overflow-hidden"
              data-hs-accordion-always-open
            >

              <ul className="space-y-1.5">
              <li>
              <NavLink to="/"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Dashboard
                  </NavLink>
              </li>
                <li>
                  <NavLink to="/customerinfo"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Customer Info
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/serviceproviderinfo"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }>
                    Service Provider Info
                  </NavLink>
                </li>
                
                <li>
                  <NavLink to="/products" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Products
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/subscription" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Subscription
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sendEmail" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Send Email
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/businessboost" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Business Boost
                  </NavLink>
                </li>
                
              </ul>
              <ul className="">
                <li>
                  <NavLink to="/support" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Support
                  </NavLink>
                </li>
              </ul>
            </nav>
          )
        }
        {
          TokenService.getUserRole() === 6 && (
            <nav
              className="hs-accordion-group p-6 w-full h-[86vh] flex flex-col justify-between overflow-hidden"
              data-hs-accordion-always-open
            >

              <ul className="space-y-1.5">
             
                <li>
                <NavLink to="/"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
                
                <li>
                  <NavLink to="/serviceproviderinfo"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }>
                    Service Provider Info
                  </NavLink>
                </li>
                
                
               
                <li>
                  <NavLink to="/subscription" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Subscription
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sendEmail" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Send Email
                  </NavLink>
                </li>
                
               
              </ul>
              <ul className="">
                <li>
                  <NavLink to="/support" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Support
                  </NavLink>
                </li>
              </ul>
            </nav>
          )
        }
        {
          TokenService.getUserRole() === 4 && (
            <nav
              className="hs-accordion-group p-6 w-full h-[86vh] flex flex-col justify-between overflow-hidden"
              data-hs-accordion-always-open
            >

              <ul className="space-y-1.5">

              <li>
                  <NavLink to="/"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
                
                <li>
                  <NavLink  state={TokenService.getUser().user.id} to={"/serviceproviderdetails"}
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'
                    }>
                    Service Provider Info
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/offers"
                    className={({ isActive }) =>
                      isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Offers
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/appointments" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Appointment
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/products" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' : 'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Products
                  </NavLink>
                </li> */}
                <li>
                  <NavLink to="/subscription" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Subscription
                  </NavLink>
                </li>
               
               
              </ul>
              <ul className="">
                <li>
                  <NavLink to="/support" className={({ isActive }) =>
                    isActive ? 'active flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black' :
                      'flex items-center gap-x-3.5 py-2 px-2.5  text-sm text-white font-semibold rounded-lg hover:bg-secondary hover:text-black'}>

                    Support
                  </NavLink>
                </li>
              </ul>
            </nav>
          )
        }

      </div>
    </div>
  );
}
