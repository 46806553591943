import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../utils/config";
import { GET, POST, PUT } from "../../utils/ApiRequestProvider.tsx";
import { set, useForm } from 'react-hook-form';
import Loader from "../../components/Loader.jsx";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { TokenService } from "../../utils/TokenService.ts";
import Modal from "react-modal";
import CitySelect from "../../components/cityselectmodal.jsx";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import modalStyle from "../../components/modalStyle";

export default function Content({ state }) {
  const { register: registerVehicle, handleSubmit: handleSubmitVehicle, formState: { errors: errorsVehicle }, setValue: setValueVehicle } = useForm();
  const { register: register, handleSubmit: handleSubmit, formState: { errors: errors }, setValue: setValue } = useForm();
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [vin, setVin] = useState('');
  const [vehicleData, setVehicleData] = useState({
    manufacturer: '',
    make: '',
    model: '',
    year: '',
    engine: '',
    transmission: ''
  });
  const [profileImgEdit, setProfileImgEdit] = useState(false);
  const [profileImage, setProfileImage] = useState({ base64: '', name: '', type: '' });
  const [profileEdit, setProfileEdit] = useState(false);
  const [vehicleEdit, setVehicleEdit] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cityModal, setCityModal] = useState(false);
  const [city, setCity] = useState({ id: 0, name: '' });
  const [v_Id, setV_Id] = useState(0);
  const [v_error, setV_error] = useState('');
  const [v_loading, setV_loading] = useState(false);
  const [ v_data, setV_data] = useState([]);
  
  const ResultsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = startIndex + ResultsPerPage;


  Modal.setAppElement(document.getElementById('your-app-element-id')); // Replace with your element's ID


  const handleImageChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result;
      setProfileImage({
        base64: base64Data,
        name: file.name,
        type: file.type,
      });
    }
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (state) {
      handleGetData();
      handleGetVehicle();
    }
  }, [state]);

  const handleGetData = async () => {
    setIsLoading(true);
    const res = await GET(BASE_URL + `api/User/getUserInfoById?user=${state}`);
    if (res.status === 'success') {
      setUserData(res.data);
      console.log(res.data);
      setIsLoading(false);
      if (res.data.status === 'Active') {
        setUserStatus(true);
      }
      else {
        setUserStatus(false);
      }
      setValue('firstName', res.data?.firstName);
      setValue('lastName', res.data?.lastName);
      setValue('gender', res.data?.gender);
      setValue('maritalStatus', res.data?.maritalStatus);
      setValue('dob', UTCtoDate(res.data?.dob));
      setValue('monthlyIncome', res.data?.monthlyIncome);
      setValue('email', res.data?.email);
      setValue('phone', res.data?.phone);
      setValue('zipCode', res.data?.zipCode);
      setValue('address', res.data?.address);
      setValue('city', res.data?.city?.name);
      setValue('city_id', res.data?.city?.id);

      res.data?.vehicle?.forEach(element => {
        setV_Id(element.id);
        setValueVehicle('manufacturer', element.manufacturer);
        setValueVehicle('make', element.make);
        setValueVehicle('model', element.model);
        setValueVehicle('year', element.year);
        setValueVehicle('engine', element.engine);
        setValueVehicle('transmission', element.transmission);       
      });


     
    }
  }

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    // get the month in this format of 04, the same for months
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const year = dateObj.getFullYear();

    const shortDate = `${year}/${month}/${day}`;

    return shortDate;
  };

  

  const handleProfileImgUpdate = async () => {
    const res = await PUT(BASE_URL + `api/User/updateUserProfilePic`, {
      userId: userData.id,
      profileBase64Data: profileImage.base64.split(',')[1],
      profileName: profileImage.name,
      profileType: profileImage.type
    });
    if (res.status === 'success') {
      alert('Profile Image Updated Successfully');
      window.location.reload();
    }
    else {
      alert(res.message);
    }
  }

  const UTCtoDate = (d) => {
    if (d) {
      const date = d?.split('T')[0].slice(0, 10).split('-');
      const formattedDate = `${date[0]}-${date[1]}-${date[2]}`;
      return formattedDate;
    }
  }

  const DatetoUTC = (date) => {
    const dateObj = new Date(date);
  
    return dateObj.toString().split(' (')[0];
  }

  const onSubmit = async (data) => {
    if(data.description === '') {
      alert('Please enter the reason for the update');
      return;
    }
    const res = await POST(BASE_URL + `api/User/requestUserinfoUpdate`, {
      userId: state,
      firstName: data.firstName,
      lastName: data.lastName,
      dob: data.dob,
      gender: data.gender,
      phone: data.phone,
      cityId: data.city_id,
      zipCode: data.zipCode,
      address: data.address,
      lati: 0,
      long: 0,
      maritalStatus: data.maritalStatus,
      monthlyIncome: data.monthlyIncome,
      requestedById: TokenService.getUser()?.user?.id,
      description: data.description,
    });
    if (res.status === 'success') {
      alert('User Info Update Requested Successfully');
      window.location.reload();
    }
    // console.log(JSON.stringify({
    //   userId: state,
    //   firstName: data.firstName,
    //   lastName: data.lastName,
    //   dob: DatetoUTC(data.dob),
    //   gender: data.genger,
    //   phone: data.phone,
    //   cityId: data.city_id,
    //   zipCode: data.zipCode,
    //   address: data.address,
    //   lati: 0,
    //   long: 0,
    //   maritalStatus: data.maritalStatus,
    //   monthlyIncome: data.monthlyIncome,
    //   requestedById: TokenService.getUser()?.user?.id,
    //   description: data.description,
    // }))
  };

  const handleStatusChange = async () => {
   
    if (!userStatus) {
      activateUser('Active')
    }
    else {
      activateUser('InActive')
    }
  }

  const activateUser = async (s) => {
    const res = await PUT(BASE_URL + `api/User/updateUserStatus`, {
      userId: userData.id,
      status: s
    });
    console.log(res);
    if (res.status === 'success') {
      alert('User Status Updated Successfully');
      window.location.reload();
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      border: 'none',
    },
  };

  const custom = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      width: '65%',
    },
  };

  function handleCitySelection(city) {
    console.log('Selected city ID:', city);
    if(city !== null){
      setValue('city', city.name);
      setValue('city_id', city.id);
      setCity({id: city.id, name: city.name})
      setCityModal(false);
    }  
  }

  async function getVehicleInfo() {
    setV_loading(true);
    const response = await fetch(`https://api.carmd.com/v3.0/decode?vin=${vin}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic M2JkNjM0MjEtZTY1My00NjBhLTk3N2UtNDdkNGEwNzJmNDRj', // Replace with your actual auth key
        'Partner-Token': 'c95c1d8989bd4cc0b44f4cbf63f7f8a9',
      },
    });
    const data = await response.json();
    if (data.data !== '') {
      setValueVehicle('manufacturer', data.data.manufacturer);
      setValueVehicle('make', data.data.make);
      setValueVehicle('model', data.data.model);
      setValueVehicle('year', data.data.year);
      setValueVehicle('engine', data.data.engine);
      setValueVehicle('transmission', data.data.transmission);
      setV_error('');
      setV_loading(false);

    }
    else {
      setV_error(data.message?.message);
      console.log(data.message?.message);
      setV_loading(false);
    }
  }


  const onVehicleSubmit = async (data) => {
    if (vin === '') {
      alert('Please enter VIN');
      return;
    }
    else if (v_error !== '') {
      alert(v_error);
      return;
    }
    else if (v_error === '') {

      const res = await PUT(BASE_URL + `api/User/updateVehicle`,
       { vehicleId: v_Id,
        vin: vin,
        year: data.year.toString(),
        make: data.make,
        model: data.model,
        engine: data.engine,
        manufacturer: data.manufacturer,
        trim: data.trim,
        transmission: data.transmission,
        status: 'Active',
        Authorization: 'Bearer ' + TokenService.getAccessToken()
       }

      );
      if (res.status === 'success') {
        alert('Vehicle Info Updated Successfully');
        window.location.reload();
      }
      // else {
      //   alert(res.message);
      // }
    }
    // console.log(v_Id);
  }

  const handleGetVehicle = async () => {
    const res = await GET(BASE_URL + `api/User/getVehicleHistoryByUserId?userId=${state}`);
    if (res.status === 'success') {
      setV_data(res.data);
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <h3 className="text-xl text-primary font-bold pb-5">
          Customer Details
        </h3>

        <label className="flex cursor-pointer gap-2 items-center justify-end">
          <p className="text-sm">In Active</p>
          <input
            type="checkbox"
            defaultChecked={userData.status} className="sr-only peer"
            checked={userStatus}
            onChange={() => setModalIsOpen(true)}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-primary"></div>
          <p className="text-sm">Active</p>
        </label>
      </div>
      {isLoading ?
        <Loader />
        :
        <>
          {/* Profile image section */}
          <div className="flex flex-row items-start justify-between w-fit">
            <div className="flex flex-col pb-5" >
              <img
                src={profileImage.base64 !== '' ? profileImage.base64 : (userData.profilePath ? BASE_URL + userData.profilePath : "images/image-placeholder.jpg")}
                className="w-[150px] h-[150px] object-cover rounded-full"
              />
              {profileImgEdit &&
                <div className="py-3">
                  <input
                    className="block text-sm p-2 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 w-fit "
                    id="file_input"
                    type="file"
                    onChange={(event) => handleImageChange(event)}
                    disabled={!profileImgEdit}
                  />

                  <div className="flex gap-3 py-3">
                    <button className="text-white bg-primary rounded-lg px-4 py-2"
                      onClick={handleProfileImgUpdate}
                    >
                      Save
                    </button>
                    <button
                      className="text-primary bg-gray-200 rounded-lg px-4 py-2"
                      onClick={() => setProfileImgEdit(!profileImgEdit)}
                    >
                      Cancel
                    </button>
                  </div>

                </div>
              }
            </div>
            {/* <div className="flex flex-col flex-grow items-between">
              <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileImgEdit(!profileImgEdit)}>
                <EditIcon />
              </button>

            </div> */}
          </div>

          <div>

            {/* Personnal Info */}
            <>
              <div className="flex flex-col">
                <div className="-m-1.5 overflow-x-auto">
                  <div className="flex flex-row items-center justify-between">
                    <h3 className="text-xl text-gray-700 font-semibold pb-5">
                      Personnal Info
                    </h3>
                    <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setProfileEdit(!profileEdit)}>
                      <EditIcon />
                    </button>
                  </div>
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="overflow-hidden">

                        <div className="flex md:flex-row flex-col gap-10 pb-3">
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="firstName" className="pb-1">First Name</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={userData?.firstName}
                              id="firstName" {...register('firstName', { required: true })}
                              disabled={!profileEdit}
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="lastNamw" className="pb-1">Last Name</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={userData?.lastName}
                              id="lastName" {...register('lastName', { required: true })}
                              disabled={!profileEdit}
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="gender" className="pb-1">Gender</label>
                            <select disabled={!profileEdit} id="gender" {...register('gender', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.gender ? 'border-red-500' : 'border-gray-300'}`}>
                              <option value="">Select a gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="maritalStatus" className="pb-1">Marital Status</label>
                            <select className="py-2 px-2 rounded-md border w-full"
                              id="maritalStatus" {...register('maritalStatus', { required: true })}
                              defaultValue={userData?.maritalStatus}
                              disabled={!profileEdit}
                            >
                              <option >Select </option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                              <option value="Student">Student</option>
                            </select>
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="dob" className="pb-1">DOB</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="date"
                              defaultValue={UTCtoDate(userData?.dob)}
                              id="dob" {...register('dob', { required: true })}
                              disabled={!profileEdit}
                            />

                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="monthlyIncome" className="pb-1">Monthly Income</label>
                            <select className="py-2 px-2 rounded-md border w-full"
                              id="monthlyIncome" {...register('monthlyIncome', { required: true })}
                              defaultValue={userData?.monthlyIncome}
                              disabled={!profileEdit}
                            >
                              <option >Select </option>
                              <option value="$1000 - $2000">$1000 - $2000</option>
                              <option value="$2000 - $3000">$2000 - $3000</option>
                              <option value="$3000 - $4000">$3000 - $4000</option>
                              <option value="$4000+">$4000+</option>
                            </select>

                          </div>

                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="email" className="pb-1">Email</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              disabled
                              defaultValue={userData?.email}
                              id="email" {...register('email', { required: true })}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="phone" className="pb-1">Phone</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="phone"
                              disabled={!profileEdit}
                              defaultValue={userData?.phone}
                              id="phone" {...register('phone', { required: true })}

                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Created At</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              defaultValue={formatDate(userData?.createdAt)}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label htmlFor="city" className="pb-1">City</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              disabled={!profileEdit}
                              defaultValue={userData?.city ? userData.city.name : ''}
                              id="city" {...register('city', { required: true })}
                              onClick={() => setCityModal(true)}
                            />
                          </div>


                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">ZIP Code</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="number"
                              disabled={!profileEdit}
                              defaultValue={userData?.zipCode}
                              id='zipCode' {...register('zipCode', { required: true })}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Address</label>
                            <textarea
                              className="py-2 px-2 rounded-md border w-full h-20"
                              type="address"
                              disabled={!profileEdit}
                              defaultValue={userData?.address}
                              id='address' {...register('address', { required: true })}

                            />
                          </div>
                        </div>
                        {profileEdit &&
                          <div className="flex md:flex-row flex-col gap-10 pb-3">

                            <div className="flex flex-col text-sm text-gray-500 w-full">
                              <label htmlFor="description" className="pb-1">Reason*</label>
                              <input
                                className="py-2 px-2 rounded-md border w-full"
                                type="text"
                                disabled={!profileEdit}
                                id="description" {...register('description')}
                              />
                            </div>

                          </div>
                        }

                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {profileEdit &&
                <div className="flex justify-end gap-3">
                  <button
                    type="submit"
                    className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                    onClick={() => setProfileEdit(!profileEdit)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-primary text-white py-2 px-4 rounded-lg"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </button>
                </div>
              }
            </>

            {/* Vehicle Info */}
            <>
              <div className="flex flex-row items-center justify-between">
                <h3 className="text-xl text-gray-700 font-semibold pb-5">
                  Vehicle Info
                </h3>
                <button className="text-primary py-2 px-4 rounded-lg" onClick={() => setVehicleEdit(!vehicleEdit)}>
                  <EditIcon />
                </button>
              </div>
              <form
                onSubmit={handleSubmitVehicle(onVehicleSubmit)}
              >
                <div className="flex flex-col">
                  <div className="-m-1.5 overflow-x-auto">
                   
                    <div className="p-1.5 min-w-full inline-block align-middle">
                      {vehicleEdit &&
                        <div className="flex flex-col text-sm text-gray-500 mb-2 w-1/2">
                          <label className="pb-1">VIN #</label>
                          <div className="flex flex-row gap-3">
                            <div className="flex flex-col text-sm text-gray-500 w-full">
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              placeholder="Enter VIN"
                              value={vin}
                              onChange={(e) => setVin(e.target.value)}
                            />
                            {v_error !== '' && <p className="text-xs text-red-500 py-1"> <ErrorOutlineIcon className="text-red-500 "  fontSize="small"/> {v_error}</p>}
                          </div>

                            <button
                              type="button"
                              className="h-10 w-60 items-center justify-center bg-primary text-white text-sm py-2 px-4 rounded-lg "
                              onClick={getVehicleInfo}
                            >
                              Get Vehicle Info
                            </button>
                          </div>
                        </div>
                      }


                      <div className="overflow-hidden">
                        {v_loading ? <div role="status" className="flex justify-center items-center w-full h-24 ">
                          <svg aria-hidden="true" className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <>
                        <div className="flex md:flex-row flex-col gap-10 pb-3" >
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Manufacturer</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              id='manufacturer' {...registerVehicle('manufacturer', { required: true })}                         
                              disabled={true}

                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Make</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="text"
                              id='make' {...registerVehicle('make', { required: true })}
                              disabled={true}
                            />
                          </div>
                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Model</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              id='model' {...registerVehicle('model', { required: true })}
                              disabled={true}
                            />
                          </div>
                        </div>

                        <div className="flex md:flex-row flex-col gap-10 pb-3">

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Year</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              id='year' {...registerVehicle('year', { required: true })}
                              disabled={true}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Engine</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              id='engine' {...registerVehicle('engine', { required: true })}
                              disabled={true}
                            />
                          </div>

                          <div className="flex flex-col text-sm text-gray-500 w-full">
                            <label className="pb-1">Transmission</label>
                            <input
                              className="py-2 px-2 rounded-md border w-full"
                              type="email"
                              id='transmission' {...registerVehicle('transmission', { required: true })}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </>

                        }

                    

                      </div>
                    </div>
                  </div>
                </div>
                {vehicleEdit &&
                  <div className="flex justify-end gap-3">
                    <button
                      type="submit"
                      className="bg-gray-200 text-primary py-2 px-4 rounded-lg"
                      onClick={() => setVehicleData(!vehicleEdit)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-primary text-white py-2 px-4 rounded-lg"
                      onClick={handleSubmitVehicle(onVehicleSubmit)}
                    >
                      Save
                    </button>
                  </div>
                }
              </form>
            </>



          </div>
        </>
      }
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 p-5">
          <h2 className="text-xl font-semibold text-gray-700">Are you sure you want to {userStatus ? 'deactivate' : 'activate'} the user?</h2>
          {/* <p className="text-sm text-gray-500 text-justify">If you change the status, the user will not be able to access the system.</p> */}
        </div>

        <div className="flex justify-center items-center gap-x-4 p-4 sm:px-7 ">
          <button type="button" className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none "
            onClick={() => setModalIsOpen(false)} data-hs-overlay="#hs-notifications">
            Cancel
          </button>
          <button className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-primary text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
            onClick={() => handleStatusChange()}>
            Confirm
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={cityModal}
        onRequestClose={() => setCityModal(false)}
        style={custom}
        appElement={document.getElementById('your-app-element-id')}
      >
        <div className="flex flex-col items-center gap-5 p-5">
          <CitySelect onCitySelect={handleCitySelection} />
        </div>
      </Modal>


    </div>
  );
}
