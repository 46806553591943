import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './salesandsupport/content';

export default function SalesAndSupport() {
  return (
    <Baselayout>
        <Content/>
    </Baselayout>
  )
}
