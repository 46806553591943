import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./workeredit/Content";
import { useLocation } from "react-router-dom";

export default function WorkerEditForm() {
  const {state} = useLocation();

  return (
    <Baselayout>
      <Content state={state}/>
    </Baselayout>
  );
}
