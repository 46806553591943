import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./addserviceprovider/content";

const AddServiceProvider = () => {

  return (
    <Baselayout>
      <Content/>
    </Baselayout>
  );
}

export default AddServiceProvider;