import React, { useState, useEffect } from "react";
import { GET, POST } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { TokenService } from '../../utils/TokenService.ts';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Loader from '../../components/Loader.jsx';
import { auth } from '../../firebase';
import { set, useForm } from "react-hook-form";
import { Token } from "@mui/icons-material";


const Login = () => {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useState(false);
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  async function login(data) {
    if(save === true){
      TokenService.setEmail(data.email);
      TokenService.setPassword(data.password);
    }
    else if(save === false){
      TokenService.setEmail('');
      TokenService.setPassword('');
    }
    setLoading(true);
    const loginResponse = await POST(BASE_URL + "api/User/login", {
      email: data.email,
      password: data.password,
      firebaseDeviceToken: 'ohyeah'
    });
    
    if (loginResponse.status === 'success') {
      if(loginResponse.data.user.role.id === 1 || loginResponse.data.user.role.id === 6 || loginResponse.data.user.role.id === 4 || loginResponse.data.user.role.id === 7){
      signInWithEmailAndPassword(auth, data.email, data.password)
        .then((userCredential) => {
          // console.log('firebaseUser', loginResponse.data.user);
          const firebaseUser = userCredential.user;
          TokenService.setAccessToken(loginResponse.data.access_token.token);
          TokenService.setUser(loginResponse.data);
          TokenService.setUserRole(loginResponse.data.user.role.id);
          TokenService.setRefreshToken(loginResponse.data.refreshToken.token);
          window.location.reload();
        })
        .catch((error) => {
          console.log('error', error);
        });
      }else
      {
        alert('You are not authorized to Perform this Task.');
        setLoading(false);
      }
    }
    else{
      alert(loginResponse.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    console.log('TokenService.getEmail()', TokenService.getEmail());
    if (sessionStorage.getItem("user-info")) {
      //   history.push("/add");
    }
    if(TokenService.getEmail() !== null){

      setEmail(TokenService.getEmail());
      setPassword(TokenService.getPassword());
      setValue('email', TokenService.getEmail());
      setValue('password', TokenService.getPassword());
      setSave(true);
    }
    else if(TokenService.getEmail() === null){

      setEmail('');
      setPassword('');
      setSave(false);
    }


  }, []);

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      login();
    }
  }

  return (
    <main className="flex items-center justify-center h-[100vh] w-full mx-auto p-6">
      {loading ?(
        <Loader />
      )
      :
      <div className="w-full lg:w-[30%] bg-white border border-gray-200 rounded-xl shadow-sm ">
        <div className="p-4 sm:p-7   ">
          <div className="flex items-center justify-center">
            <img className="w-32" src="/images/logo.png" />
          </div>

          <div className="mt-5 w-full">
            {/* <form >
              <div className="grid gap-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm mb-2">
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      className="py-3 px-4 block w-full  ring-gray-200 rounded-lg text-sm border focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center">
                    <label htmlFor="password" className="block text-sm mb-2">
                      Password
                    </label>
                    <a
                      className="text-sm text-blue-600 decoration-2 hover:underline font-medium d"
                      href="../examples/html/recover-account.html"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="relative">
                    <input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      autoComplete=""
                      className="py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      defaultChecked={save}
                      checked={save}
                      onClick={(e) => setSave(e.target.checked)}
                    />
                  </div>
                  <div className="ms-3">
                    <label htmlFor="remember-me" className="text-sm">
                      Remember me
                    </label>
                  </div>
                </div>

                <button
                  type="button"
                  onClick={login}
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none "
                >
                  Sign in
                </button>
              </div>
            </form> */}
            <form onSubmit={handleSubmit(login)}>
            <div className="grid gap-y-4">
                <div>
                  <label htmlFor="email" className="block text-sm mb-2">
                    Email address
                  </label>
                  <div className="relative">
                    <input
                      type="email"
                      defaultValue={email}
                      id="email" {...register('email', { required: true })}
                      className="py-3 px-4 block w-full  ring-gray-200 rounded-lg text-sm border focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex justify-between items-center">
                    <label htmlFor="password" className="block text-sm mb-2">
                      Password
                    </label>
                    <a
                      className="text-sm text-blue-600 decoration-2 hover:underline font-medium d"
                      // href="../examples/html/recover-account.html"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <div className="relative">
                    <input
                      type="password"
                      defaultValue={password}
                      id="password" {...register('password', { required: true })}
                      className="py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="flex">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      defaultChecked={save}
                      checked={save}
                      onClick={(e) => setSave(e.target.checked)}
                    />
                  </div>
                  <div className="ms-3">
                    <label htmlFor="remember-me" className="text-sm">
                      Remember me
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none "
                >
                  Sign in
                </button>
              </div>
              </form>

          </div>
        </div>
      </div>
      }
    </main>
  );
};

export default Login;
