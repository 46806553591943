import React from "react";
import Baselayout from "../components/layouts/Baselayout";
import Content from "./serviceproviderinfoedit/Content";
import { useLocation } from "react-router-dom";

const ServiceProviderInfoEdit = () => {
  const { state } = useLocation();

  return (
    <Baselayout>
      <Content state={state} />
    </Baselayout>
  );
}

export default ServiceProviderInfoEdit;