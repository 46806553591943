import { JsonData } from '@amcharts/amcharts5';
import axios from './RequestProvider.ts';
import { replace } from '@amcharts/amcharts5/.internal/core/util/Array';
import { BASE_URL } from './config.jsx';
import { TokenService } from './TokenService.ts';

export interface APIResponse {
    status: "success" | "error",
    message: string;
    data: any
}
export const POST: (url: string, data?: {}, headers?: {}) => Promise<APIResponse> = async (url: string, data = {}, headers = {}) => {
    try {
        const res = await axios.post(url, data,
            {
                validateStatus: status => {
                    return status >= 200
                }
            }
        );
        return res.data;
    } catch (error) {
        return error;
    }
}


export const PUT: (url: string, data?: {}, headers?: {}) => Promise<APIResponse> = async (url: string, data = {}, headers = {}) => {
    try {
        const res = await axios.put(url, data,
            {
                validateStatus: status => {
                    return status >= 200
                }
            }
        );
        return res.data;
    } catch (error) {
        return error;
    }
}

export const GET = async (url: string, headers = {}): Promise<APIResponse> => {
    try {
        const res = await axios.get(url, { validateStatus: status => status >= 200 });

        return res.data;
    } catch (error) {
          
                    TokenService.clearToken();
                    TokenService.clearUser();
                    TokenService.clearUserRole();
                    sessionStorage.removeItem("token");
                    TokenService.setAccessToken('');
                    window.location.href = "/";
        // If the error is not due to a 401 status code or token refresh fails, return the error
        return error;
    }
}


export const DELETE: (url: string, data?: {}, headers?: {}) => Promise<APIResponse> = async (url: string, data = {}, headers = {}) => {
    try {
        const res = await axios.delete(url, {
            data,
            validateStatus: status => {
                return status >= 200
            }
        }
        );
        return res.data;
    } catch (error) {
        return error;
    }
}

export const GETFile: (url: string) => Promise<File> = async (url: string) => {
    try {
        url = "https://testapi.entry-bot.com/api/Documents/GetImage?url=" + url;
        const encodedUrl: string = encodeURI(url);
        const response = await axios.get(encodedUrl, {
            headers: {
                
                'Access-Control-Allow-Headers': 'access-control-allow-origin,authorization',
                'Access-Control-Allow-Methods': 'GET',
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            },
            withCredentials: true,
            responseType: 'blob'
        });
        // console.log(response);
        return new File(
            [response.data],
            url.split("/")[url.split("/").length - 1],
            { type: response.data.type }
        );
    } catch (error: any) {
        throw error;
    }
}