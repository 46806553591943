import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
// import { models } from 'powerbi-client';
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { Link } from "react-router-dom";
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { models, Report, Embed, service, Page } from 'powerbi-client';

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { TokenService } from "../../utils/TokenService.ts";
import { set } from "react-hook-form";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
ChartJS.register(ArcElement, Tooltip, Legend);
export default function Content() {
    const [EarningchartData, setEarningchartData] = useState(null);
    const [appointmentStatusData, setAppointmentStatusData] = useState(null);
    const [appointmentStatusByServiceData, setAppointmentStatusServiceData] = useState(null);
    const [completedEarnings, setCompletedEarnings] = useState(0);
    const [earningData, setEarningData] = useState(null);
    const [totalEarning, setTotalEarning] = useState(0);
    const [pendingEarnings, setPendingEarnings] = useState(0);
    const [userData, setUserData] = useState(null);
    const [RNuserData, setRNUserData] = useState(null);
    const [businessData, setBusinessData] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [businessCount, setBusinessCount] = useState(0);
    const [ticketCount, setTicketCount] = useState(0);
    const [workerCount, setWorkerCount] = useState(0);
    const [offerData, setOfferData] = useState(null);
    const [topValue, setTopValue] = useState(10);
    const [withinValue, setWithinValue] = useState(20);
    const [frequentCustomers, setFrequentCustomers] = useState([]);
    const [remainingSubs, setRemainingSubs] = useState(null);
    const [appointments, setAppointments] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [workerData, setWorkerData] = useState(null);
    const [businessRating, setBusinessRating] = useState(0);
    const [reviewCount, setReviewCount] = useState(0);
    const fetchOfferData = async () => {
        try {
            const res = await GET(BASE_URL + `api/Reports/getNearlyExpirOffer?userId=${TokenService.getUser().user.id}&top=${topValue}&withIn=${withinValue}`);
            if (res.status === 'success') {
                console.log(res.data);
                setOfferData(res.data);
            } else {
                console.error('Error fetching offer data:', res);
            }
        } catch (error) {
            console.error('Error fetching offer data:', error);
        }
    };

    const fetchUserCount = async () => {
        const res = await GET(BASE_URL + `api/Reports/getBusinessCustomerCount?businessId=${TokenService.getUser().user.id}`);
        if (res.status === 'success') {
            if (res.data.customerCount !== 0) {
                setUserCount(res.data.customerCount);
            }
            else
            setUserCount(0);
        } else {
            console.error('Error fetching user count:', res);
        }
    };


    useEffect(() => {
        fetchOfferData();
        fetchUserCount();
    }, [topValue, withinValue]);
    const handleTopChange = (e) => {
        setTopValue(parseInt(e.target.value));
    };

    const handleWithinChange = (e) => {
        setWithinValue(parseInt(e.target.value));
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0'); // Get day and pad with leading zero if necessary
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month (add 1 because month is zero-indexed) and pad
        const year = date.getFullYear(); // Get full year
        return `${day}-${month}-${year}`; // Return formatted date in dd-mm-yyyy format
    };
    // Define a color palette array
    const colorPalette = ['#EFD82B', '#275DAB', '#FF6384', '#36A2EB', '#FF99C3', '#4BC0C0', '#C9DE55', '#B5928E', '#8B008B', '#808080'];
    // Function to get a color from the palette based on index
    const getColorFromPalette = (index) => {
        return colorPalette[index % colorPalette.length];
    };


    const formatAppointmentStatusData = (data) => {
        const labels = data.map((item) => item.appointmentStatus);
        const counts = data.map((item) => item.count);
        const formattedData = {
            labels: labels,
            datasets: [
                {
                    label: 'Appointments',
                    data: counts,
                    backgroundColor: labels.map((label, index) => getColorFromPalette(index)), // Assign colors dynamically
                },
            ],
        };
        return formattedData;
    };
    const formatAppointmentByServiceTypeData = (data) => {
        const labels = data.map((item) => item.serviceTypeName);
        const counts = data.map((item) => item.count);
        const formattedData = {
            labels: labels,
            datasets: [
                {
                    label: 'Appointments',
                    data: counts,
                    backgroundColor: labels.map((label, index) => getColorFromPalette(index)), // Assign colors dynamically
                },
            ],
        };
        return formattedData;
    };
    const formatBusinessChartData = (data) => {
        const cityCounts = {};
        // Count businesses for each city
        data.forEach((business) => {
            const cityName = business.cityName;
            if (cityCounts[cityName]) {
                cityCounts[cityName] += 1;
            } else {
                cityCounts[cityName] = 1;
            }
        });
        // Prepare chart data
        const chartData = {
            labels: Object.keys(cityCounts),
            datasets: [{
                label: 'Number of Businesses',
                data: Object.values(cityCounts),
                backgroundColor: Object.keys(cityCounts).map((key, index) => getColorFromPalette(index)), // Assign colors dynamically
            }],
        };
        return chartData;
    };
    const formatUserData = (data) => {
        const chartData = {
            labels: [], // City names
            datasets: [], // Array to hold datasets (one per user type)
        };
        if (!Array.isArray(data) || data.length === 0) {
            console.error('Invalid data format or empty data array:', data);
            return chartData;
        }
        // Organize data by city and user type
        const cityMap = {};
        data.forEach((entry) => {
            const { cityName, userType, count } = entry;
            const label = cityName || 'Unknown'; // Use 'Unknown' if cityName is null
            if (!cityMap[label]) {
                cityMap[label] = {};
                chartData.labels.push(label);
            }
            cityMap[label][userType] = count;
        });
        // Create datasets based on user types
        const userTypes = ['Business', 'Customer', 'Worker', 'Sale', 'Support'];
        userTypes.forEach((userType, index) => {
            const dataPoints = chartData.labels.map((cityName) => cityMap[cityName]?.[userType] || 0);
            chartData.datasets.push({
                label: userType,
                data: dataPoints,
                backgroundColor: getColorFromPalette(index), // Assign color based on index
            });
        });
        return chartData;
    };
    const formatEarningData = (data) => {
        const labels = data.map((item) => item.subscription);
        const earnings = data.map((item) => item.earning);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Earning by Type',
                    data: earnings,
                    backgroundColor: ['#275DAB', '#EFD82B', '#FF6384'], // Colors for each slice
                },
            ],
        };

        return chartData;
    };
    const formatRNUserData = (data) => {
        const labels = data.map((item) => item.user_type);
        const counts = data.map((item) => item.user_count);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'User Type Count',
                    data: counts,
                    backgroundColor: ['#275DAB', '#EFD82B', '#FF6384', '#36A2EB', '#FFCE56'], // Colors for each slice
                },
            ],
        };

        return chartData;
    };
    const formatEarningChartData = (data) => {
        const labels = data.map(item => item.offerName);
        const earnings = data.map(item => item.earning);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Earnings by Offer',
                    data: earnings,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)', // Blue color
                }
            ]
        };
    };

    const appointmentDates = appointments.map((appointment) => new Date(appointment.appointmentDate));

    const tileClassName = ({ date }) => {
        const isAppointmentDate = appointmentDates.some((appointmentDate) => isSameDay(appointmentDate, date));
        return isAppointmentDate ? 'highlighted-date' : null;
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const isSameDay = (date1, date2) => {
        return (
            date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate()
        );
    };
    useEffect(() => {
        const fetchAppointments = async () => {
            try {
                const res = await GET(BASE_URL + 'api/Reports/getcurrentMonthAppointments?businessId=' + TokenService.getUser().user.businessId);
                if (res.status === 'success') {
                    setAppointments(res.data);
                } else {
                    console.log('Error fetching appointments:', res);
                }
            } catch (error) {
                console.error('Error fetching appointments:', error);
            }
        };

        fetchAppointments();
        // PIE Chart 
        const fetchActiveAppointment = async () => {
            const res = await GET(BASE_URL + `api/Reports/getAppointmentStatusCount?userId=` + TokenService.getUser().user.id);
            if (res.status === 'success') {
                console.log(res.data);
                const formattedData = formatAppointmentStatusData(res.data);
                setAppointmentStatusData(formattedData);
            }
            else {
                console.log('Total Earning', res);
            }
        };
        // PIE Chart
        const fetchActiveAppointmentByServiceType = async () => {
            const res = await GET(BASE_URL + `api/Reports/getAppointmentServiceCount?userId=` + TokenService.getUser().user.id);
            if (res.status === 'success') {
                //   console.log(res.data);
                const formattedData = formatAppointmentByServiceTypeData(res.data);
                setAppointmentStatusServiceData(formattedData);
            }
            else {
                console.log('Total Earning', res);
            }
        };

        // top Card
        const fetchTotalEarning = async () => {
            try {
                const res = await GET(BASE_URL + 'api/Reports/getEarningsByStatusAndBusiness?businessId=' + TokenService.getUser().user.business.id);
                if (res.status === 'success') {
                    console.log('success',res.data);
                    setPendingEarnings(res?.data[0].pendingEarnings);
                    setCompletedEarnings(res?.data[0].completedEarnings);
                    setTotalEarning(res?.data[0].pendingEarnings+res?.data[0].completedEarnings);
                } else {
                    console.log('Error fetching business data:', res);
                }
            } catch (error) {
                console.error('Error fetching business data:', error);
            }
        };
        //    Top Card
        const fetchgetOnlyAppointmentListByBusinessId = async () => {
            try {
                const res = await GET(BASE_URL + 'api/Appointment/getOnlyAppointmentListByBusinessId?businessId=' + TokenService.getUser().user.businessId);
                if (res.status === 'success') {
                    const Count = res.data.length;
                    setBusinessCount(Count);
                } else {
                    console.log('Error fetching business data:', res);
                }
            } catch (error) {
                console.error('Error fetching business data:', error);
            }
        };
        const FetchgetEarningByOffer = async () => {
            const res = await GET(BASE_URL + `api/Reports/getEarningByOffer?userId=` + TokenService.getUser().user.id);
            if (res.status === 'success') {
                console.log(res.data);
                const formattedData = formatUserData(res.data);
                console.log('User Data', formattedData);
                setUserData(formattedData);
            }
            else {
                console.log('Total Earning', res);
            }
        };

        const fetchWorkers = async () => {
            const res = await GET(BASE_URL + `api/User/GetAllBusinessWorkerList?businessId=` + TokenService.getUser().user.businessId);
            if (res.status === 'success') {
                console.log(res.data);
                const count = res.data.length;
                setWorkerCount(count);
            }
        }
        const fetchEarningData = async () => {
            try {
                const userId = 'your_user_id_here'; // Replace with actual user ID
                const res = await GET(BASE_URL + `api/Reports/getEarningByOffer?userId=${TokenService.getUser().user.id}`);
                if (res.status === 'success') {
                    const formattedData = formatEarningChartData(res.data);
                    setEarningData(formattedData);
                } else {
                    console.error('Error fetching earning data:', res);
                }
            } catch (error) {
                console.error('Error fetching earning data:', error);
            }
        };
        const handleGetRemainingSubs = async () => {
            const res = await GET(BASE_URL + "api/Reports/getRemainingSubsCount?businessId=" + TokenService.getUser().user.businessId);
            if (res.status === "success") {
                const formattedData = formatRemainingSubsData(res.data);
                setRemainingSubs(formattedData);
            } else {
                console.log("Error fetching remaining subscriptions:", res);
            }
        };
        handleGetRemainingSubs();
        fetchWorkers()
        fetchEarningData();

        fetchActiveAppointment();

        FetchgetEarningByOffer();
        fetchActiveAppointmentByServiceType();
        fetchTotalEarning();

        fetchgetOnlyAppointmentListByBusinessId();

    }
        , []);
    const formatRemainingSubsData = (data) => {
        const labels = [];
        const counts = [];
        const backgroundColors = [];

        data.forEach((business) => {
            business.remainingSubs.forEach((subscription) => {
                labels.push(subscription.serviceType);
                counts.push(subscription.remainingCount);
                // Use color palette for each slice based on index
                backgroundColors.push(getColorFromPalette(labels.length - 1));
            });
        });

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Remaining Count',
                    data: counts,
                    backgroundColor: backgroundColors,
                },
            ],
        };

        return chartData;
    };
    useEffect(() => {
        const fetchWorkerAverageTime = async () => {
            try {
                const res = await GET(BASE_URL + 'api/Reports/getAppointmentsAverageTimeByWorker?businessId='+TokenService.getUser().user.businessId);
                if (res.status === 'success') {
                    const formattedData = formatWorkerData(res.data);
                    setWorkerData(formattedData);
                } else {
                    console.log('Error fetching worker average time:', res);
                }
            } catch (error) {
                console.error('Error fetching worker average time:', error);
            }
        };

        fetchWorkerAverageTime();
    }, []);

    const formatWorkerData = (data) => {
        const formattedWorkerData = [];

        for (const workerName in data) {
            const averageTime = data[workerName];
            const formattedAverageTime = formatDuration(averageTime);
            formattedWorkerData.push({ workerName, averageTime: formattedAverageTime });
        }

        return formattedWorkerData;
    };

    const formatDuration = (durationString) => {
        if (!durationString) return "Invalid duration";
      
        const [days, time] = durationString.split(".");
        const [hours, minutes, seconds] = time.split(":");
      
        const formattedDuration = [];
      
        if (days) {
          const parsedDays = parseInt(days, 10);
          formattedDuration.push(`${parsedDays} day${parsedDays !== 1 ? 's' : ''}`);
        }
      
        if (hours) {
          const parsedHours = parseInt(hours, 10);
          formattedDuration.push(`${parsedHours} hour${parsedHours !== 1 ? 's' : ''}`);
        }
      
        if (minutes) {
          const parsedMinutes = parseInt(minutes, 10);
          formattedDuration.push(`${parsedMinutes} minute${parsedMinutes !== 1 ? 's' : ''}`);
        }
      
        if (seconds) {
          const parsedSeconds = parseInt(seconds, 10);
          formattedDuration.push(`${parsedSeconds} second${parsedSeconds !== 1 ? 's' : ''}`);
        }
      
        return formattedDuration.join(", ");
      };
     

      useEffect(() => {
        
        const fetechBusinessRating = async () => {
            try {
                const res = await GET(BASE_URL + 'api/Business/GetBusinessesById?business=' + TokenService.getUser().user.businessId);
                if (res.status === 'success') {
                   
                    setBusinessRating(res.data.calculatedRating);
                    setReviewCount(res.data.reviewCount);
                } else {
                    console.error('Error fetching business rating:', res);
                }
            } catch (error) {
                console.error('Error fetching business rating:', error);
            }
        }
        const fetchRNCustomerData = async () => {
            console.log("TokenService.getUser().user.id", TokenService.getUser().user.id);
            try {
              const res = await GET(BASE_URL + "api/Reports/getNewAndReturneeCount?userId="+TokenService.getUser().user.id);
              if (res.status === "success") {
                const formattedData = formatRNUserData(res.data);
                console.log("User Data return", res.data);
                setRNUserData(formattedData);
              } else {
                console.log("Error fetching user data:", res);
              }
            } catch (error) {
              console.error("Error fetching user data:", error);
            }
          };
        fetchRNCustomerData();
        fetechBusinessRating();
    }, []);
      

    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">

                {/* Total Users Card */}
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">Customers</h2>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Total Customers:</p>
                        <p className="text-lg font-semibold text-gray-800">{userCount}</p>
                    </div>
                </div>

                {/* Earnings Card */}
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">Earnings</h2>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Completed Earnings:</p>
                        <p className="text-lg font-semibold text-gray-800">${completedEarnings.toFixed(2)}</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Pending Earnings:</p>
                        <p className="text-lg font-semibold text-gray-800">${pendingEarnings.toFixed(2)}</p>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Total Earnings:</p>
                        <p className="text-lg font-semibold text-gray-800">${totalEarning.toFixed(2)}</p>
                    </div>
                </div>

                {/* Active Users Card */}
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">Appointments</h2>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Total Appointments:</p>
                        <p className="text-lg font-semibold text-gray-800">{businessCount}</p>
                    </div>
                </div>

                {/* Active Users Card */}
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">Workers</h2>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Total Workers:</p>
                        <p className="text-lg font-semibold text-gray-800">{workerCount}</p>
                    </div>
                </div>
                {/* Active Users Card */}
                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-semibold text-gray-800 mb-2">Calculated Rating</h2>
                    <div className="flex items-center justify-between">
                        <p className="text-sm text-gray-600">Average Rating:</p>
                        <p className="text-lg font-semibold text-gray-800">{businessRating}</p>
                        <p className="text-sm text-gray-600">Review Count:</p>
                        <p className="text-lg font-semibold text-gray-800">{reviewCount}</p>
                    </div>
                </div>

            </div>

            <div className="flex flex-row w-[100%] gap-4 mb-4">
                {offerData !== null && offerData.length > 0 && (

                    <div className="container mx-auto px-4 py-8">
                        <h2 className="text-2xl font-bold mb-4">Offers Nearly Expire</h2>
                        <div className="flex justify-between mb-4">
                            <div className="flex items-center">
                                <label className="mr-2">Top:</label>
                                <select
                                    className="border rounded p-2"
                                    value={topValue}
                                    onChange={handleTopChange}
                                >
                                    {[5, 10, 20, 30, 40, 50].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center">
                                <label className="mr-2">Within:</label>
                                <select
                                    className="border rounded p-2"
                                    value={withinValue}
                                    onChange={handleWithinChange}
                                >
                                    {[5, 10, 20, 30, 40, 50].map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <table className="min-w-full border rounded-lg overflow-hidden">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="py-2 px-4">ID</th>
                                    <th className="py-2 px-4">Name</th>
                                    <th className="py-2 px-4">Description</th>
                                    <th className="py-2 px-4">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {offerData.map((offer) => (
                                    <tr key={offer.id} className="border-t">
                                        <td className="py-2 px-4">{offer.id}</td>
                                        <td className="py-2 px-4">{offer.name}</td>
                                        <td className="py-2 px-4">{offer.description}</td>
                                        <td className="py-2 px-4">{offer.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                )}
                {earningData !== null && earningData.datasets[0].data.length > 0  &&(
                    <div className="w-[25%]">
                        <h2>Earning By Offers</h2>
                        <Pie
                            data={earningData}
                            options={{
                                plugins: {
                                    legend: {
                                        position: 'top', // Position legend on the right side
                                    },
                                },
                            }}
                        />
                    </div>
                )}

                { appointmentStatusData !== null && appointmentStatusData.datasets[0].data.length > 0  && (
                    <div className="w-[25%]">
                         <Link to="/appointments" className="text-sm font-bold mb-4 underline" >
                            <h2>Appointment By Status</h2>
                        </Link>
                        <Pie
                            data={appointmentStatusData}
                        />
                    </div>
                )}
                

            </div>

            <div className="flex flex-row w-[100%] gap-4 mb-4">
            {appointmentStatusByServiceData  !== null && appointmentStatusByServiceData.datasets[0].data.length > 0  && (
                    <div className="w-[20%]">
                         <Link to="/appointments" className="text-sm font-bold mb-4 underline" >
                        <h2>Appointment by Service Type</h2>
                        </Link>
                        <Pie
                            data={appointmentStatusByServiceData}
                        />
                    </div>
                )}
                {RNuserData !== null && RNuserData.datasets[0].data.length > 0  && (
                    <div className="w-[20%]">
                        <h2>New And Returning Customers</h2>
                        <Pie
                            data={RNuserData}
                            options={{
                                plugins: {
                                    legend: {
                                        position: 'top', // Position legend on the right side
                                    },
                                },
                            }}
                        />
                    </div>
                )}
                <div className="w-[30%]">
                    <h2>Appointments Calendar</h2>
                    <Calendar
                        onChange={handleDateChange}
                        value={selectedDate}
                        tileClassName={tileClassName}
                    />
                </div>
                {workerData !== null && workerData.length > 0 &&  (
                <div className="w-[30%]">
                    
                            <h2 className="text-lg font-semibold mb-4">Worker Average Completion Time</h2>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Worker Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Completion Time</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {workerData.map((worker) => (
                        <tr key={worker.workerName}>
                            <td className="px-6 py-4 whitespace-nowrap">{worker.workerName}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{worker.averageTime}</td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                    
                </div>
                )}

            </div>
            <div className="flex flex-row w-[100%] gap-4 mb-4">
                <div className="w-[30%] md:w-[30%]">
                    {remainingSubs !== null && remainingSubs.datasets[0].data.length > 0  && (
                        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                            <Link to="/subscription" className="text-sm font-bold mb-4 underline" >
                            <h2 className="">Remaining Subscriptions</h2>
                            </Link>
                            <Pie
                                data={remainingSubs}
                                options={{
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: "top",
                                        },
                                    },
                                }}
                            />
                        </div>
                    )}
                </div>
             
                
                {/* <div className="w-[50%] md:w-[49%]">
                    {ratingChart && (
                        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                            <h2 className="font-bold">Business Rating</h2>
                            <Bar
                                data={ratingChart}
                                options={{
                                    indexAxis: "y",
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                    },
                                    scales: {
                                        y: {
                                            beginAtZero: true,
                                            title: {
                                                display: true,
                                                text: "Business",
                                            },
                                        },
                                        x: {
                                            title: {
                                                display: true,
                                                text: "Rating",
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );
}