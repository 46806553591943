import React, { useState, useEffect } from "react";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import Dropdown from "../../components/drodown.jsx";
import {TokenService} from "../../utils/TokenService.ts";
import { Link } from "react-router-dom"
import Modal from "react-modal";
import Loader from "../../components/Loader.jsx";

const ResultsPerPage = 10;

export default function Content() {
  const [loading, setLoading] = useState(false);
  const [myOffers, setMyOffers] = useState([]);
  const [selectedBusinessId, setSelectedBusinessId] = useState(null);
  const [appointmentData, setAppointmentData] = useState([]);
  const [currentPage , setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;

  const handleBusinessSelect = (id) => {
    
    console.log('selected business id', id);
    setSelectedBusinessId(id);
    handleGetOffers(id)
  };

  useEffect(() => {
    // console.log("Appointemets", TokenService.getAccessToken());
    if (selectedBusinessId === null) {
      handleGetAllOffers();
    }
  }, []);


  const handleGetAllOffers = async ()=> {
    setLoading(true);
    const res = await GET(BASE_URL + "api/Appointment/getOnlyAppointmentList", {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
    });

    if (res.status === 'success') {
      let appointmentList = res.data;
      if(TokenService.getUserRole() === 4){
      appointmentList = res.data.filter(appointment => appointment.businessId === TokenService.getUser().user.businessId);
    }
    setMyOffers(appointmentList); 
    setFilteredAppointments(appointmentList);
      // console.log(res.data);
      setLoading(false);
    }
    else{
      alert(res.message);
      setLoading(false);
    }
  }
  

  const handleGetOffers = async (id) => {
    setLoading(true);
    setMyOffers([])
    // var offerList = [];
    if (id === null || id === undefined) {
      console.log('id is null');
      handleGetAllOffers();
    }
    else{
      const res = await GET(BASE_URL + `api/Appointment/getOnlyAppointmentListByBusinessId?businessId=${id}`, {
        headers: {
          Authorization: `Bearer ${TokenService.getAccessToken()}`,
        },
      });
  
      if (res.status === 'success') {
        setLoading(false);
        setMyOffers (res.data);
        setFilteredAppointments(res.data);
        // console.log(res.data);
      }
      else{
        alert(res.message);
        setLoading(false);
      }
    }

  }

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filteredAppointments = myOffers.filter(appointment => {
      return appointment.creator.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || 
      appointment.creator.lastName.toLowerCase().includes(e.target.value.toLowerCase()) || 
      appointment.serviceType.name.toLowerCase().includes(e.target.value.toLowerCase()) || 
      appointment.business.title.toLowerCase().includes(e.target.value.toLowerCase()) || 
      appointment.status.toLowerCase().includes(e.target.value.toLowerCase()) ||
      formatDate(appointment.appointmentDate).toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredAppointments(filteredAppointments);
  }


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

 

  return (
    <div>
       {
        (TokenService.getUserRole() === 1 || TokenService.getUserRole() === 7) && (
      <div className="flex flex-row items-center justify-end sm:px-6 mb-6 gap-4">
        <div className="flex justify-end my-4">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e)=>handleSearch(e)}
              className="w-fit px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
        <div className="flex ">
          <Dropdown onBusinessSelect={handleBusinessSelect} />

        </div>
      </div>
        )
      }
      {
        loading ? (
          <Loader />
        )
          :
      <div className=" sm:px-6 mx-auto">
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden ">
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 ">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 ">
                      Appointments
                    </h2>
                  </div>


                  <div>
                  </div>
                </div>
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th
                        scope="col"
                        className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Creator 
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Status
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Service
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Assignee/ Cancellee
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Business
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Created
                          </span>
                        </div>
                      </th>

                      {/* <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Valid till
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Price
                          </span>
                        </div>
                      </th>
                      <th scope="col" className="px-6 py-3 text-start">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Discount
                          </span>
                        </div>
                      </th> */}

                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            View
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {filteredAppointments && filteredAppointments.slice(startIndex, endIndex).map((appointment) => (
                      <tr key={appointment.id}>
                        <td className="size-px whitespace-nowrap">
                          <div className="ps-6 lg:ps-3  px-6 py-3">
                            <div className="flex items-center gap-x-3">
                              <img
                                className="inline-block size-[38px] rounded-full"
                                src="images/image-placeholder.jpg"
                                alt="Image Description"
                              />
                              <div className="grow">
                                <Link to='/customerinfodetails' state={appointment.creatorId}>
                                <span className="block text-sm font-semibold text-gray-800 hover:underline ">
                                  {appointment.creator.firstName} {appointment.creator.lastName}
                                </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            {
                              appointment.status === 'Completed' ?
                              <span className="py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full ">
                                {appointment.status}
                              </span>
                              :
                              <span className="text-sm text-gray-500">
                                {appointment.status}
                              </span>

                            }
                             
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3 flex flex-row gap-3">
                            <span className="text-sm text-gray-500">
                              {appointment.serviceType?.name}
                            </span>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                          {
                              appointment.assignee ?
                                <Link to='/workerinfodetails' state={appointment.assigneeId}>
                                  <span className="text-sm text-gray-500 hover:underline hover:text-gray-800">
                                    {appointment.assignee?.firstName + ' ' + appointment.assignee?.lastName}
                                  </span>
                                </Link>
                                :
                                appointment.cancellee ?
                                  <Link to='/workerinfodetails' state={appointment.cancelleeId}>
                                    <span className="text-sm text-gray-500">
                                      {appointment.cancellee?.firstName + ' ' + appointment.cancellee?.lastName}
                                    </span>
                                  </Link>
                                  :
                                  <span className="text-sm text-gray-500">
                                  Not assigned yet
                                  </span>
                            }
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                             {appointment.business?.title}
                            </span>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {formatDate(appointment.appointmentDate)}
                            </span>
                          </div>
                        </td>
                        <td className="size-px whitespace-nowrap">
                         
                          <div className="px-6 py-3">
                            <div className="flex items-center gap-x-3">
                              <Link to={'/appointmentdetails'} state={appointment.id}>
                              <button className="text-sm text-white bg-primary p-2 rounded-xl">
                               View
                              </button>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                      
                    ))}
                  </tbody>
                </table>
                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 ">
                  <div>
                    <p className="text-sm text-gray-600 ">
                      <span className="font-semibold text-gray-800 ">{filteredAppointments.length}</span>{" "}
                      results
                    </p>
                  </div>

                  <div className="flex flex-row items-center gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(myOffers.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(myOffers.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
  
    </div>
  );
}




