import React from 'react';
import { useForm } from 'react-hook-form';
import { POST } from '../../utils/ApiRequestProvider.tsx';
import { BASE_URL } from '../../utils/config.jsx';

export default function Content() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    

    const onSubmit =async (data) => {
        if(data.role === 'Support') {
            // console.log(data.firstName, data.lastName, data.gender, data.email, data.phone, Token);
            const res = await POST(BASE_URL + 'api/Admin/RegisterSupport', {
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                email: data.email,
                phone: data.phone,
                redirectURL: `https://admin.cnamobile.app/setpassword`
            }
            );
            if(res.status === 'success') {
                alert('Support registered successfully');
                window.location.href = '/support';
            }
            else {
                alert(res.message);
            }
        }

        else if(data.role === 'Sale') {
            const res = await POST(BASE_URL + 'api/Admin/RegisterSales', {
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                email: data.email,
                phone: data.phone,
                redirectURL: `https://admin.cnamobile.app/setpassword`
            }
            );
            if(res.status === 'success') {
                alert('Sales registered successfully');
                window.location.href = '/support';
            }
            else {
                alert(res.message);
            }
        }
    };

    return (
        <div className='flex items-center justify-center h-[80vh] w-full'>
            <div className="bg-white border border-gray-200 rounded-xl shadow-sm w-full 2xl:w-1/3 ">
                <div className="p-4 sm:p-7 m-4">
                    <div className="text-center">
                        <h1 className="block text-2xl font-bold text-gray-800">Register</h1>
                    </div>

                    <div className="mt-5">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="grid gap-y-4">
                                <div>
                                    <label htmlFor="firstName" className="block text-sm mb-2">First Name</label>
                                    <div className="relative">
                                        <input type="text" id="firstName" {...register('firstName', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.firstName ? 'border-red-500' : 'border-gray-300'}`} />
                                    </div>
                                    {errors.firstName && <p className="text-xs text-red-600 mt-2">First Name is required</p>}
                                </div>

                                <div>
                                    <label htmlFor="lastName" className="block text-sm mb-2">Last Name</label>
                                    <div className="relative">
                                        <input type="text" id="lastName" {...register('lastName', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.lastName ? 'border-red-500' : 'border-gray-300'}`} />
                                    </div>
                                    {errors.lastName && <p className="text-xs text-red-600 mt-2">Last Name is required</p>}
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-sm mb-2">Email</label>
                                    <div className="relative">
                                        <input type="email" id="email" {...register('email', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.email ? 'border-red-500' : 'border-gray-300'}`} />
                                    </div>
                                    {errors.email && <p className="text-xs text-red-600 mt-2">Email is required</p>}
                                </div>

                                <div>
                                    <label htmlFor="gender" className="block text-sm mb-2">Gender</label>
                                    <select id="gender" {...register('gender', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.gender ? 'border-red-500' : 'border-gray-300'}`}>
                                        <option value="">Select a gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    {errors.gender && <p className="text-xs text-red-600 mt-2">Please select a gender</p>}
                                </div>

                                <div>
                                    <label htmlFor="phone" className="block text-sm mb-2">Phone Number</label>
                                    <div className="relative">
                                        <input type="text" id="phone" {...register('phone', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.phone ? 'border-red-500' : 'border-gray-300'}`} />
                                    </div>
                                    {errors.phone && <p className="text-xs text-red-600 mt-2">Phone Number is required</p>}
                                </div>

                                <div>
                                    <label htmlFor="role" className="block text-sm mb-2">Role</label>
                                    <select id="role" {...register('role', { required: true })} className={`py-3 px-4 block w-full border rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 ${errors.gender ? 'border-red-500' : 'border-gray-300'}`}>
                                        <option value="">Select a role</option>
                                        <option value="Support">Support</option>
                                        <option value="Sale">Sale</option>
                                    </select>
                                    {errors.role && <p className="text-xs text-red-600 mt-2">Please select a Role</p>}
                                </div>

                                <button type="submit" className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none">Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
