import React from 'react'

export default function Context(state) {
    console.log("mail details: ",state);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
      };

      function getText(html){
        var divContainer= document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }
  return (
    <div>
        

    </div>
  )
}
