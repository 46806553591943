import React, { useEffect } from "react";
import { GET } from "../../utils/ApiRequestProvider.tsx";
import { BASE_URL } from "../../utils/config.jsx";
import { TokenService } from "../../utils/TokenService.ts";
import { set } from "react-hook-form";
import Loader from "../../components/Loader.jsx";



export default function Content({ state }) {

  const [appointmentData, setAppointmentData] = React.useState({});
  const [appointmentDetails, setAppointmentDetails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [reviews, setReviews] = React.useState([]);

  useEffect(() => {
    if (state) {
      handleGetData();
      
    }
  }, [state]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  // const formatTime = (timeString) => {
  //   console.log(timeString.split('T')[1]);
  //   let time = timeString.split('T')[1];
  //   return time;
  // };

  const handleGetData = async () => {
    // console.log('appointment', state);
    setLoading(true);
    const res = await GET(BASE_URL + `api/Appointment/getOnlyAppointmentById?appointmentId=${state}`, {
      headers: {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
      },
    });

    if (res.status === 'success') {
      setAppointmentData(res.data); // Update users state with filtered data
      handleGetAppointmentDetails(res.data.id);
      handleGetReviews();
      setLoading(false);
    }
    else {
      alert(res.message);
      setLoading(false);
    }
  }

  const handleGetAppointmentDetails = async (id) => {
    setLoading(true);
    const res = await GET(BASE_URL + `api/AppointmentDetail/getOnlyAppointmentDetailListByAppointmentId?appointmentId=${id}`);
    if (res.status === 'success') {
      setAppointmentDetails(res.data); // Update users state with filtered data
      console.log('creator details:',res.data);
      setLoading(false);
    }
    else {
      alert(res.message);
      setLoading(false);
    }
  }

  const handleGetReviews = async () => {
    const res = await GET(BASE_URL + `api/Review/getReviewsByAppointmentId?appointmentId=${state}`);
    if (res.status === 'success') {
      setReviews(res.data); // Update users state with filtered data
    }
    else {
      alert(res.message);
    }
  }

  return (
    <div>
      <div className="text-center">
        <h3 className="text-primary font-bold text-3xl ">Appointment Details</h3>
      </div>
      {loading ? <Loader /> :
        <div className="flex flex-col py-4">.

          {/* creator details */}
          <div className="flex flex-col gap-3 py-4">
            <h3 className="text-black font-semibold pt-2  text-lg">Creator Info</h3>

            <div className="flex flex-row gap-3 w-full justify-between">

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  First Name
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.creator?.firstName}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Last Name
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.creator?.lastName}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Gender
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.creator?.gender}
                </span>
              </div>
            </div>

            <div className="flex flex-row gap-3 w-full justify-between">

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Email
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.creator?.email}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Phone
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.creator?.phone}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  DOB
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {formatDate(appointmentData?.creator?.dob)}
                </span>
              </div>
            </div>
          </div>

          

          {/* assignee details */}
          <div className="flex flex-col gap-3 py-4">

            <h3 className="text-black font-semibold pt-2  text-lg">
              {
                appointmentData.assignee ?
                  'Assignee Info'
                  :
                  appointmentData.cancellee ?
                    'Cancellee Info'
                    :
                    'Assignee/Cancellee Info'
              }
            </h3>

            {(!appointmentData?.assignee && !appointmentData.cancellee) ? <div className="text-center text-gray-500 p-2">No assignee/cancellee found</div> :
              <>
                <div className="flex flex-row gap-3 w-full justify-between">

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      First Name
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.assignee?.firstName}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Last Name
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.assignee?.lastName}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Gender
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.assignee?.gender}
                    </span>
                  </div>
                </div>

                <div className="flex flex-row gap-3 w-full justify-between">

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Email
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.assignee?.email}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Phone
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.assignee?.phone}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      DOB
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {formatDate(appointmentData?.assignee?.dob)}
                    </span>
                  </div>
                </div>
              </>
            }

          </div>

          {/* business details */}
          <div className="flex flex-col gap-3 py-4">
            <h3 className="text-black font-semibold pt-2  text-lg">Business Info</h3>
            {!appointmentData?.business ?
              <div className="text-center text-gray-500 p-2">No business found</div> :
              <>
                <div className="flex flex-row gap-3 w-full justify-between">

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Title
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.business?.title}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Phone
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.business?.phone}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      email
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.business?.email}
                    </span>
                  </div>
                </div>

                <div className="flex flex-row gap-3 w-full justify-between">

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Address
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.business?.address} {appointmentData?.business?.zipCode}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      License
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {appointmentData?.business?.facilitylicense}
                    </span>
                  </div>

                  <div className="flex flex-col items-start justify-start w-full">
                    <h2 className="font-medium text-gray-500 text-sm">
                      Created At
                    </h2>
                    <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                      {formatDate(appointmentData?.business?.createdAt)}
                    </span>
                  </div>
                </div>
              </>
            }
          </div>

          {/* Offer details */}
          <div className="flex flex-col gap-3 py-4">
            <h3 className="text-black font-semibold pt-2  text-lg">Offer Info</h3>

            <div className="flex flex-row gap-3 w-full justify-between">

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Name
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.offer?.name}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Price
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.offer?.price}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Discount
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.offer?.discount} %
                </span>
              </div>
            </div>

            <div className="flex flex-row gap-3 w-full justify-between">

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Description
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.offer?.description}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Service Type
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.serviceType?.name}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Valid Till
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {formatDate(appointmentData?.offer?.validDate)}
                </span>
              </div>
            </div>
          </div>

          {/* Appointment details */}
          <div className="flex flex-col gap-3 py-4">
            <h3 className="text-black font-semibold pt-2  text-lg">Appointment Info</h3>

            <div className="flex flex-row gap-3 w-full justify-between">

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Appointment Date
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {formatDate(appointmentData?.appointmentDate)}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Estimate
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {!appointmentData?.estimate ? 'No estimate' : appointmentData?.estimate}
                </span>
              </div>

              <div className="flex flex-col items-start justify-start w-full">
                <h2 className="font-medium text-gray-500 text-sm">
                  Current Status
                </h2>
                <span className="font-regular text-gray-700 text-md border p-2 rounded-lg border-slate-300 flex-grow w-full">
                  {appointmentData?.status}
                </span>
              </div>
            </div>

            {/* appointment History */}
            <div className=" rounded-xl border border-gray-300">
              <h3 className="text-black font-semibold p-2  text-lg">History</h3>

              {appointmentDetails !== null ?
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Name
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Status
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Date
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {appointmentDetails && appointmentDetails.map((appointment) => (
                      <tr key={appointment.id}>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {appointment.creator?.firstName} {appointment.creator?.lastName}
                            </span>
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {appointment.status}
                            </span>
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {formatDate(appointment.createdAt)}
                            </span>
                          </div>
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>
                : <div className="text-center text-gray-500 p-2">No history found</div>}
            </div>

            <div className=" rounded-xl border border-gray-300">
              <h3 className="text-black font-semibold p-2  text-lg">Review</h3>

              {reviews !== null ?
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50 ">
                    <tr>
                      <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Comment
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="ps-6 lg:ps-3 px-6 py-3 text-start"
                      >
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Rating
                          </span>
                        </div>
                      </th>

                      <th scope="col" className="px-6 py-3 text-end">
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 ">
                            Date
                          </span>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200 ">
                    {reviews && reviews.map((r) => (
                      <tr key={r.id}>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {r.comment}
                            </span>
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {r.rating}
                            </span>
                          </div>
                        </td>

                        <td className="size-px whitespace-nowrap">
                          <div className="px-6 py-3">
                            <span className="text-sm text-gray-500">
                              {formatDate(r.createdAt)}
                            </span>
                          </div>
                        </td>
                      </tr>

                    ))}
                  </tbody>
                </table>
                : <div className="text-center text-gray-500 p-2">No Review found</div>}
            </div>


          </div>





        </div>
      }
    </div>
  );
}
