import React,{useEffect, useState}from 'react'
import { BASE_URL } from '../../utils/config';
import { GET } from '../../utils/ApiRequestProvider.tsx';
import { set } from 'react-hook-form';
import { Link } from 'react-router-dom';




export default function Content() {
  let ResultsPerPage=10;
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage , setCurrentPage] = useState(1);
  let startIndex = (currentPage - 1) * ResultsPerPage;
  let endIndex = currentPage * ResultsPerPage;


  const handleGetNotifications = async () => {
    setLoading(true);
    try {
      const res = await GET(BASE_URL + 'api/Notification/getNotificationLogs');
      if (res.status === "success") {
        // Slice the latest 4 notifications from the fetched data
        setNotifications(res.data);
        console.log('setNotifications',res.data);
        setLoading(false);

      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }

  useEffect(() => {
    handleGetNotifications();
  }, []); 

  const getLink = (ref) => {
    if (ref === 'Ticket') {
      return '/ticketdetails';
    }
    if (ref === 'BusinessUpdate') {
      return '/requests';
    }
    if (ref === 'Offer') {
      return '/offerdetails';
    }
    if (ref === 'Appointment') {
      return '/appointmentdetails';
    }
    if (ref === 'customer') {
      return '/customerinfodetails';
    }
    if (ref === 'business') {
      return '/serviceproviderdetails';
    }
    if (ref === 'UserUpdate') {
      return '/requests';
    }
  }


  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Notifications </h1>
        <button className="bg-primary text-white px-3 py-1 rounded" onClick={handleGetNotifications}>
          {loading ? 'Loading...' : 'Refresh'}
        </button>
      </div>
      <div className="mt-5">
        {notifications.slice(startIndex, endIndex).map((notification, index) => (
           <Link key={notification.id} to={getLink(notification.ref)} state={notification.refId}>
          <div key={index} className="flex items-center justify-between border border-gray-200 p-3 rounded-lg mb-3">
            <div>
            <h2 className="text-lg font-semibold">{notification.title}</h2>
            <p className="text-sm text-gray-700">{notification.message}</p>
            </div>
            <div>
                      <p className="text-black text-xs mx-2">{new Date(notification.logTime).toLocaleString()}</p>
            </div>

            {/* <p className="text-sm text-gray-500">{notification.created_at}</p> */}
          </div>
          </Link>
        ))}
      </div>
      <div className="flex flex-row items-center justify-end w-fit p-2 rounded-lg bg-gray-100 gap-3">
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span className="text-sm font-medium text-gray-800 ">
                        {currentPage} of {Math.ceil(notifications.length / ResultsPerPage)}
                      </span>
                      <button
                        className="text-sm font-medium text-gray-800 hover:underline disabled:opacity-50"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === Math.ceil(notifications.length / ResultsPerPage)}
                      >
                        Next
                      </button>
                    </div>
    </div>
  )
}
