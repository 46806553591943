import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './notify/content'

export default function SendNotification() {
  return (
    <Baselayout>
    <Content />
    </Baselayout>
  )
}
