import React from 'react'
import Baselayout from '../components/layouts/Baselayout'
import Content from './productedit/content'
import { useLocation } from "react-router-dom";


export default function ProductEdit() {
    const { state } = useLocation();
    return (
      <>
        <Baselayout>
          <Content state={state}/>
        </Baselayout>
      </>
    );
}
